import axios from "axios";
import {
  LocalStorage,
  getHeaders,
} from "../../utils/local_storage/local_storage";
import toast from "react-hot-toast";
import { Network } from "../../utils/axios/axios_utils";

export const getSettings = async (appContext: any) => {
  const response = await Network.get("settings");

  const acceptImages: boolean =
    response.data?.payload?.validations?.addressAcceptImages ?? false;

  appContext.changeContext({ acceptImages: acceptImages });

  return response.data;
};
