/* eslint-disable eqeqeq */
import React, { useState } from "react";
import {
  InputFieldProps,
  helpersTexts,
} from "../../../../models/component_props/input_props";

function TextInputField(props: InputFieldProps) {
  const [isOnFocus, setIsOnFocus] = useState(false);

  let style: any = props.style ?? {};
  let styleContainer: any = props.styleContainer;

  if (props.width) {
    style.width = props.width;
  }

  if (props.filled) {
    styleContainer = {
      background: "#f3f4f6",
      padding: "0.7vh 2vw",
      width: "20vw",
      borderRadius: "20px",
      border: "3px solid transparent",
      ...props.styleContainer,
    };

    if (isOnFocus) {
      styleContainer["border"] = "3px solid #0f69b3";
    }

    style = {
      border: "none",
      width: "40vw",
      background: "#f3f4f6",
      ...style,
    };
  }

  const onChange = (e: String) => {
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const getError = () => {
    return props.useForm?.errors?.[props.useForm.name];
  };

  const getErrorMessage = (): String => {
    return getError()?.message == ""
      ? new helpersTexts().required
      : getError()?.message;
  };

  const hasError: boolean = getError() != null;

  return (
    <div className="inputTextField-container">
      <div
        className={`inputTextField inputTextField-haserror-${hasError}`}
        style={styleContainer}
      >
        <div>
          <input
            disabled={props?.disabled || false}
            onFocus={() => setIsOnFocus(true)}
            onBlur={() => setIsOnFocus(false)}
            placeholder={props.placeholder}
            onChange={(e) => onChange(e.target.value)}
            style={style}
            {...props.useForm}
          />
        </div>
      </div>
      {hasError && (
        <h4 className="input-text-error">
          {hasError ? getErrorMessage() : ""}
        </h4>
      )}
    </div>
  );
}

export default TextInputField;
