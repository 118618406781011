import React, { useEffect } from "react";
import LayoutVerificationsHeader from "./header/layout_verifications_header";
import { useLocation } from "react-router-dom";
import { VerificationsMode } from "../../../models/verifications/verifications_models";

interface Props {
  children: React.ReactNode;
  footer?: React.ReactNode;
  mode: VerificationsMode;
  hideGoBack?: boolean;
  hideTitle?: boolean;
}

function LayoutVerification(props: Props) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="LayoutVerification">
      <LayoutVerificationsHeader
        mode={props.mode}
        hideTitle={props.hideTitle}
        hideGoBack={props.hideGoBack}
      />
      {props.children}
      {props.footer}
    </div>
  );
}

export default LayoutVerification;
