/* eslint-disable eqeqeq */
import React from "react";

interface Props {
  currentStep: number;
  stepsAmount: number;
}

function StepIndicator(mainprops: Props) {
  const Circle = (props: { index: number }) => {
    const current: boolean = props.index + 1 === mainprops.currentStep;
    const passed = props.index < mainprops.currentStep - 1;

    return (
      <div
        className={`circle ${
          current || passed ? "current-circle" : ""
        } circle-passed-${passed}`}
      >
        {" "}
        {passed ? (
          <i className="fa-solid fa-check"></i>
        ) : (
          <div className="smallCircle"></div>
        )}
      </div>
    );
  };

  const Line = (props: { current: boolean }) => {
    let width = "23%";

    if (mainprops.stepsAmount == 3) {
      width = "38%";
    }

    if (mainprops.stepsAmount == 2) {
      width = "85%";
    }

    return (
      <div
        className={`line ${props.current ? "current-line" : ""}`}
        style={{ width: width }}
      />
    );
  };

  return (
    <div className="steps-indicator">
      {Array(mainprops.stepsAmount)
        .fill(0)
        .map((_, index) => {
          return (
            <>
              <Circle index={index} />
              {index + 1 != mainprops.stepsAmount && (
                <Line current={index + 1 < mainprops.currentStep} />
              )}
            </>
          );
        })}
    </div>
  );
}

export default StepIndicator;
