/* eslint-disable eqeqeq */
import axios from "axios";
import { Base64Utils } from "../../utils/base64/base64_utils";
import {
  LocalStorage,
  getHeaders,
} from "../../utils/local_storage/local_storage";
import { UserUtils } from "../../utils/user/user_utils";
import { LivenessTestsModes } from "../../models/verifications/verifications_models";
import { toast } from "react-hot-toast";
import { isDev } from "../../utils/devices/devices_utils";
import { Network } from "../../utils/axios/axios_utils";

const serverChallengeUrl = process.env.REACT_APP_API_CHALLENGE;
const serverOnBoarding = process.env.REACT_APP_API_ONBOARDING;

export class LivenessService {
  tryStartProcess = async (
    base64: string
  ): Promise<interfaceLivenessStartChallengeResponse> => {
    try {
      const response = await Network.post(
        "challenge/start",
        {
          frame: new Base64Utils().clean(base64),
          user_id: new UserUtils().getUserId(),
        },
        {
          baseURL: serverChallengeUrl,
        }
      );

      const statusCode = response?.data["status-code"] ?? 400;
      //toast(JSON.stringify(response.data));

      return {
        success: statusCode == 200,
        statusCode: statusCode,
        faceIsNotLikeDocument: response.data["message"] == "Face not similar",
        faceIsTooFar: false,
        challengeBoxPosition: response.data["challengeBoxPosition"],
      };
    } catch (error: any) {
      const response = error?.response?.data;

      //toast(JSON.stringify(response));

      return {
        statusCode: error.response.data["status-code"],
        faceIsTooFar: error.response.data["message"] == "TOO_FAR",
        faceIsNotLikeDocument:
          error.response.data["message"] == "Face not similar",
        success: false,
        challengeBoxPosition: { x: 0, y: 0, width: 0, height: 0 },
      };
    }
  };

  tryContinueProcess = async (
    base64: string
  ): Promise<interfaceLivenessChallengeResponse> => {
    //toast.success("tryContinueProcess");
    //toast("tryContinueProcess");
    try {
      const response = await Network.post(
        "challenge/process",
        {
          frame: new Base64Utils().clean(base64),
          user_id: new UserUtils().getUserId(),
        },
        {
          baseURL: serverChallengeUrl,
        }
      );

      return {
        success: JSON.stringify(response.data)?.includes("challenge complete"),
        challengeBoxPosition: response.data["challengeBoxPosition"],
        challengeMode: response.data["test"],
        faceIsTooClose: false,
        faceIsOutTheBox: false,
        faceIsTooFar: response.data["message"] == "TOO_FAR",
        statusCode: response.data["status-code"],
        challengeModeSide: response.data["side"],
      };
    } catch (error: any) {
      const response = error.response.data;
      return {
        success: false,
        faceIsOutTheBox: response["message"] == "FACE_OUT_OF_BOX",
        faceIsTooClose: response["message"] == "TOO_CLOSE",
        faceIsTooFar:
          response["message"] == "TOO_FAR" ||
          response["error"]?.includes("too far"),
        statusCode: response["status-code"],
      };
    }
  };

  finishProcess = async (email?: string | null): Promise<void> => {
    const response = await Network.post(
      "onboarding-processes/finish-facial-validations",
      email
        ? {
            email: email,
          }
        : {}
    );
  };
}

export interface interfaceLivenessStartChallengeResponse {
  statusCode: number;
  success: boolean;
  faceIsTooFar: boolean;
  faceIsNotLikeDocument: boolean;
  challengeBoxPosition?: {
    x: number;
    y: number;
    width?: number;
    height?: number;
  } | null;
}

export interface interfaceLivenessChallengeResponse {
  success: boolean;
  challengeMode?: string;
  challengeModeSide?: string;
  statusCode: number;
  faceIsOutTheBox: boolean;
  faceIsTooFar: boolean;
  faceIsTooClose: boolean;
  challengeBoxPosition?: {
    x: number;
    y: number;
    width?: number;
    height?: number;
  } | null;
}
