/* eslint-disable eqeqeq */
import React from "react";
import Checkbox from "../../../elements/inputs/checkbox/checkbox";
import {
  Abbreviation,
  CountryWithOCR,
  SupportedDocument,
} from "../../../../models/verifications/verifications_models";

export enum DocumentType {
  card = "Cédula de identidad",
  passport = "Pasaporte",
  carLicense = "Licencia de conducir",
  idle = "",
}

export const getIdOFDocumentType = (type: DocumentType): string => {
  switch (type) {
    case DocumentType.card:
      return "CEDULA DE IDENTIDAD";
    case DocumentType.passport:
      return "PASAPORTE";
    case DocumentType.carLicense:
      return "LICENCIA DE CONDUCIR";
    default:
      return "PASAPORTE";
  }
};

function VerfIdentityTypeDocument({
  documentTypeSelected,
  setDocumentType,
  countrySelected,
}: {
  documentTypeSelected: SupportedDocument | null;
  setDocumentType: (type: SupportedDocument) => void;
  countrySelected: CountryWithOCR;
}) {
  const hasTypeDocument = (abbr: Abbreviation) => {
    return countrySelected.supported_documents.some(
      (document) => document.abbreviation == abbr
    );
  };

  const getTypeDocument = (abbr: Abbreviation) => {
    return countrySelected.supported_documents.find(
      (document) => document.abbreviation == abbr
    );
  };

  function VerfIdentityTypeDocumentItem({ type }: { type: SupportedDocument }) {
    return (
      <div className="verfIdentityTypeDocumentItem">
        <Checkbox
          value={type == documentTypeSelected}
          onToggle={() => setDocumentType(type)}
        />
        <p className="verification-paragraph">{type.type}</p>
      </div>
    );
  }

  return (
    <div className="verf_identity_type_document">
      <h3>Selecciona el tipo de documento:</h3>
      {hasTypeDocument(Abbreviation.DL) && (
        <VerfIdentityTypeDocumentItem
          type={getTypeDocument(Abbreviation.DL)!}
        />
      )}
      {hasTypeDocument(Abbreviation.Pp) && (
        <VerfIdentityTypeDocumentItem
          type={getTypeDocument(Abbreviation.Pp)!}
        />
      )}
      {hasTypeDocument(Abbreviation.ID) && (
        <VerfIdentityTypeDocumentItem
          type={getTypeDocument(Abbreviation.ID)!}
        />
      )}
    </div>
  );
}

export default VerfIdentityTypeDocument;
