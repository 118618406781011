/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import ArrowIcon from "../../../../assets/icons/arrow_left.png";
import { useNavigate } from "react-router-dom";
import { VerificationsMode } from "../../../../models/verifications/verifications_models";

interface Props {
  mode: VerificationsMode;
  hideGoBack?: boolean;
  hideTitle?: boolean;
  title?: string;
}

function LayoutVerificationsHeader(props: Props) {
  const navigate = useNavigate();

  const getTitle = (): string => {
    if (props.title) return props.title;
    if (props.hideTitle) return "";

    switch (props.mode) {
      case VerificationsMode.facial:
        return "Verificación facial";
      case VerificationsMode.identity:
        return "Verificación de identidad";
      case VerificationsMode.address:
        return "Verificación de domicilio";
      default:
        return "";
    }
  };

  return (
    <div
      className="header_layout_verification"
      style={{ justifyContent: props.hideGoBack ? "center" : "initial" }}
    >
      {!props.hideGoBack && (
        <img src={ArrowIcon} onClick={() => navigate(-1)} />
      )}
      <h3>{getTitle()}</h3>
    </div>
  );
}

export default LayoutVerificationsHeader;
