export interface buttonProps {
  label: string;
  onTap?: () => void;
  width?: string;
  style?: React.CSSProperties;
  outlined?: boolean;
  disabled?: boolean;
  isSubmit?: boolean;
}

export enum AdviceMode {
  error,
  info,
  success,
}
