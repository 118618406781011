import { LocalStorageItems } from "../../models/local_storage/local_storage";
import jwt_decode from "jwt-decode";

export class UserUtils {
  getUserId() {
    const jwtt = localStorage.getItem(LocalStorageItems.JWTT);

    if (jwtt == null) return;

    var decoded: any = jwt_decode(jwtt!);

    return decoded.publicId;
  }
}
