import React from "react";

interface Props {
  remainingSeconds: number;
}

function LivenessTimeIndicator({ remainingSeconds }: Props) {
  const formatTime = () => {
    const minutes = Math.floor(remainingSeconds / 60);
    const seconds = remainingSeconds % 60;

    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div
      className="liveness_time_indicator"
      style={remainingSeconds < 15 ? { backgroundColor: "red" } : {}}
    >
      {remainingSeconds <= 0
        ? "Tiempo agotado"
        : ` Tiempo restante: ${formatTime()}`}
    </div>
  );
}

export default LivenessTimeIndicator;
