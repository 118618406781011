/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import LayoutVerification from "../../../../components/layouts/veriifcations_layout/layout_verifications";
import { VerificationsMode } from "../../../../models/verifications/verifications_models";
import Ilustration from "../../../../assets/ilustrations/facialstep1.svg";
import VerificationsFooter from "../../../../components/layouts/veriifcations_layout/footer/verifications_footer";
import { RoutesList, routes } from "../../../../utils/routes/routes_utils";
import { DeviceUtils } from "../../../../utils/devices/devices_utils";
import Swal from "sweetalert2";
import VerificationCancelledModal from "../../../../components/modals/verf_cancelled/verification_cancelled_modal";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";

function InstructionOneVerificationFacial() {
  const navigate = useNavigate();

  return (
    <LayoutVerification
      mode={VerificationsMode.facial}
      hideGoBack
      footer={
        <VerificationsFooter
          currentStep={1}
          routeNext={RoutesList.InstructionTwoVerificationFacial}
        />
      }
    >
      <div className="verification_facial_instructions">
        <p className="verification-paragraph">
          En esta sección podremos asegurarnos perfectamente de tu identidad, lo
          que generará un ambiente de seguridad y confianza entre todos los
          usuarios con los que vayas a firmar tus documentos.
          <br />
          <br />
          Por favor, antes de iniciar la verificación lee atentamente las
          siguientes sugerencias para que el proceso de reconocimiento facial
          sea ágil y exitoso
        </p>
        <div className="center">
          <img
            src={Ilustration}
            onClick={() => {
              /* withReactContent(Swal).fire({
                showCloseButton: false,
                width: "88vw",
                showConfirmButton: false,
                showCancelButton: false,
                padding: "0 0 0 0",
                html: <VerificationCancelledModal />,
              }); */
            }}
          />
        </div>
        <p className="verification-paragraph zxac">
          Es necesario que estés ubicado en un lugar que tenga buena luz y
          sobretodo que permita que tus rasgos faciales sean claros ante la
          cámara.
        </p>
      </div>
    </LayoutVerification>
  );
}

export default InstructionOneVerificationFacial;
