/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import LayoutVerification from "../../../../components/layouts/veriifcations_layout/layout_verifications";
import { VerificationsMode } from "../../../../models/verifications/verifications_models";
import Ilustration from "../../../../assets/ilustrations/facialstep2.svg";
import VerificationsFooter from "../../../../components/layouts/veriifcations_layout/footer/verifications_footer";
import { RoutesList } from "../../../../utils/routes/routes_utils";

function InstructionTwoVerificationFacial() {
  return (
    <LayoutVerification
      mode={VerificationsMode.facial}
      footer={
        <VerificationsFooter
          currentStep={2}
          routeNext={RoutesList.InstructionThreeVerificationFacial}
        />
      }
    >
      <div className="verification_facial_instructions">
        <div className="center">
          <img src={Ilustration} />
        </div>
        <p className="verification-paragraph">
          Evita portar gafas oscuras o con filtros UV, sólo se admiten gafas con
          lentes totalmente transparentes. Es necesario que tus ojos se vean
          claramente.
        </p>
      </div>
    </LayoutVerification>
  );
}

export default InstructionTwoVerificationFacial;
