import { createContext } from "react";
import Context, { ContextState } from "../../../models/context/context_model";

export const initialContext: ContextState = {
  isLoading: false,
  registerMode: false,
  access_token: "-",
};

const AppContext = createContext({
  state: initialContext,
  isLogged: false,
  changeContext: (newState: object) => {},
} as Context);

export default AppContext;
