import { LocalStorageItems } from "../../models/local_storage/local_storage";

export class LocalStorage {
  save(value: string, key: LocalStorageItems) {
    localStorage.setItem(key, value);
  }

  get(key: LocalStorageItems) {
    localStorage.getItem(key);
  }

  saveContext(value: any) {
    localStorage.setItem(LocalStorageItems.CONTEXT, JSON.stringify(value));
  }

  getJwtt() {
    return localStorage.getItem(LocalStorageItems.JWTT);
  }
}

export const isRegisterMode = () => {
  return localStorage.getItem(LocalStorageItems.REGISTERMODE) === "true";
};

export const getHeaders = () => {
  if (isRegisterMode()) {
    return {
      userId: localStorage.getItem(LocalStorageItems.USERID),
    };
  } else {
    return {
      Authorization: `Bearer ${new LocalStorage().getJwtt()}`,
    };
  }
};
