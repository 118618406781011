import React from "react";
import { buttonProps } from "../../../models/component_props/button_props";

function Button(props: buttonProps) {
  const style: any = {};

  if (props.width) {
    style.width = props.width;
  }

  if (props.isSubmit) {
    return (
      <input
        value={props.label}
        type="submit"
        className={`button button_outlined_${props.outlined} button_disabled_${props.disabled}`}
        style={style}
      />
    );
  }

  return (
    <button
      className={`button button_outlined_${props.outlined} button_disabled_${props.disabled} prevent-select`}
      style={style}
      onClick={props.disabled ? () => {} : props.onTap}
    >
      {props.label}
    </button>
  );
}

export default Button;
