import React, { useEffect } from "react";
import Advice from "../../../../components/advice/advice";
import { AdviceMode } from "../../../../models/component_props/button_props";
import LayoutVerificationsHeader from "../../../../components/layouts/veriifcations_layout/header/layout_verifications_header";
import {
  IdentityDataUser,
  VerificationsMode,
} from "../../../../models/verifications/verifications_models";
import StaticInput from "../../../../components/elements/inputs/static/static_input";
import StepIndicator from "../../../../components/elements/step_indicator/step_indicator";
import Button from "../../../../components/elements/buttons/button";
import { useLocation, useNavigate } from "react-router-dom";
import useAppContext from "../../../../utils/context/useAppContext/use_app_context";
import { UserUtils } from "../../../../utils/user/user_utils";
import { IdentityService } from "../../../../service/identity/identity_service";
import { RoutesList } from "../../../../utils/routes/routes_utils";
import { toast } from "react-hot-toast";
import { LocalStorageItems } from "../../../../models/local_storage/local_storage";
import { isRegisterMode } from "../../../../utils/local_storage/local_storage";

function VerificationIdentityReview() {
  const navigate = useNavigate();
  const appContext = useAppContext();
  const location = useLocation();

  const onConfirm = async () => {
    const ws = appContext.state.ws;

    if (!isRegisterMode()) {
      appContext.toggleLoading(true);

      try {
        await new IdentityService().markAsForRevision();
      } catch (error: any) {}

      try {
        if (ws) {
          ws.send(
            JSON.stringify({
              action: "liveness-challenge-update",
              args: {
                status: "FINISHED",
                user: new UserUtils().getUserId(),
              },
            })
          );
        }
      } catch (error) {
        //
      }
    }

    appContext.changeContext({ has_completed_challenge: true });
    appContext.toggleLoading(false);

    navigate(
      isRegisterMode()
        ? RoutesList.RegisterVerificationFacial
        : RoutesList.SuccessVerificationIdentity
    );
  };

  useEffect(() => {
    if (appContext.state.has_completed_challenge) {
      navigate(RoutesList.IdentityIntro);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const identityDataUser: IdentityDataUser | undefined =
    appContext.state.identityDataUser;

  if (!identityDataUser) {
    if (appContext.state.has_completed_challenge) {
      navigate(RoutesList.IdentityIntro);
    }
  }

  const documentTypeSelected = location.state?.documentTypeSelected;

  return (
    <div className="LayoutVerification verification_identity_review">
      <LayoutVerificationsHeader mode={VerificationsMode.identity} />
      <Advice
        mode={AdviceMode.error}
        title="Revisa los datos"
        text="Por favor, antes de enviar a verificar tu identidad, revisa los datos extraído de tu documento. Si hay alguno que no sea correcto, regresa al paso anterior e intenta nuevamente con otra toma."
      />
      <StaticInput
        title="Nombre/s (tal como figura en el DNI) sin tildes"
        value={
          identityDataUser?.userFirstName ?? identityDataUser?.first_name ?? ""
        }
      />
      <StaticInput
        title="Apellido/s (tal como figura en el DNI) sin tildes"
        value={
          identityDataUser?.userLastName ?? identityDataUser?.last_name ?? ""
        }
      />
      <StaticInput
        title="Número de documento (sin puntos)"
        value={
          identityDataUser?.userDocumentNumber ??
          identityDataUser?.document_number ??
          ""
        }
      />
      <StaticInput
        title="Tipo de documento"
        value={documentTypeSelected.type ?? "Cédula de Ciudadanía"}
      />

      <StepIndicator
        currentStep={documentTypeSelected.double_sided ? 3 : 2}
        stepsAmount={documentTypeSelected.double_sided ? 3 : 2}
      />

      <div className="row-buttons">
        <Button
          label="Corregir proceso"
          onTap={() =>
            navigate(RoutesList.FrontImageDocument, {
              state: {
                countrySelected: location.state?.countrySelected,
                documentTypeSelected: location.state?.documentTypeSelected,
              },
            })
          }
        />
        <div className="separator" />
        <Button label="Enviar a verificar" onTap={onConfirm} />
      </div>
    </div>
  );
}

export default VerificationIdentityReview;
