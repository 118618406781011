/* eslint-disable eqeqeq */
import React from "react";
import Select from "react-select";
import {
  SelectProps,
  helpersTexts,
} from "../../../../models/component_props/input_props";

function SelectInputField(props: SelectProps) {
  const style: any = props.style ?? {};

  if (props.width) {
    style.width = props.width;
  }

  const onChange = (value: any) => {
    if (props.onChange) {
      props.onChange(value);
    }

    if (!props.useForm) return;
    props.useForm.onChange({
      target: { value: value, name: props.useForm.name },
    });
  };

  const getError = () => {
    return props.useForm?.errors?.[props.useForm.name];
  };

  const getErrorMessage = (): String => {
    return getError()?.message == ""
      ? new helpersTexts().required
      : getError()?.message;
  };

  const hasError: boolean = getError() != null;

  return (
    <div className=" inputSelectField">
      <div style={style}>
        <Select
          placeholder={props.label}
          value={props.value}
          getOptionLabel={props.getOptionLabel}
          onChange={onChange}
          options={props.options}
          styles={props.styles}
          components={props.components}
        />
      </div>
      {hasError && (
        <h4 className="input-text-error">
          {hasError ? getErrorMessage() : ""}
        </h4>
      )}
    </div>
  );
}

export default SelectInputField;
