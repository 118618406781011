/* eslint-disable jsx-a11y/alt-text */
import LayoutVerification from "../../../../components/layouts/veriifcations_layout/layout_verifications";
import { VerificationsMode } from "../../../../models/verifications/verifications_models";
import VerificationsFooter from "../../../../components/layouts/veriifcations_layout/footer/verifications_footer";
import useAppContext from "../../../../utils/context/useAppContext/use_app_context";
import Button from "../../../../components/elements/buttons/button";
import StepIndicator from "../../../../components/elements/step_indicator/step_indicator";
import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoutesList } from "../../../../utils/routes/routes_utils";
import Ilustration from "../../../../assets/ilustrations/verf_address_finished.svg";
import { toast } from "react-hot-toast";

function FinishedVerificationAddress() {
  useEffect(() => {
    const preventBack = (event: any) => {
      event.preventDefault();
      window.history.pushState("", "", window.location.href);
      window.history.replaceState("", "", window.location.href);
    };

    window.history.pushState("", "", window.location.href);
    window.addEventListener("popstate", preventBack);
    return () => {
      window.removeEventListener("popstate", preventBack);
    };
  }, []);

  return (
    <LayoutVerification mode={VerificationsMode.address} hideGoBack>
      <div className="verification_address verification_address_finished">
        <div className="">
          <img src={Ilustration} />
          <h2>Gracias por enviar a verificar tu domicilio!</h2>

          <p className="verification-paragraph center_text">
            Estamos revisando tus datos. Muy pronto te enviaremos una
            notificación.
          </p>
        </div>
      </div>
    </LayoutVerification>
  );
}

export default FinishedVerificationAddress;
