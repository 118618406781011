import React from "react";

import Ilustration from "../../assets/ilustrations/verification_check.svg";
import Button from "../../components/elements/buttons/button";

function VinculatedSuccessModal(props: any) {
  return (
    <div className="vinculate_email_screen vinculated_success_modal">
      <img src={Ilustration} alt="ilsu" />

      <p
        className="verification-paragraph"
        style={{ color: "#2bbfed", margin: "4vh 0", fontWeight: "bold" }}
      >
        ¡Muchas gracias, la verificación ha sido exitosa!
      </p>

      <p className="verification-paragraph">
        {!props.hasVinculatedEmail
          ? "Si en algún momento decides unirte a Adamo, estaremos aquí para darte la bienvenida. ¡Gracias por visitarnos y esperamos verte nuevamente en el futuro"
          : "Ahora puedes disfrutar de todas las ventajas y funcionalidades que nuestra plataforma tiene para ofrecerte."}
      </p>

      <p
        className="verification-paragraph bold "
        style={{ textAlign: "center" }}
      >
        ¿Quieres pertenecer a la familia Adamo ID?
      </p>

      <Button
        label="Descargar Aplicación"
        width="100%"
        onTap={() => {
          window.open(
            "https://adamo-flutter-deploy-qa.s3.us-east-2.amazonaws.com/AdamoID-qa.apk"
          );
        }}
      />
    </div>
  );
}

export default VinculatedSuccessModal;
