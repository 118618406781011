/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Camera from "../../../../components/cameras/cameras";
import { LivenessService } from "../../../../service/liveness/liveness_service";
import Sheet from "react-modal-sheet";
import { useNavigate, useParams } from "react-router-dom";
import {
  LivenessProps,
  faceSquareState,
} from "../../../../models/camera/camera_models";
import AppContext from "../../../../utils/context/initial/initial_context";
import useAppContext from "../../../../utils/context/useAppContext/use_app_context";
import { CameraUtils } from "../../../../utils/camera/camera_utils";
import Button from "../../../../components/elements/buttons/button";
import IlustrationFailed from "../../../../assets/ilustrations/verificationfailed.svg";
import FaceOverlay from "../../assets/ilustrations/facemask.svg";
import LayoutVerificationsHeader from "../../../../components/layouts/veriifcations_layout/header/layout_verifications_header";
import {
  LivenessChallengeConfig,
  LivenessTestsModes,
  LivenessTestsSides,
  VerificationsMode,
} from "../../../../models/verifications/verifications_models";
import LivenessIndicator from "../../../../components/cameras/liveness_indicator/liveness_indicator";
import { RoutesList } from "../../../../utils/routes/routes_utils";
import { LivenessChallengeUtils } from "./liveness_challenge_utils";
import { DeviceUtils } from "../../../../utils/devices/devices_utils";
import { ToastBar, toast } from "react-hot-toast";
import { isRegisterMode } from "../../../../utils/local_storage/local_storage";
import ModalFaceIncorrect from "../../../misc/modal_face_incorrect";

function LivenessChallenge() {
  const webcamRef: any = React.useRef(null);
  const navigate = useNavigate();
  const appContext = useAppContext();
  var dateStartedChallenge: Date | null = null;

  const [hasStartedProcces, setHasStartedProcces] = useState(false);
  const [faceIsTooFar, setFaceIsTooFar] = useState(false);
  const [faceIsTooClose, setFaceIsTooClose] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [faceIsOutTheBox, setFaceIsOutTheBox] = useState(false);
  const [faceIsNotLikeDocument, setFaceIsNotLikeDocument] = useState(false);
  const [remainingSeconds, setRemainingSeconds] = useState(120);
  const [faceIsIncorrect, setFaceIsIncorrect] = useState(false);

  const [faceIndicatorStatus, setFaceIndicatorStatus] =
    useState<faceSquareState>(faceSquareState.idle);
  const [configChallenge, setConfigChallenge] =
    useState<LivenessChallengeConfig>({
      challengeType: LivenessTestsModes.checkpoints,
      hasStartedProcess: false,
      faceIsTooFar: false,
      faceIsOutTheBox: false,
      faceIsNotLikeDocument: false,
      faceIsTooClose: false,
    });
  const [livenessProps, setLivenessProps] = useState<LivenessProps>({
    x: 0,
    y: 0,
    showIndicator: true,
    remainingSeconds: 120,
  });

  const handleStartProccess = async () => {
    try {
      if (checkIfIsOutOfTime()) return;

      setHasStartedProcces(true);
      const base64 = takePhoto();
      const livenessService = new LivenessService();
      const response = await livenessService.tryStartProcess(base64);

      const newPositions = new CameraUtils().calculateNewPosition(response);

      if (response.statusCode == 400) {
        setFaceIndicatorStatus(faceSquareState.idle);
      } else {
        setFaceIndicatorStatus(faceSquareState.correct);
      }

      setFaceIsTooFar(response.faceIsTooFar);
      setFaceIsNotLikeDocument(response.faceIsNotLikeDocument);

      setFaceIsIncorrect(response.faceIsNotLikeDocument);

      if (response.success) {
        new DeviceUtils().completedStep();
        setLivenessProps({
          y: newPositions.y,
          x: newPositions.x,
          showIndicator: true,
          remainingSeconds: 120,
        });

        handleContinueProcess();
      } else {
        if (response.faceIsNotLikeDocument != true) handleStartProccess();
      }
    } catch (error: any) {}
  };

  const handleContinueProcess = async () => {
    if (checkIfIsOutOfTime()) return;

    const base64 = takePhoto();
    const livenessService = new LivenessService();
    const response = await livenessService.tryContinueProcess(base64 || "");

    if (response.statusCode == 400) {
      setFaceIndicatorStatus(faceSquareState.idle);
    } else {
      setFaceIndicatorStatus(faceSquareState.correct);
    }

    if (response.success) {
      new DeviceUtils().vibrate();

      navigate(
        isRegisterMode()
          ? RoutesList.vinculateEmail
          : RoutesList.CompletedLivenessChallenge
      );
    } else {
      if (response.challengeBoxPosition) {
        const newPositions = new CameraUtils().calculateNewPosition(response);
        new DeviceUtils().completedStep();

        setLivenessProps({
          y: newPositions.y,
          x: newPositions.x,
          showIndicator: true,
          remainingSeconds: 120,
        });
      }

      if (response.challengeMode) {
        if (response.challengeMode == LivenessTestsModes.yaw) {
          setLivenessProps({
            ...livenessProps,
            showIndicator: false,
          });
        }

        if (configChallenge.challengeType != response.challengeMode) {
          new DeviceUtils().completedStep();
        }

        setConfigChallenge({
          challengeModeSide: response.challengeModeSide,
          challengeType: response.challengeMode,
          hasStartedProcess: true,
          faceIsTooClose: response.faceIsTooClose,
          faceIsTooFar: response.faceIsTooFar,
          faceIsOutTheBox: response.faceIsOutTheBox,
          faceIsNotLikeDocument: faceIsNotLikeDocument,
        });
      }

      setFaceIsTooFar(response.faceIsTooFar);
      setFaceIsTooClose(response.faceIsTooClose);
      setFaceIsOutTheBox(response.faceIsOutTheBox);

      handleContinueProcess();
    }
  };

  const startChallenge = () => {
    dateStartedChallenge = new Date();
    setInterval(function (this: any) {
      const interval = this;

      setRemainingSeconds((value: number) => {
        if (value <= 0) {
          clearInterval(interval);
          return 0;
        }
        return value - 1;
      });
    }, 1000);
    handleStartProccess();
  };

  const takePhoto = (): string => {
    return webcamRef.current.getScreenshot();
  };

  const checkIfIsOutOfTime = (): boolean => {
    const seconds = Math.floor(
      Math.abs(dateStartedChallenge!.getTime() - new Date().getTime()) / 1000
    );

    const isOutOfTime = seconds >= 120;

    if (isOutOfTime) {
      setOpenModalError(true);
    }

    return isOutOfTime;
  };

  const closeModalError = () => {
    setOpenModalError(false);
    navigate(RoutesList.InstructionOneVerificationFacial);
  };

  useEffect(() => {
    if (appContext.state.blockFacialVerf) {
      navigate(
        isRegisterMode() ? RoutesList.vinculateEmail : RoutesList.EndFlowScreen
      );
    }
  }, [appContext.state]);

  return (
    <div className="liveness_challenge_content">
      <div className="camera-container">
        <Camera
          isVideo
          cameraRef={webcamRef}
          mirrored
          livenessProps={{
            ...livenessProps,
            state: faceIndicatorStatus,
            remainingSeconds,
          }}
          hasStartedProcces={hasStartedProcces}
        />
      </div>
      <div className="liveness_challenge_layer_one">
        <div
          className="liveness_challenge_layer_two"
          onClick={() => setFaceIsIncorrect(true)}
        >
          <LayoutVerificationsHeader mode={VerificationsMode.facial} />
        </div>
        <p
          className="verification-paragraph center_text"
          onClick={() => {
            navigate(RoutesList.vinculateEmail);
          }}
          style={
            faceIsTooFar ||
            faceIsOutTheBox ||
            faceIsTooClose ||
            faceIsNotLikeDocument
              ? { color: "red" }
              : {}
          }
        >
          {new LivenessChallengeUtils().getHelper({
            ...configChallenge,
            faceIsTooFar: faceIsTooFar,
            faceIsTooClose: faceIsTooClose,
            faceIsOutTheBox: faceIsOutTheBox,
            faceIsNotLikeDocument: faceIsNotLikeDocument,
          })}
        </p>

        <div className="container-button">
          <Button
            label={hasStartedProcces ? "Grabando..." : "Iniciar grabación"}
            onTap={hasStartedProcces ? () => {} : startChallenge}
          />
        </div>
      </div>

      <Sheet
        isOpen={faceIsIncorrect}
        onClose={() => setFaceIsIncorrect(false)}
        springConfig={{
          stiffness: 2000,
          damping: 10,
          mass: 0.1,
        }}
      >
        <div className="modal-full-height">
          <Sheet.Container>
            <ModalFaceIncorrect />
          </Sheet.Container>
        </div>
      </Sheet>

      <Sheet
        isOpen={openModalError}
        onClose={closeModalError}
        prefersReducedMotion
      >
        <Sheet.Container>
          <div
            className={`verification_identity_screen verification_identity_screen_finished`}
          >
            <img src={IlustrationFailed} />

            <h2>{"Intenta nuevamente"}</h2>

            <p>Se ha acabo el tiempo</p>

            <Button label="Intentar nuevamente" onTap={closeModalError} />
          </div>
        </Sheet.Container>
      </Sheet>
    </div>
  );
}

export default LivenessChallenge;
