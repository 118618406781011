export enum VerificationsMode {
  facial,
  identity,
  address,
}

export enum LivenessTestsModes {
  checkpoints = "CHECKPOINTS",
  yaw = "YAW",
  smile = "SMILE",
  eye = "EYES",
}

export enum LivenessTestsSides {
  left = "left",
  right = "right",
}

export interface LivenessChallengeConfig {
  challengeType: string;
  challengeModeSide?: string;
  hasStartedProcess: boolean;
  faceIsTooFar: boolean;
  faceIsNotLikeDocument: boolean;
  faceIsOutTheBox: boolean;
  faceIsTooClose: boolean;
}

export enum IdentityVerfErrors {
  badImage = "BAD_IMAGE_SENT",
  tinyBufferImage = "TINY_BUFFER_IMAGE",
  duplicatedDocumentNumber = "DUPLICATED_DOCUMENT_NUMBER",
  idle = "IDLE",
  errorFetchingData = "ERROR_FETCHING_DATA",
  unathorized = "UNAUTHORIZED",
}

export interface IdentityDataUser {
  userDocumentNumber: string;
  userFirstName: string;
  userLastName: string;
  document_number?: string;
  last_name?: string;
  first_name?: string;
}

export interface CountryWithOCR {
  name: string;
  abbreviation: string;
  flag_image: string;
  supported_documents: SupportedDocument[];
}

export interface SupportedDocument {
  type: Type;
  abbreviation: Abbreviation;
  double_sided: boolean;
}

export enum Abbreviation {
  DL = "DL",
  ID = "ID",
  Pp = "PP",
}

export enum Type {
  CedulaDeIdentidad = "CEDULA DE IDENTIDAD",
  LicenciaDeConducir = "LICENCIA DE CONDUCIR",
  Pasaporte = "PASAPORTE",
}
