import withReactContent from "sweetalert2-react-content";
import VerificationCancelledModal from "../../components/modals/verf_cancelled/verification_cancelled_modal";
import { LocalStorageItems } from "../../models/local_storage/local_storage";
import { UserUtils } from "../user/user_utils";
import Swal from "sweetalert2";
import { NavigateFunction } from "react-router-dom";

export class SocketUtils {
  onMessage = (event: MessageEvent, navigate: NavigateFunction) => {
    const json = JSON.parse(event.data);
    console.log("socket event");
    console.log(json.message, json.status);
    const action: string = json?.message || "";

    switch (action) {
      case "liveness-challenge-status-updated":
        if (json.status === "CANCELLED") {
          withReactContent(Swal).fire({
            showCloseButton: false,
            width: "88vw",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: false,
            padding: "0 0 0 0",
            html: <VerificationCancelledModal />,
          });
        }
        break;
    }
  };

  onOpen = (ws: WebSocket) => {
    ws.send(
      JSON.stringify({
        action: "liveness-challenge-announcer",
        args: {
          user: new UserUtils().getUserId(),
        },
      })
    );
  };
}
