/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import LayoutVerification from "../../../../components/layouts/veriifcations_layout/layout_verifications";
import { VerificationsMode } from "../../../../models/verifications/verifications_models";
import Ilustration from "../../../../assets/ilustrations/facialstep4.svg";
import VerificationsFooter from "../../../../components/layouts/veriifcations_layout/footer/verifications_footer";
import { useNavigate } from "react-router-dom";
import { RoutesList } from "../../../../utils/routes/routes_utils";

function InstructionFourVerificationFacial() {
  return (
    <LayoutVerification
      mode={VerificationsMode.facial}
      footer={
        <VerificationsFooter
          hideSkipInstructions
          currentStep={4}
          routeNext={RoutesList.LivenessChallenge}
        />
      }
    >
      <div className="verification_facial_instructions">
        <div className="center">
          <img src={Ilustration} />
        </div>
        <p className="verification-paragraph">
          Evita tapar parte o todo tu rostro con tus manos, esto no permitirá
          identificar tus rasgos.
        </p>
      </div>
    </LayoutVerification>
  );
}

export default InstructionFourVerificationFacial;
