import React, { useContext } from "react";
import { RaceBy } from "@uiball/loaders";
import AppContext from "../../../utils/context/initial/initial_context";

function LoaderSpinner() {
  const context = useContext(AppContext);

  if (!context.state?.isLoading) return <div></div>;

  return (
    <div className="spinner-wrapper">
      <RaceBy size={120} lineWeight={8} speed={1.2} color="white" />
    </div>
  );
}

export default LoaderSpinner;
