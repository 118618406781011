/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import AppContext, {
  initialContext,
} from "./utils/context/initial/initial_context";
import Context, { ContextState } from "./models/context/context_model";
import {
  LocalStorage,
  isRegisterMode,
} from "./utils/local_storage/local_storage";
import { RoutesList, routes } from "./utils/routes/routes_utils";
import {
  RouterProvider,
  createBrowserRouter,
  useNavigate,
} from "react-router-dom";
import LoaderSpinner from "./components/elements/loaders/loader";
import { Toaster, toast } from "react-hot-toast";
import { SocketUtils } from "./utils/socket/socket_utils";
import { IdentityService } from "./service/identity/identity_service";
import VerificationCancelledModal from "./components/modals/verf_cancelled/verification_cancelled_modal";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import RegisterCancelledScreen from "./components/modals/register_cancelled/register_cancelled_screen";

function App() {
  const [context, setContext] = useState<ContextState>(initialContext);
  let hasRunnedCheckLastTimeStamp = false;

  const changeContext = (newState: object) => {
    setContext((val: any) => {
      const newContext = { ...val, ...newState };

      return newContext;
    });
  };

  const changeContextWithState = (newState: Function) => {
    setContext((val: any) => {
      const newContext = { ...val, ...newState(val) };

      new LocalStorage().saveContext(newContext);

      return newContext;
    });
  };

  const router = createBrowserRouter(routes());

  const contextValue: Context = {
    state: context,
    isLogged: context.access_token != null,
    changeContext,
    changeContextWithState,
  };

  window.addEventListener("beforeunload", function (event) {
    const isFromModal = localStorage.getItem("a") === "true";

    if (isFromModal) {
      return;
    }

    onClosePage();
    event.preventDefault();
    event.returnValue = "¿Realmente desea abandonar la página?";
  });

  function onClosePage() {
    saveTimestamp();
  }

  function saveTimestamp() {
    const timestamp = new Date().getTime();
    localStorage.setItem("timestamp", timestamp.toString());
  }

  function checkLastTimeStamp() {
    if (hasRunnedCheckLastTimeStamp) return;

    hasRunnedCheckLastTimeStamp = true;
    const isFromModal = localStorage.getItem("a") === "true";

    const savedTimestamp: string | null = localStorage.getItem("timestamp");

    if (!savedTimestamp || isFromModal) {
      localStorage.removeItem("a");
      return;
    }

    const currentTimestamp = new Date().getTime();
    const differenceInSeconds =
      (currentTimestamp - parseInt(savedTimestamp)) / 1000;

    if (differenceInSeconds > 2) {
    } else {
      withReactContent(Swal).fire({
        showCloseButton: false,
        width: "88vw",
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        showCancelButton: false,
        padding: "0 0 0 0",
        html: isRegisterMode() ? (
          <RegisterCancelledScreen />
        ) : (
          <VerificationCancelledModal fullScreen />
        ),
      });
    }

    localStorage.removeItem("timestamp");
  }

  window.addEventListener("unload", function (event: BeforeUnloadEvent) {
    if (event.returnValue.toString() !== "") {
      context.ws?.close();
      onClosePage();
    }
  });

  useEffect(() => {
    if (process.env.REACT_APP_DEV_MODE == "true") {
      return;
    }

    checkLastTimeStamp();

    const identityDataUserString: string | null =
      localStorage.getItem("identityDataUser");

    if (identityDataUserString) {
      changeContext({ identityDataUser: JSON.parse(identityDataUserString) });
    }
  }, []);

  return (
    <AppContext.Provider value={contextValue}>
      <LoaderSpinner />
      <div className="toaster-container">
        <Toaster />
      </div>
      <RouterProvider router={router} />
    </AppContext.Provider>
  );
}

export default App;
