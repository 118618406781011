/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import IlustrationSuccess from "../../../../assets/ilustrations/verificationfinished.svg";
import IlustrationFailed from "../../../../assets/ilustrations/register_cancelled.svg";
import Button from "../../../../components/elements/buttons/button";
import IdentityInstructions from "../../../../components/verf_identity/instructions/identity_instructions";
import { Route, useNavigate } from "react-router-dom";
import { RoutesList } from "../../../../utils/routes/routes_utils";
import { IdentityService } from "../../../../service/identity/identity_service";
import { IdentityVerfErrors } from "../../../../models/verifications/verifications_models";
import { IdentityUtils } from "../../../../utils/identity/identity_utils";
import useAppContext from "../../../../utils/context/useAppContext/use_app_context";
import { UserUtils } from "../../../../utils/user/user_utils";
import ButtonPickImage from "../../../../components/elements/buttons/button_pick_image";

function ErrorTwiceModal(props: any) {
  const navigate = useNavigate();

  return (
    <div
      className={`verification_identity_screen verification_identity_screen_finished verification_identity_screen_finished_true`}
      style={{ height: "100vh" }}
    >
      <img src={IlustrationFailed} />

      <p style={{ color: "#2bbfed", fontWeight: "bold", marginBottom: "0" }}>
        Parece que ha ocurrido un error.
      </p>
      <p
        className="verification-paragraph"
        style={{ color: "black", fontSize: "3.8vw" }}
      >
        ¡Pero tenemos otra solución disponible para ti.
      </p>

      <Button
        label="Reiniciar proceso de verificación"
        onTap={() => navigate(RoutesList.WelcomeRegister)}
      />
      <ButtonPickImage setFile={props.onTakePhoto} />
    </div>
  );
}

export default ErrorTwiceModal;
