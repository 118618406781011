import { State } from "./address_service";

export const spainCities: State[] = [
  {
    name: "Andalucía",
    alias: ["Andalucía"],
    municipalities: [
      "Sevilla",
      "Málaga",
      "Córdoba",
      "Granada",
      "Jerez de la Frontera",
      "Huelva",
      "Almería",
      "Marbella",
      "Dos Hermanas",
      "Cádiz",
      "Algeciras",
      "Jaén",
      "El Ejido",
      "Mijas",
      "Fuengirola",
      "Níjar",
      "Torremolinos",
      "Linares",
      "Roquetas de Mar",
      "La Línea de la Concepción",
      "Alcalá de Guadaíra",
      "Utrera",
      "Adra",
      "Estepona",
      "Motril",
      "San Fernando",
      "Antequera",
      "Andújar",
      "Écija",
      "Vélez-Málaga",
      "Almuñécar",
      "Los Palacios y Villafranca",
      "Almonte",
      "Puente Genil",
      "Sanlúcar de Barrameda",
      "Baza",
      "Loja",
      "Camas",
      "Vélez-Rubio",
      "Priego de Córdoba",
      "Lepe",
      "Palma del Río",
      "Villanueva de la Serena",
      "Ubrique",
      "Bollullos Par del Condado",
      "La Carlota",
      "Alhaurín de la Torre",
      "Aracena",
      "Écija",
      "La Carolina",
      "Guadix",
      "Lucena",
      "Villanueva del Arzobispo",
      "Bormujos",
      "San Juan de Aznalfarache",
      "Alcalá la Real",
      "Osuna",
      "Huércal-Overa",
      "Guadalcanal",
      "Punta Umbría",
      "Alhaurín el Grande",
      "Huércal de Almería",
      "Nerja",
      "Pozoblanco",
      "Albox",
      "Villacarrillo",
      "Gines",
      "Cabra",
      "Los Barrios",
      "Benalmádena",
      "Arroyo de la Miel",
      "San Bartolomé de Tirajana",
      "Alcalá del Río",
      "Morón de la Frontera",
      "Mairena del Aljarafe",
      "Arcos de la Frontera",
      "Ronda",
      "Campillos",
      "San Roque",
    ],
  },
  {
    name: "Aragón",
    alias: ["Aragón"],
    municipalities: [
      "Zaragoza",
      "Huesca",
      "Teruel",
      "Calatayud",
      "Ejea de los Caballeros",
      "Utebo",
      "Monzón",
      "Barbastro",
      "Alcañiz",
      "Fraga",
      "Jaca",
      "Caspe",
      "Tarazona",
      "Sabiñánigo",
      "Borja",
      "La Almunia de Doña Godina",
      "Binéfar",
      "Cuarte de Huerva",
      "Sariñena",
      "Tauste",
      "Mequinenza",
      "Alcorisa",
      "Zuera",
      "Cariñena",
      "Andorra",
      "Calamocha",
      "Graus",
      "Calanda",
      "Escatrón",
      "Villanueva de Gállego",
      "Albaida del Aljarafe",
      "Monreal del Campo",
      "Aínsa-Sobrarbe",
      "Cinco Olivas",
      "Belchite",
      "Fuentes de Ebro",
      "Mora de Rubielos",
      "Mallén",
      "Almudévar",
      "Illueca",
      "Ateca",
      "La Puebla de Alfindén",
      "Utrillas",
      "Villamayor de Gállego",
      "Gallur",
      "Alfajarín",
      "Ricla",
      "Sallent de Gállego",
      "Épila",
      "Santa Eulalia del Campo",
      "Villanueva de Huerva",
      "Alcolea de Cinca",
      "Bielsa",
      "Cetina",
      "La Muela",
      "Ejea de los Caballeros",
      "Gurrea de Gállego",
      "Leciñena",
      "Nonaspe",
      "Sádaba",
      "Villarquemado",
      "Azaila",
      "Brea de Aragón",
      "Calaceite",
      "Campo",
      "Castejón de Monegros",
      "Castejón de Sos",
      "Fayón",
      "Fuentespalda",
      "Grañén",
      "Isábena",
      "Lanaja",
      "Loporzano",
      "Miedes de Aragón",
      "Pina de Ebro",
      "Quinto",
      "Sástago",
      "Tardienta",
      "Velilla de Ebro",
      "Villafranca del Campo",
      "Villamayor de Gállego",
      "Zaidín",
      "Fabara",
      "Ainzón",
      "Alhama de Aragón",
      "Altorricón",
      "Azanuy-Alins",
      "Baldellou",
      "Barbués",
      "Benabarre",
      "Benasque",
      "Berbegal",
      "Bisaurri",
      "Bonansa",
      "Camarillas",
      "Capdesaso",
      "Capella",
      "Capella",
      "Castelnou",
      "Castiello de Jaca",
      "Colungo",
      "Estadilla",
      "Fago",
      "Foradada del Toscar",
      "Gurrea de Gállego",
      "Hoz de Jaca",
      "Igriés",
      "Juslibol",
      "Labuerda",
      "La Puebla de Castro",
      "Lascellas-Ponzano",
      "Laspaúles",
      "Loarre",
      "Montanuy",
      "Monzón",
      "Novales",
      "Perdiguera",
      "Pertusa",
      "Peralta de Alcofea",
      "Peralta de Calasanz",
      "Puebla de Castro",
      "Puente de Montañana",
      "San Esteban de Litera",
      "San Juan de Plan",
      "Secastilla",
      "Sesa",
      "Sopeira",
      "Torres de Alcanadre",
      "Tramaced",
      "Valfarta",
      "Vencillón",
      "Viacamp y Litera",
      "Villanova",
      "Zuera",
    ],
  },
  {
    name: "Asturias",
    alias: ["Asturias"],
    municipalities: [
      "Gijón",
      "Oviedo",
      "Avilés",
      "Siero",
      "Langreo",
      "Mieres",
      "Castrillón",
      "Corvera de Asturias",
      "San Martín del Rey Aurelio",
      "Llanera",
      "Cangas del Narcea",
      "Valdés",
      "Villaviciosa",
      "Carreño",
      "Gozón",
      "Aller",
      "Grado",
      "Navia",
      "Lena",
      "Piloña",
      "Cangas de Onís",
      "Soto del Barco",
      "Cudillero",
      "Tineo",
      "Pravia",
      "Illas",
      "Vegadeo",
      "Ribadesella",
      "Boal",
      "Colunga",
      "Villanueva de Oscos",
      "Villaviciosa",
      "Villayón",
      "Navia",
      "Tapia de Casariego",
      "Castrillón",
      "Cangas del Narcea",
      "Allande",
      "Gozón",
      "Llanes",
      "Noreña",
      "Villaviciosa",
      "Cangas de Narcea",
      "Parres",
      "Ribadesella",
      "Laviana",
      "Soto del Barco",
      "Ponga",
      "Valdés",
      "Boal",
      "Peñamellera Alta",
      "Grado",
      "Coaña",
      "Caravia",
      "Valdés",
      "Navia",
      "Salas",
      "Aller",
      "Belmonte de Miranda",
      "Siero",
      "Castrillón",
      "Mieres",
      "Ribadedeva",
      "Lena",
      "Carreño",
      "Langreo",
      "Onís",
      "Navia",
      "Pravia",
      "Illas",
      "Vegadeo",
      "Candamo",
      "Villayón",
      "Villanueva de Oscos",
      "Taramundi",
      "Cudillero",
      "Nava",
      "Castropol",
      "Sariego",
      "Amieva",
      "Laviana",
      "Piloña",
      "Parres",
      "Tineo",
      "Colunga",
      "Santo Adriano",
      "Peñamellera Baja",
      "Tapia de Casariego",
      "Ribera de Arriba",
      "Illano",
      "Ribadesella",
      "Tapia de Casariego",
      "Ponga",
      "Valdés",
      "Boal",
      "Villaviciosa",
      "Lena",
      "Gozón",
      "Mieres",
      "Castrillón",
      "Parres",
      "Llanes",
      "Nava",
      "Allande",
      "Carreño",
      "Valdés",
      "Navia",
      "Soto del Barco",
      "Cangas del Narcea",
      "Salas",
      "Langreo",
      "Candamo",
      "Pravia",
      "Illas",
      "Cangas de Onís",
      "Aller",
      "Vegadeo",
      "Castrillón",
      "Piloña",
      "Cudillero",
      "Sariego",
      "Colunga",
      "Villanueva de Oscos",
      "Amieva",
      "Belmonte de Miranda",
      "Onís",
      "Peñamellera Alta",
      "Ribadedeva",
      "Caravia",
      "Villayón",
      "Tineo",
      "Tapia de Casariego",
      "Cangas de Onís",
      "Villaviciosa",
      "Langreo",
      "Gijón",
      "Oviedo",
      "Avilés",
      "Siero",
      "Castrillón",
      "Mieres",
      "Lena",
      "Cangas del Narcea",
      "Valdés",
      "Villaviciosa",
      "Carreño",
      "Gozón",
      "Aller",
      "Langreo",
      "Mieres",
      "Castrillón",
      "Corvera de Asturias",
      "San Martín del Rey Aurelio",
      "Llanera",
      "Cangas del Narcea",
      "Valdés",
      "Villaviciosa",
      "Carreño",
      "Gozón",
      "Aller",
      "Grado",
      "Navia",
      "Lena",
      "Piloña",
      "Cangas de Onís",
      "Soto del Barco",
      "Cudillero",
      "Tineo",
      "Pravia",
      "Illas",
      "Vegadeo",
      "Ribadesella",
      "Boal",
      "Colunga",
      "Villanueva de Oscos",
      "Villaviciosa",
      "Villayón",
      "Navia",
      "Tapia de Casariego",
      "Castrillón",
      "Cangas del Narcea",
      "Allande",
      "Gozón",
      "Llanes",
      "Noreña",
      "Villaviciosa",
      "Cangas de Narcea",
      "Parres",
      "Ribadesella",
      "Laviana",
      "Soto del Barco",
      "Ponga",
      "Valdés",
      "Boal",
      "Peñamellera Alta",
      "Grado",
      "Coaña",
      "Caravia",
      "Valdés",
      "Navia",
      "Salas",
      "Aller",
      "Belmonte de Miranda",
      "Siero",
      "Castrillón",
      "Mieres",
      "Ribadedeva",
      "Lena",
      "Carreño",
      "Langreo",
      "Onís",
      "Navia",
      "Pravia",
      "Illas",
      "Vegadeo",
      "Candamo",
      "Villayón",
      "Villanueva de Oscos",
      "Taramundi",
      "Cudillero",
      "Nava",
      "Castropol",
      "Sariego",
      "Amieva",
      "Laviana",
      "Piloña",
      "Parres",
      "Tineo",
      "Colunga",
      "Santo Adriano",
      "Peñamellera Baja",
      "Tapia de Casariego",
      "Ribera de Arriba",
      "Illano",
      "Ribadesella",
      "Tapia de Casariego",
      "Ponga",
      "Valdés",
      "Boal",
      "Villaviciosa",
      "Lena",
      "Gozón",
      "Mieres",
      "Castrillón",
      "Parres",
      "Llanes",
      "Nava",
      "Allande",
      "Carreño",
      "Valdés",
      "Navia",
      "Soto del Barco",
      "Cangas del Narcea",
      "Salas",
      "Langreo",
      "Candamo",
      "Pravia",
      "Illas",
      "Cangas de Onís",
      "Aller",
      "Vegadeo",
      "Castrillón",
      "Piloña",
      "Cudillero",
      "Sariego",
      "Colunga",
      "Villanueva de Oscos",
      "Amieva",
      "Belmonte de Miranda",
      "Onís",
      "Peñamellera Alta",
      "Ribadedeva",
      "Caravia",
      "Villayón",
      "Tineo",
      "Tapia de Casariego",
    ],
  },
  {
    name: "Islas Baleares",
    alias: ["Baleares"],
    municipalities: [
      "Palma de Mallorca",
      "Calvià",
      "Manacor",
      "Llucmajor",
      "Inca",
      "Marratxí",
      "Mao (Mahón)",
      "Eivissa",
      "Santa Eulària des Riu",
      "Ciutadella de Menorca",
      "Sant Antoni de Portmany",
      "Sóller",
      "Felanitx",
      "Alcúdia",
      "Pollensa",
      "Andratx",
      "Sa Pobla",
      "Capdepera",
      "Sant Josep de sa Talaia",
      "Campos",
      "Sant Llorenç des Cardassar",
      "Valldemossa",
      "Artà",
      "Santa Margalida",
      "Ses Salines",
      "Bunyola",
      "Lloseta",
      "Porreres",
      "Santa Maria del Camí",
      "Muro",
      "Llubí",
      "Fornalutx",
      "Son Servera",
      "Pollença",
      "Esporles",
      "Montuïri",
      "Estellencs",
      "Sant Joan de Labritja",
      "Sineu",
      "Alaró",
      "Algaida",
      "Lloret de Vistalegre",
      "Binissalem",
      "Sant Lluís",
      "Ferreries",
      "Maria de la Salut",
      "Santanyí",
      "Consell",
      "Santa Eugènia",
      "Sencelles",
      "Llucmajor",
      "Mao (Mahón)",
      "Andratx",
      "Calvià",
      "Pollença",
      "Manacor",
      "Felanitx",
      "Sant Josep de sa Talaia",
      "Sóller",
      "Eivissa",
      "Llubí",
      "Campos",
      "Marratxí",
      "Alcúdia",
      "Porreres",
      "Lloseta",
      "Llucmajor",
      "Inca",
      "Sant Llorenç des Cardassar",
      "Santa Maria del Camí",
      "Sa Pobla",
      "Son Servera",
      "Binissalem",
      "Valldemossa",
      "Muro",
      "Montuïri",
      "Búger",
      "Artà",
      "Sant Joan",
      "Es Castell",
      "Costitx",
      "Santanyí",
      "Maria de la Salut",
      "Alaior",
      "Petra",
      "Santa Margalida",
      "Ses Salines",
      "Fornalutx",
      "Estellencs",
      "Mancor de la Vall",
      "Banyalbufar",
      "Sencelles",
      "Algaida",
      "Ferreries",
      "Sant Lluís",
      "Santa Eugènia",
      "Consell",
      "Sant Joan de Labritja",
      "Sineu",
      "Lloret de Vistalegre",
      "Santa Margalida",
      "Ses Salines",
      "Artà",
      "Llucmajor",
      "Inca",
      "Manacor",
      "Marratxí",
      "Mao (Mahón)",
      "Eivissa",
      "Santa Eulària des Riu",
      "Ciutadella de Menorca",
      "Sant Antoni de Portmany",
      "Sóller",
      "Felanitx",
      "Alcúdia",
      "Pollensa",
      "Andratx",
      "Sa Pobla",
      "Capdepera",
      "Sant Josep de sa Talaia",
      "Campos",
      "Sant Llorenç des Cardassar",
      "Valldemossa",
      "Artà",
      "Santa Margalida",
      "Ses Salines",
      "Bunyola",
      "Lloseta",
      "Porreres",
      "Santa Maria del Camí",
      "Muro",
      "Llubí",
      "Fornalutx",
      "Son Servera",
      "Pollença",
      "Esporles",
      "Montuïri",
      "Estellencs",
      "Sant Joan de Labritja",
      "Sineu",
      "Alaró",
      "Algaida",
      "Lloret de Vistalegre",
      "Binissalem",
      "Sant Lluís",
      "Ferreries",
      "Maria de la Salut",
      "Santanyí",
      "Consell",
      "Santa Eugènia",
      "Sencelles",
      "Llucmajor",
      "Mao (Mahón)",
      "Andratx",
      "Calvià",
      "Pollença",
      "Manacor",
      "Felanitx",
      "Sant Josep de sa Talaia",
      "Sóller",
      "Eivissa",
      "Llubí",
      "Campos",
      "Marratxí",
      "Alcúdia",
      "Porreres",
      "Lloseta",
      "Llucmajor",
      "Inca",
      "Sant Llorenç des Cardassar",
      "Santa Maria del Camí",
      "Sa Pobla",
      "Son Servera",
      "Binissalem",
      "Valldemossa",
      "Muro",
      "Montuïri",
      "Búger",
      "Artà",
      "Sant Joan",
      "Es Castell",
      "Costitx",
      "Santanyí",
      "Maria de la Salut",
      "Alaior",
      "Petra",
      "Santa Margalida",
      "Ses Salines",
      "Fornalutx",
      "Estellencs",
      "Mancor de la Vall",
      "Banyalbufar",
      "Sencelles",
      "Algaida",
      "Ferreries",
      "Sant Lluís",
      "Santa Eugènia",
      "Consell",
      "Sant Joan de Labritja",
      "Sineu",
    ],
  },
  {
    name: "Canarias",
    alias: ["Islas Canarias"],
    municipalities: [
      "Las Palmas de Gran Canaria",
      "Santa Cruz de Tenerife",
      "San Cristóbal de La Laguna",
      "Telde",
      "La Orotava",
      "Arrecife",
      "Puerto del Rosario",
      "Adeje",
      "La Laguna",
      "Los Realejos",
      "San Bartolomé de Tirajana",
      "La Oliva",
      "Mogán",
      "Güímar",
      "Icod de los Vinos",
      "Arona",
      "Santiago del Teide",
      "Granadilla de Abona",
      "Guía de Isora",
      "San Miguel de Abona",
      "Santa Lucía de Tirajana",
      "Valverde",
      "Tías",
      "Agüimes",
      "Breña Alta",
      "Los Llanos de Aridane",
      "Pájara",
      "Tuineje",
      "Vallehermoso",
      "Teguise",
      "Villa de Mazo",
      "Tazacorte",
      "Tegueste",
      "Antigua",
      "Tinajo",
      "Puntagorda",
      "Hermigua",
      "El Rosario",
      "El Paso",
      "Villa de Garafía",
      "El Sauzal",
      "Haría",
      "Vilaflor de Chasna",
      "Valle Gran Rey",
      "Fasnia",
      "San Juan de la Rambla",
      "La Victoria de Acentejo",
      "Buenavista del Norte",
      "Santo Domingo de Garafía",
      "Tijarafe",
      "Agulo",
      "Santa Brígida",
      "Arafo",
      "El Tanque",
      "Los Silos",
      "La Guancha",
      "Valle de San Lorenzo",
      "Barlovento",
      "Valsequillo de Gran Canaria",
      "Moya",
      "Los Realejos",
      "San Bartolomé de Tirajana",
      "La Oliva",
      "Mogán",
      "Güímar",
      "Icod de los Vinos",
      "Arona",
      "Santiago del Teide",
      "Granadilla de Abona",
      "Guía de Isora",
      "San Miguel de Abona",
      "Santa Lucía de Tirajana",
      "Valverde",
      "Tías",
      "Agüimes",
      "Breña Alta",
      "Los Llanos de Aridane",
      "Pájara",
      "Tuineje",
      "Vallehermoso",
      "Teguise",
      "Villa de Mazo",
      "Tazacorte",
      "Tegueste",
      "Antigua",
      "Tinajo",
      "Puntagorda",
      "Hermigua",
      "El Rosario",
      "El Paso",
      "Villa de Garafía",
      "El Sauzal",
      "Haría",
      "Vilaflor de Chasna",
      "Valle Gran Rey",
      "Fasnia",
      "San Juan de la Rambla",
      "La Victoria de Acentejo",
      "Buenavista del Norte",
      "Santo Domingo de Garafía",
      "Tijarafe",
      "Agulo",
      "Santa Brígida",
      "Arafo",
      "El Tanque",
      "Los Silos",
      "La Guancha",
      "Valle de San Lorenzo",
      "Barlovento",
      "Valsequillo de Gran Canaria",
      "Moya",
      "Los Realejos",
      "San Bartolomé de Tirajana",
      "La Oliva",
      "Mogán",
      "Güímar",
      "Icod de los Vinos",
      "Arona",
      "Santiago del Teide",
      "Granadilla de Abona",
      "Guía de Isora",
      "San Miguel de Abona",
      "Santa Lucía de Tirajana",
      "Valverde",
      "Tías",
      "Agüimes",
      "Breña Alta",
      "Los Llanos de Aridane",
      "Pájara",
      "Tuineje",
      "Vallehermoso",
      "Teguise",
      "Villa de Mazo",
      "Tazacorte",
      "Tegueste",
      "Antigua",
      "Tinajo",
      "Puntagorda",
      "Hermigua",
      "El Rosario",
      "El Paso",
      "Villa de Garafía",
      "El Sauzal",
      "Haría",
      "Vilaflor de Chasna",
      "Valle Gran Rey",
      "Fasnia",
      "San Juan de la Rambla",
      "La Victoria de Acentejo",
      "Buenavista del Norte",
      "Santo Domingo de Garafía",
      "Tijarafe",
      "Agulo",
      "Santa Brígida",
      "Arafo",
      "El Tanque",
      "Los Silos",
      "La Guancha",
      "Valle de San Lorenzo",
      "Barlovento",
      "Valsequillo de Gran Canaria",
      "Moya",
    ],
  },
  {
    name: "Cantabria",
    alias: ["Cantabria"],
    municipalities: [
      "Santander",
      "Torrelavega",
      "Camargo",
      "Castro-Urdiales",
      "Santoña",
      "Piélagos",
      "El Astillero",
      "Santa Cruz de Bezana",
      "Laredo",
      "Los Corrales de Buelna",
      "Reinosa",
      "Colindres",
      "Medio Cudeyo",
      "Suances",
      "Val de San Vicente",
      "Ramales de la Victoria",
      "Cabezón de la Sal",
      "Miengo",
      "Polanco",
      "Entrambasaguas",
      "Ajo",
      "Santa María de Cayón",
      "Villaescusa",
      "Cartes",
      "Ribamontán al Mar",
      "Ampuero",
      "Ruiloba",
      "Pesquera",
      "Maliaño",
      "Vioño de Piélagos",
      "Polanco",
      "Sancibrián",
      "Castillo",
      "Muriedas",
      "Barros",
      "Valdecilla",
      "Sobarzo",
      "Hermandad de Campoo de Suso",
      "Campoo de Enmedio",
      "Campoo de Yuso",
      "Polanco",
      "Val de San Vicente",
      "Villacarriedo",
      "Polanco",
      "Val de San Vicente",
      "Santillana del Mar",
      "Bárcena de Cicero",
      "Noja",
      "Castañeda",
      "Potes",
      "Cabezón de Liébana",
      "Limpias",
      "San Vicente de la Barquera",
      "Liérganes",
      "Marina de Cudeyo",
      "Los Tojos",
      "Reocín",
      "San Roque de Riomiera",
      "San Felices de Buelna",
      "Vega de Pas",
      "Valle de Villaverde",
      "Selaya",
      "Rionansa",
      "Santiurde de Toranzo",
      "Valle de Valdeprado",
      "Valdeolea",
      "Corvera de Toranzo",
      "Cieza",
      "Pesaguero",
      "Polanco",
      "Val de San Vicente",
      "Valdáliga",
      "Ribamontán al Monte",
      "Las Rozas de Valdearroyo",
      "Villaescusa",
      "Valderredible",
      "Polanco",
      "Val de San Vicente",
      "Polanco",
      "Val de San Vicente",
      "Polanco",
      "Val de San Vicente",
      "San Miguel de Aguayo",
      "San Pedro del Romeral",
      "San Pedro del Río",
      "San Vicente de la Barquera",
      "Santa Cruz de Bezana",
      "Santa María de Cayón",
      "Santander",
      "Santillana del Mar",
      "Santiurde de Reinosa",
      "Santiurde de Toranzo",
      "Santoña",
      "Saro",
      "Selaya",
      "Soba",
      "Solórzano",
      "Suances",
      "Tojos, Los",
      "Torrelavega",
      "Tresviso",
      "Tudanca",
      "Udías",
      "Val de San Vicente",
      "Valdáliga",
      "Valdeolea",
      "Valdeprado del Río",
      "Valderredible",
      "Valle de Villaverde",
      "Vega de Liébana",
      "Vega de Pas",
      "Villacarriedo",
      "Villaescusa",
      "Villafufre",
      "Voto",
    ],
  },
  {
    name: "Castilla y León",
    alias: ["Castilla y León"],
    municipalities: [
      "Valladolid",
      "León",
      "Salamanca",
      "Burgos",
      "Palencia",
      "Zamora",
      "Segovia",
      "Ávila",
      "Soria",
      "Ponferrada",
      "San Andrés del Rabanedo",
      "Miranda de Ebro",
      "Aranda de Duero",
      "Laguna de Duero",
      "El Bierzo",
      "Medina del Campo",
      "Tudela de Duero",
      "San Cristóbal de Segovia",
      "Benavente",
      "La Bañeza",
      "Cuéllar",
      "Ponferrada",
      "Villaquilambre",
      "Bembibre",
      "La Virgen del Camino",
      "Castrillo-Tejeriego",
      "Valverde de la Virgen",
      "Valencia de Don Juan",
      "La Cistérniga",
      "Villafranca del Bierzo",
      "Astorga",
      "Gordaliza del Pino",
      "Valderrueda",
      "Valencia de Don Juan",
      "Villaquilambre",
      "Ponferrada",
      "Tordesillas",
      "Villaquilambre",
      "Ponferrada",
      "Guardo",
      "El Bierzo",
      "Bembibre",
      "La Robla",
      "La Virgen del Camino",
      "Cigales",
      "Carrizo de la Ribera",
      "Sahagún",
      "Dueñas",
      "Olmedo",
      "La Pola de Gordón",
      "Tudela de Duero",
      "Cabezón de Pisuerga",
      "Bembibre",
      "La Virgen del Camino",
      "Valencia de Don Juan",
      "Santa María del Páramo",
      "La Cistérniga",
      "Simancas",
      "Villarcayo de Merindad de Castilla la Vieja",
      "Villamuriel de Cerrato",
      "Arévalo",
      "Aguilar de Campoo",
      "Valencia de Don Juan",
      "Villaquilambre",
      "Ponferrada",
      "Guardo",
      "El Bierzo",
      "Bembibre",
      "San Andrés del Rabanedo",
      "Medina del Campo",
      "Tudela de Duero",
      "Puebla de Sanabria",
      "Laguna de Duero",
      "Santovenia de Pisuerga",
      "Almazán",
      "Valverde de la Virgen",
      "Cabezas de Alambre",
      "Béjar",
      "Tordesillas",
      "Carrión de los Condes",
      "El Tiemblo",
      "Benavides",
      "Aguilar de Campoo",
      "Santa Marta de Tormes",
      "Aranda de Duero",
      "Zaratán",
      "Melgar de Fernamental",
      "Valencia de Don Juan",
      "Cigales",
      "La Cistérniga",
      "Guardo",
      "Medina del Campo",
      "Peñafiel",
      "Astudillo",
      "Lumbrales",
      "Tudela de Duero",
      "Villasana de Mena",
      "Villamuriel de Cerrato",
      "Valderrueda",
      "Laguna de Duero",
      "Medina del Campo",
      "Ponferrada",
      "León",
      "Palencia",
      "Valladolid",
      "Burgos",
      "Zamora",
      "Salamanca",
      "Soria",
      "Miranda de Ebro",
      "Aranda de Duero",
      "San Andrés del Rabanedo",
      "Bembibre",
      "Laguna de Duero",
      "Dueñas",
      "Guardo",
      "La Cistérniga",
      "Valencia de Don Juan",
      "Tordesillas",
      "Astorga",
      "El Bierzo",
      "Ponferrada",
      "Burgos",
      "León",
      "Salamanca",
      "Valladolid",
      "Zamora",
      "Segovia",
      "Ávila",
      "Soria",
      "Miranda de Ebro",
      "Aranda de Duero",
      "San Andrés del Rabanedo",
      "Villaquilambre",
      "El Bierzo",
      "Medina del Campo",
      "Tudela de Duero",
      "San Cristóbal de Segovia",
      "Benavente",
      "La Bañeza",
      "Cuéllar",
      "Ponferrada",
      "Guardo",
      "Laguna de Duero",
      "Dueñas",
      "Olmedo",
      "La Cistérniga",
      "Bembibre",
      "Villaquilambre",
      "Ponferrada",
      "Valencia de Don Juan",
      "Santa María del Páramo",
      "Simancas",
      "Villarcayo de Merindad de Castilla la Vieja",
      "Villamuriel de Cerrato",
      "Arévalo",
      "Aguilar de Campoo",
      "Valencia de Don Juan",
      "Villaquilambre",
      "Ponferrada",
      "Guardo",
      "El Bierzo",
      "Bembibre",
      "Medina del Campo",
      "Peñafiel",
      "Astudillo",
      "Lumbrales",
      "Tudela de Duero",
      "Villasana de Mena",
      "Villamuriel de Cerrato",
      "Valderrueda",
      "Santovenia de Pisuerga",
      "Almazán",
      "Cabezas de Alambre",
      "Béjar",
      "Carrión de los Condes",
      "Benavides",
      "Santa Marta de Tormes",
      "Zaratán",
      "Melgar de Fernamental",
      "Cigales",
      "La Cistérniga",
      "Guardo",
      "Medina del Campo",
      "Peñafiel",
      "Astudillo",
      "Lumbrales",
      "Tudela de Duero",
      "Villasana de Mena",
      "Villamuriel de Cerrato",
      "Valderrueda",
      "Santovenia de Pisuerga",
      "Almazán",
      "Cabezas de Alambre",
      "Béjar",
      "Carrión de los Condes",
      "Benavides",
      "Santa Marta de Tormes",
      "Zaratán",
      "Melgar de Fernamental",
    ],
  },
  {
    name: "Castilla-La Mancha",
    alias: ["Castilla-La Mancha", "CLM"],
    municipalities: [
      "Toledo",
      "Albacete",
      "Guadalajara",
      "Ciudad Real",
      "Cuenca",
      "Puertollano",
      "Tomelloso",
      "Alcázar de San Juan",
      "Almansa",
      "Valdepeñas",
    ],
  },
  {
    name: "Cataluña",
    alias: ["Catalunya"],
    municipalities: [
      "Barcelona",
      "L'Hospitalet de Llobregat",
      "Badalona",
      "Terrassa",
      "Sabadell",
      "Tarragona",
      "Lleida",
      "Mataró",
      "Santa Coloma de Gramenet",
      "Girona",
      "Cornellà de Llobregat",
      "Sant Cugat del Vallès",
      "Reus",
      "Sant Boi de Llobregat",
      "Manresa",
      "Rubí",
      "Vilanova i la Geltrú",
      "El Prat de Llobregat",
      "Viladecans",
      "Castelldefels",
      "Cerdanyola del Vallès",
      "Mollet del Vallès",
      "Esplugues de Llobregat",
      "Figueres",
      "Igualada",
      "Granollers",
      "Sant Feliu de Llobregat",
      "Vic",
      "Vila-seca",
      "Vilafranca del Penedès",
      "Blanes",
      "Ripollet",
      "El Vendrell",
      "Palafrugell",
      "Olot",
      "Sant Adrià de Besòs",
      "Cambrils",
      "Vilaseca",
      "Montcada i Reixac",
      "Sant Joan Despí",
      "El Prat",
      "Lloret de Mar",
      "Salou",
      "Sant Pere de Ribes",
      "Molins de Rei",
      "Vilassar de Mar",
      "Sant Vicenç dels Horts",
      "Igualada",
      "Cerdanyola",
      "Castellar del Vallès",
      "Sant Andreu de la Barca",
      "Valls",
      "Ripollet",
      "Lloret",
      "Montcada",
      "Manlleu",
      "Mataró",
      "Viladecavalls",
      "Pineda de Mar",
      "Palafrugell",
      "Sant Celoni",
      "Vilassar",
      "Santa Perpètua de Mogoda",
      "Sant Just Desvern",
      "Vilafranca",
      "Palafrugell",
      "Salt",
      "Amposta",
      "Vic",
      "Calafell",
      "Calella",
      "Mollet",
      "La Garriga",
      "Montornès del Vallès",
      "Sant Sadurní d'Anoia",
      "Castelldefels",
      "Banyoles",
      "Vilanova",
      "El Masnou",
      "Vilassar de Dalt",
      "Palau-solità i Plegamans",
      "La Seu d'Urgell",
      "Vic",
      "Lloret",
      "Granollers",
      "Sant Joan Despí",
      "Castelldefels",
      "Manlleu",
      "Blanes",
      "Sitges",
      "Sant Andreu de la Barca",
      "Igualada",
      "Sant Cugat del Vallès",
      "Molins de Rei",
      "Palafrugell",
      "Olesa de Montserrat",
      "Sant Feliu de Guíxols",
      "Caldes de Montbui",
      "Tàrrega",
      "La Pobla de Farnals",
      "Llagostera",
      "Cerdanyola del Vallès",
      "Sant Quirze del Vallès",
      "Vilafranca del Penedès",
      "Viladecans",
      "Sant Cugat",
      "Mollet",
      "Santa Coloma de Farners",
      "Caldes de Malavella",
      "Malgrat de Mar",
      "Sant Joan de Vilatorrada",
      "Les Franqueses del Vallès",
      "Valls",
      "Palafrugell",
      "Torelló",
      "Figueres",
      "Igualada",
      "Vilassar de Mar",
      "Vic",
      "Sant Joan Despí",
      "Berga",
      "Caldes de Montbui",
      "Vilanova",
      "Sant Feliu de Llobregat",
      "Torredembarra",
      "Canet de Mar",
      "La Canonja",
      "Vila-seca",
      "Calella",
      "El Prat de Llobregat",
      "Tàrrega",
      "El Prat",
      "Santa Coloma de Gramenet",
      "Sant Andreu de la Barca",
      "Sant Fruitós de Bages",
      "Vilassar",
      "Amposta",
      "Lleida",
      "Torredembarra",
      "Cerdanyola",
      "Reus",
      "Sant Cugat",
      "Sant Pere de Ribes",
      "Vilafranca",
      "Cerdanyola del Vallès",
      "Igualada",
      "Cambrils",
      "Sitges",
      "Viladecans",
      "Palafrugell",
      "Olot",
      "Ripollet",
      "Vilafranca del Penedès",
      "Lloret de Mar",
      "Manlleu",
      "Mollet",
      "Santa Coloma de Farners",
      "Sant Adrià de Besòs",
      "Sant Feliu de Guíxols",
      "Montornès del Vallès",
      "Girona",
      "Figueres",
      "Sant Feliu de Llobregat",
      "Sant Just Desvern",
      "Viladecavalls",
      "Les Franqueses del Vallès",
      "Banyoles",
      "La Pobla de Farnals",
      "Caldes de Malavella",
      "Pineda de Mar",
      "Calella",
      "La Garriga",
      "Salou",
      "Sant Joan Despí",
      "Caldes de Montbui",
      "Ripollet",
      "Blanes",
      "Santa Coloma de Gramenet",
      "Vic",
      "Mataró",
      "Vilanova i la Geltrú",
      "Barcelona",
      "L'Hospitalet de Llobregat",
      "Badalona",
      "Terrassa",
      "Sabadell",
      "Tarragona",
      "Lleida",
      "Mataró",
      "Santa Coloma de Gramenet",
      "Girona",
      "Cornellà de Llobregat",
      "Sant Cugat del Vallès",
      "Reus",
      "Sant Boi de Llobregat",
      "Manresa",
      "Rubí",
      "Vilanova i la Geltrú",
      "El Prat de Llobregat",
      "Viladecans",
      "Castelldefels",
      "Cerdanyola del Vallès",
      "Mollet del Vallès",
      "Esplugues de Llobregat",
      "Figueres",
      "Igualada",
      "Granollers",
      "Sant Feliu de Llobregat",
      "Vic",
      "Vila-seca",
      "Vilafranca del Penedès",
      "Blanes",
      "Ripollet",
      "El Vendrell",
      "Palafrugell",
      "Olot",
      "Sant Adrià de Besòs",
      "Cambrils",
      "Vilaseca",
      "Montcada i Reixac",
      "Sant Joan Despí",
      "El Prat",
      "Lloret de Mar",
      "Salou",
      "Sant Pere de Ribes",
      "Molins de Rei",
      "Vilassar de Mar",
      "Sant Vicenç dels Horts",
      "Igualada",
      "Cerdanyola",
      "Castellar del Vallès",
      "Sant Andreu de la Barca",
      "Valls",
      "Ripollet",
      "Lloret",
      "Montcada",
      "Manlleu",
      "Mataró",
      "Viladecavalls",
      "Pineda de Mar",
      "Palafrugell",
      "Sant Celoni",
      "Vilassar",
      "Santa Perpètua de Mogoda",
      "Sant Just Desvern",
      "Vilafranca",
      "Palafrugell",
      "Salt",
      "Amposta",
      "Vic",
      "Calafell",
      "Calella",
      "Mollet",
      "La Garriga",
      "Montornès del Vallès",
      "Sant Sadurní d'Anoia",
      "Castelldefels",
      "Banyoles",
      "Vilanova",
      "El Masnou",
      "Vilassar de Dalt",
      "Palau-solità i Plegamans",
      "La Seu d'Urgell",
      "Vic",
      "Lloret",
      "Granollers",
      "Sant Joan Despí",
      "Castelldefels",
      "Manlleu",
      "Blanes",
      "Sitges",
      "Sant Andreu de la Barca",
      "Igualada",
      "Sant Cugat del Vallès",
      "Molins de Rei",
      "Palafrugell",
      "Olesa de Montserrat",
      "Sant Feliu de Guíxols",
      "Caldes de Montbui",
      "Tàrrega",
      "La Pobla de Farnals",
      "Llagostera",
      "Cerdanyola del Vallès",
      "Sant Quirze del Vallès",
      "Vilafranca del Penedès",
      "Viladecans",
      "Sant Cugat",
      "Mollet",
      "Santa Coloma de Farners",
      "Caldes de Malavella",
      "Malgrat de Mar",
      "Sant Joan de Vilatorrada",
      "Les Franqueses del Vallès",
      "Valls",
      "Palafrugell",
      "Torelló",
      "Figueres",
      "Igualada",
      "Vilassar de Mar",
      "Vic",
      "Sant Joan Despí",
      "Berga",
      "Caldes de Montbui",
      "Vilanova",
      "Sant Feliu de Llobregat",
      "Torredembarra",
      "Canet de Mar",
      "La Canonja",
      "Vila-seca",
      "Calella",
      "El Prat de Llobregat",
      "Tàrrega",
      "El Prat",
      "Santa Coloma de Gramenet",
      "Sant Andreu de la Barca",
      "Sant Fruitós de Bages",
      "Vilassar",
      "Amposta",
      "Lleida",
      "Torredembarra",
      "Cerdanyola",
      "Reus",
      "Sant Cugat",
      "Sant Pere de Ribes",
      "Vilafranca",
      "Cerdanyola del Vallès",
      "Igualada",
      "Cambrils",
      "Sitges",
      "Viladecans",
      "Palafrugell",
      "Olot",
      "Ripollet",
      "Vilafranca del Penedès",
      "Lloret de Mar",
      "Manlleu",
      "Mollet",
      "Santa Coloma de Farners",
      "Sant Adrià de Besòs",
      "Sant Feliu de Guíxols",
      "Montornès del Vallès",
      "Girona",
      "Figueres",
      "Sant Feliu de Llobregat",
      "Sant Just Desvern",
      "Viladecavalls",
      "Les Franqueses del Vallès",
      "Banyoles",
      "La Pobla de Farnals",
      "Caldes de Malavella",
      "Pineda de Mar",
      "Calella",
      "La Garriga",
      "Salou",
      "Sant Joan Despí",
      "Caldes de Montbui",
      "Ripollet",
      "Blanes",
      "Santa Coloma de Gramenet",
      "Vic",
      "Mataró",
      "Vilanova i la Geltrú",
      "Barcelona",
      "L'Hospitalet de Llobregat",
      "Badalona",
      "Terrassa",
      "Sabadell",
      "Tarragona",
      "Lleida",
      "Mataró",
      "Santa Coloma de Gramenet",
      "Girona",
      "Cornellà de Llobregat",
      "Sant Cugat del Vallès",
      "Reus",
      "Sant Boi de Llobregat",
      "Manresa",
      "Rubí",
      "Vilanova i la Geltrú",
      "El Prat de Llobregat",
      "Viladecans",
      "Castelldefels",
      "Cerdanyola del Vallès",
      "Mollet del Vallès",
      "Esplugues de Llobregat",
      "Figueres",
      "Igualada",
      "Granollers",
      "Sant Feliu de Llobregat",
      "Vic",
      "Vila-seca",
      "Vilafranca del Penedès",
      "Blanes",
      "Ripollet",
      "El Vendrell",
      "Palafrugell",
      "Olot",
      "Sant Adrià de Besòs",
      "Cambrils",
      "Vilaseca",
      "Montcada i Reixac",
      "Sant Joan Despí",
      "El Prat",
      "Lloret de Mar",
      "Salou",
      "Sant Pere de Ribes",
      "Molins de Rei",
      "Vilassar de Mar",
      "Sant Vicenç dels Horts",
      "Igualada",
      "Cerdanyola",
      "Castellar del Vallès",
      "Sant Andreu de la Barca",
      "Valls",
      "Ripollet",
      "Lloret",
      "Montcada",
      "Manlleu",
      "Mataró",
      "Viladecavalls",
      "Pineda de Mar",
      "Palafrugell",
      "Sant Celoni",
      "Vilassar",
      "Santa Perpètua de Mogoda",
      "Sant Just Desvern",
      "Vilafranca",
      "Palafrugell",
      "Salt",
      "Amposta",
      "Vic",
      "Calafell",
      "Calella",
      "Mollet",
      "La Garriga",
      "Montornès del Vallès",
      "Sant Sadurní d'Anoia",
      "Castelldefels",
      "Banyoles",
      "Vilanova",
      "El Masnou",
      "Vilassar de Dalt",
      "Palau-solità i Plegamans",
      "La Seu d'Urgell",
      "Vic",
      "Lloret",
      "Granollers",
      "Sant Joan Despí",
      "Castelldefels",
      "Manlleu",
      "Blanes",
      "Sitges",
      "Sant Andreu de la Barca",
      "Igualada",
      "Sant Cugat del Vallès",
      "Molins de Rei",
      "Palafrugell",
      "Olesa de Montserrat",
      "Sant Feliu de Guíxols",
      "Caldes de Montbui",
      "Tàrrega",
      "La Pobla de Farnals",
      "Llagostera",
      "Cerdanyola del Vallès",
      "Sant Quirze del Vallès",
      "Vilafranca del Penedès",
      "Viladecans",
      "Sant Cugat",
      "Mollet",
      "Santa Coloma de Farners",
      "Caldes de Malavella",
      "Malgrat de Mar",
      "Sant Joan de Vilatorrada",
      "Les Franqueses del Vallès",
      "Valls",
      "Palafrugell",
      "Torelló",
      "Figueres",
      "Igualada",
      "Vilassar de Mar",
      "Vic",
      "Sant Joan Despí",
      "Berga",
      "Caldes de Montbui",
      "Vilanova",
      "Sant Feliu de Llobregat",
      "Torredembarra",
      "Canet de Mar",
      "La Canonja",
      "Vila-seca",
      "Calella",
      "El Prat de Llobregat",
      "Tàrrega",
      "El Prat",
      "Santa Coloma de Gramenet",
      "Sant Andreu de la Barca",
      "Sant Fruitós de Bages",
      "Vilassar",
      "Amposta",
      "Lleida",
      "Torredembarra",
      "Cerdanyola",
      "Reus",
      "Sant Cugat",
      "Sant Pere de Ribes",
      "Vilafranca",
      "Cerdanyola del Vallès",
      "Igualada",
      "Cambrils",
      "Sitges",
      "Viladecans",
      "Palafrugell",
      "Olot",
      "Ripollet",
      "Vilafranca del Penedès",
      "Lloret de Mar",
      "Manlleu",
      "Mollet",
      "Santa Coloma de Farners",
      "Sant Adrià de Besòs",
      "Sant Feliu de Guíxols",
      "Montornès del Vallès",
      "Girona",
      "Figueres",
      "Sant Feliu de Llobregat",
      "Sant Just Desvern",
      "Viladecavalls",
      "Les Franqueses del Vallès",
      "Banyoles",
      "La Pobla de Farnals",
      "Caldes de Malavella",
      "Pineda de Mar",
      "Calella",
      "La Garriga",
      "Salou",
      "Sant Joan Despí",
      "Caldes de Montbui",
      "Ripollet",
      "Blanes",
      "Santa Coloma de Gramenet",
      "Vic",
      "Mataró",
      "Vilanova i la Geltrú",
    ],
  },
  {
    name: "Madrid",
    alias: ["Comunidad de Madrid", "Madrid"],
    municipalities: [
      "La Acebeda",
      "Ajalvir",
      "Alameda del Valle",
      "El Álamo",
      "Alcalá de Henares",
      "Alcobendas",
      "Alcorcón",
      "Aldea del Fresno",
      "Algete",
      "Alpedrete",
      "Ambite",
      "Anchuelo",
      "Aranjuez",
      "Arganda del Rey",
      "Arroyomolinos",
      "El Atazar",
      "Batres",
      "Becerril de la Sierra",
      "Belmonte de Tajo",
      "El Berrueco",
      "Berzosa del Lozoya",
      "Boadilla del Monte",
      "El Boalo",
      "Braojos",
      "Brea de Tajo",
      "Brunete",
      "Buitrago del Lozoya",
      "Bustarviejo",
      "Cabanillas de la Sierra",
      "La Cabrera",
      "Cadalso de los Vidrios",
      "Camarma de Esteruelas",
      "Campo Real",
      "Canencia",
      "Carabaña",
      "Casarrubuelos",
      "Cenicientos",
      "Cercedilla",
      "Cervera de Buitrago",
      "Chapinería",
      "Chinchón",
      "Ciempozuelos",
      "Cobeña",
      "Colmenar de Oreja",
      "Colmenar del Arroyo",
      "Colmenar Viejo",
      "Collado Mediano",
      "Collado Villalba",
      "Corpa",
      "Coslada",
      "Cubas de la Sagra",
      "Daganzo de Arriba",
      "El Escorial",
      "Estremera",
      "Fresnedillas de la Oliva",
      "Fresno de Torote",
      "Fuenlabrada",
      "Fuente el Saz de Jarama",
      "Fuentidueña de Tajo",
      "Galapagar",
      "Garganta de los Montes",
      "Gargantilla del Lozoya y Pinilla de Buitrago",
      "Gascones",
      "Getafe",
      "Griñón",
      "Guadalix de la Sierra",
      "Guadarrama",
      "La Hiruela",
      "Horcajo de la Sierra",
      "Horcajuelo de la Sierra",
      "Hoyo de Manzanares",
      "Humanes de Madrid",
      "La Cabrera",
      "La Loma",
      "Lozoya",
      "Lozoyuela-Navas-Sieteiglesias",
      "Madarcos",
      "Madrid",
      "Majadahonda",
      "Manzanares el Real",
      "Meco",
      "Mejorada del Campo",
      "Miraflores de la Sierra",
      "El Molar",
      "Los Molinos",
      "Montejo de la Sierra",
      "Moraleja de Enmedio",
      "Moralzarzal",
      "Morata de Tajuña",
      "Móstoles",
      "Navacerrada",
      "Navalafuente",
      "Navalagamella",
      "Navalcarnero",
      "Navarredonda y San Mamés",
      "Navas del Rey",
      "Nuevo Baztán",
      "Olmeda de las Fuentes",
      "Orusco de Tajuña",
      "Paracuellos de Jarama",
      "Parla",
      "Patones",
      "Pedrezuela",
      "Pelayos de la Presa",
      "Perales de Tajuña",
      "Peñalver",
      "Perales de la Milla",
      "Peralejo",
      "El Pimpollar",
      "Pinto",
      "Piñuécar-Gandullas",
      "Pinilla del Valle",
      "Pozuelo de Alarcón",
      "Pozuelo del Rey",
      "Prádena del Rincón",
      "Puebla de la Sierra",
      "Puentes Viejas",
      "Quijorna",
      "Rascafría",
      "Redueña",
      "Ribatejada",
      "Rivas-Vaciamadrid",
      "Robledillo de la Jara",
      "Robledo de Chavela",
      "Robregordo",
      "Las Rozas de Madrid",
      "Rozas de Puerto Real",
      "San Agustín del Guadalix",
      "San Fernando de Henares",
      "San Lorenzo de El Escorial",
      "San Martín de la Vega",
      "San Martín de Valdeiglesias",
      "San Sebastián de los Reyes",
      "Santa María de la Alameda",
      "Santorcaz",
      "Los Santos de la Humosa",
      "La Serna del Monte",
      "Serranillos del Valle",
      "Sevilla la Nueva",
      "Somosierra",
      "Soto del Real",
      "Talamanca de Jarama",
      "Tielmes",
      "Titulcia",
      "Torrejón de Ardoz",
      "Torrejón de la Calzada",
      "Torrejón de Velasco",
      "Torrelaguna",
      "Torrelodones",
      "Torremocha de Jarama",
      "Torres de la Alameda",
      "Tres Cantos",
      "Valdaracete",
      "Valdeavero",
      "Valdelaguna",
      "Valdemanco",
      "Valdemaqueda",
      "Valdemorillo",
      "Valdemoro",
      "Valdeolmos-Alalpardo",
      "Valdepiélagos",
      "Valdetorres de Jarama",
      "Valdilecha",
      "Valverde de Alcalá",
      "Velilla de San Antonio",
      "El Vellón",
      "Venturada",
      "Villa del Prado",
      "Villaconejos",
      "Villalbilla",
      "Villamanrique de Tajo",
      "Villamanta",
      "Villamantilla",
      "Villanueva de la Cañada",
      "Villanueva de Perales",
      "Villanueva del Pardillo",
      "Villar del Olmo",
      "Villarejo de Salvanés",
      "Villaviciosa de Odón",
      "Villavieja del Lozoya",
      "Zarzalejo",
    ],
  },
  {
    name: "Comunidad Foral de Navarra",
    alias: ["Navarra"],
    municipalities: [
      "Abáigar",
      "Abárzuza",
      "Abaurregaina/Abaurrea Alta",
      "Abaurrepea/Abaurrea Baja",
      "Aberin",
      "Ablitas",
      "Adiós",
      "Aguilar de Codés",
      "Aibar/Oibar",
      "Allín",
      "Allo",
      "Altsasu/Alsasua",
      "Améscoa Baja",
      "Ancín",
      "Andosilla",
      "Ansoáin",
      "Anue",
      "Añorbe",
      "Aoiz/Agoitz",
      "Araitz",
      "Arakil",
      "Aranarache",
      "Aranguren",
      "Arano",
      "Arantza",
      "Aras",
      "Arbizu",
      "Arce/Artzi",
      "Arcos, Los",
      "Arellano",
      "Areso",
      "Arguedas",
      "Aria",
      "Aribe",
      "Armañanzas",
      "Arróniz",
      "Arruazu",
      "Artajona",
      "Artazu",
      "Atez",
      "Auritz/Burguete",
      "Ayegui",
      "Azagra",
      "Azuelo",
      "Bakaiku",
      "Barañáin",
      "Barásoain",
      "Barbarin",
      "Bargota",
      "Barillas",
      "Basaburua",
      "Baztan",
      "Beintza-Labaien",
      "Beire",
      "Belascoáin",
      "Bera/Vera de Bidasoa",
      "Berbinzana",
      "Beriáin",
      "Berrioplano",
      "Berriozar",
      "Bertizarana",
      "Betelu",
      "Bidaurreta",
      "Biurrun-Olcoz",
      "Buñuel",
      "Burgui/Burgi",
      "Burlada/Burlata",
      "Busto, El",
      "Cabanillas",
      "Cabredo",
      "Cadreita",
      "Caparroso",
      "Cárcar",
      "Carcastillo",
      "Cascante",
      "Cáseda",
      "Castejón",
      "Castillonuevo",
      "Cendea de Olza/Oltza Zendea",
      "Cintruénigo",
      "Cirauqui/Zirauki",
      "Ciriza",
      "Cizur",
      "Corella",
      "Cortes",
      "Desojo",
      "Dicastillo",
      "Donamaria",
      "Doneztebe/Santesteban",
      "Echarri",
      "Egüés",
      "Elgorriaga",
      "Enériz/Eneritz",
      "Eratsun",
      "Ergoiena",
      "Erro",
      "Eslava",
      "Esparza de Salazar",
      "Espronceda",
      "Estella/Lizarra",
      "Esteribar",
      "Etayo",
      "Etxalar",
      "Etxarri-Aranatz",
      "Etxauri",
      "Eulate",
      "Ezcabarte",
      "Ezcároz/Ezkaroze",
      "Ezkurra",
      "Ezprogui",
      "Falces",
      "Fitero",
      "Fontellas",
      "Funes",
      "Fustiñana",
      "Galar",
      "Gallipienzo",
      "Gallués/Galoze",
      "Garaioa",
      "Garde",
      "Garínoain",
      "Garralda",
      "Genevilla",
      "Goizueta",
      "Goñi",
      "Güesa/Gorza",
      "Guesálaz/Gesalatz",
      "Guirguillano",
      "Hiriberri/Villanueva de Aezkoa",
      "Huarte/Uharte",
      "Ibargoiti",
      "Igantzi",
      "Igúzquiza",
      "Imotz",
      "Irañeta",
      "Irurtzun",
      "Isaba/Izaba",
      "Ituren",
      "Iturmendi",
      "Iza",
      "Izagaondoa",
      "Izalzu/Itzaltzu",
      "Jaurrieta",
      "Javier",
      "Juslapeña",
      "Lakuntza",
      "Lana",
      "Lantz",
      "Lapoblación",
      "Larraga",
      "Larraona",
      "Larraun",
      "Lazagurría",
      "Leache",
      "Legarda",
      "Legaria",
      "Leitza",
      "Lekunberri",
      "Leoz/Leotz",
      "Lerga",
      "Lerín",
      "Lesaka",
      "Lezáun",
      "Liédena",
      "Lizoáin",
      "Lodosa",
      "Longida",
      "Lumbier",
      "Luquin",
      "Luzaide/Valcarlos",
      "Mañeru",
      "Marañón",
      "Marcilla",
      "Mélida",
      "Mendavia",
      "Mendaza",
      "Mendigorría",
      "Metauten",
      "Milagro",
      "Mirafuentes",
      "Miranda de Arga",
      "Monreal",
      "Monteagudo",
      "Morentin",
      "Mues",
      "Murchante",
      "Murieta",
      "Murillo el Cuende",
      "Murillo el Fruto",
      "Muruzábal",
      "Navascués/Nabaskoze",
      "Nazar",
      "Noáin (Valle de Elorz)/Noain (Elortzibar)",
      "Obanos",
      "Ochagavía/Otsagabia",
      "Oco",
      "Odieta",
      "Oitz",
      "Olaibar",
      "Olazti/Olazagutía",
      "Olejua",
      "Olite",
      "Ollo",
      "Olóriz",
      "Orbaitzeta",
      "Orbara",
      "Orísoain",
      "Orkoien",
      "Oronz/Orontze",
      "Oroz-Betelu",
      "Orreaga/Roncesvalles",
      "Oteiza",
      "Pamplona/Iruña",
      "Peralta",
      "Petilla de Aragón",
      "Piedramillera",
      "Pitillas",
      "Puente la Reina/Gares",
      "Pueyo",
      "Ribaforada",
      "Romanzado",
      "Roncal/Erronkari",
      "Sada",
      "Saldías",
      "Salinas de Oro",
      "San Adrián",
      "San Martín de Unx",
      "Sangüesa/Zangoza",
      "Sansol",
      "Santacara",
      "Sarriés/Sartze",
      "Sartaguda",
      "Sesma",
      "Sorlada",
      "Sunbilla",
      "Tafalla",
      "Tiebas-Muruarte de Reta",
      "Tirapu",
      "Torralba del Río",
      "Torres del Río",
      "Tudela",
      "Tulebras",
      "Ucar",
      "Uharte-Arakil",
      "Ujué",
      "Ultzama",
      "Unciti",
      "Unzué",
      "Urdazubi/Urdax",
      "Urdiain",
      "Urraúl Alto",
      "Urraúl Bajo",
      "Urrotz",
      "Urroz-Villa",
      "Urzainqui/Urzainki",
      "Uterga",
      "Uztárroz/Uztarroze",
      "Valle de Yerri/Deierri",
      "Valtierra",
      "Viana",
      "Vidángoz/Bidankoze",
      "Villafranca",
      "Villamayor de Monjardín",
      "Villatuerta",
      "Villava/Atarrabia",
      "Yesa",
      "Zabalza",
      "Ziordia",
      "Zizur Mayor/Zizur Nagusia",
      "Zubieta",
      "Zugarramurdi",
      "Zúñiga",
    ],
  },
  {
    name: "Extremadura",
    alias: ["Extremadura"],
    municipalities: [
      "Badajoz",
      "Cáceres",
      "Mérida",
      "Plasencia",
      "Don Benito",
      "Almendralejo",
      "Villanueva de la Serena",
      "Montijo",
      "Zafra",
      "Navalmoral de la Mata",
      "Olivenza",
      "Jerez de los Caballeros",
      "Miajadas",
      "Los Santos de Maimona",
      "Villanueva del Fresno",
      "Coria",
      "Fregenal de la Sierra",
      "Trujillo",
      "Casar de Cáceres",
      "Castuera",
      "Talarrubias",
      "Guareña",
      "Mérida",
      "Don Benito",
      "Almendralejo",
      "Montijo",
      "Zafra",
      "Navalmoral de la Mata",
      "Villanueva de la Serena",
      "Olivenza",
      "Jerez de los Caballeros",
      "Miajadas",
      "Los Santos de Maimona",
      "Fregenal de la Sierra",
      "Villanueva del Fresno",
      "Castuera",
      "Coria",
      "Llerena",
      "Arroyo de la Luz",
      "Valverde de Leganés",
      "Monesterio",
      "Campanario",
      "Ahillones",
      "Arroyo de San Serván",
      "Alburquerque",
      "Alcántara",
      "Valencia de Alcántara",
      "Montánchez",
      "Jaraíz de la Vera",
      "Badajoz",
      "Mérida",
      "Almendralejo",
      "Don Benito",
      "Montijo",
      "Zafra",
      "Villanueva de la Serena",
      "Olivenza",
      "Jerez de los Caballeros",
      "Navalmoral de la Mata",
      "Miajadas",
      "Los Santos de Maimona",
      "Fregenal de la Sierra",
      "Castuera",
      "Villanueva del Fresno",
      "Coria",
      "Llerena",
      "Trujillo",
      "Arroyo de la Luz",
      "Valverde de Leganés",
      "Monesterio",
      "Campanario",
      "Ahillones",
      "Arroyo de San Serván",
      "Alburquerque",
      "Alcántara",
      "Valencia de Alcántara",
      "Montánchez",
      "Jaraíz de la Vera",
      "Brozas",
      "Feria",
      "Puebla de Alcocer",
      "Talarrubias",
      "Villar del Rey",
      "Guareña",
      "Azuaga",
      "Puebla de la Calzada",
      "Calamonte",
      "Herrera del Duque",
      "Gévora",
      "La Roca de la Sierra",
      "Valverde de Mérida",
      "Puebla de Obando",
      "Almendral",
      "La Codosera",
      "Ribera del Fresno",
      "Villagonzalo",
      "Cabeza del Buey",
      "Corte de Peleas",
      "Valencia del Ventoso",
      "San Vicente de Alcántara",
      "Burguillos del Cerro",
      "Aceuchal",
      "Zahínos",
      "Berlanga",
      "Fuente del Arco",
      "Solana de los Barros",
      "Fuente del Maestre",
      "Cabeza la Vaca",
      "Valencia de las Torres",
      "Puebla de la Reina",
      "Valdecaballeros",
      "Medellín",
      "Los Santos de Maimona",
      "Azuaga",
      "Siruela",
      "Casas de Don Pedro",
      "Talarrubias",
      "Corte de Peleas",
      "Guareña",
      "Castañar de Ibor",
      "Alange",
      "Monesterio",
      "Barcarrota",
      "Valdetorres",
      "Villar del Rey",
      "Aceuchal",
      "Villanueva del Fresno",
      "San Vicente de Alcántara",
      "La Garrovilla",
      "Salorino",
      "Valencia de Alcántara",
      "Táliga",
      "Montijo",
      "Puebla de Alcocer",
      "Talavera la Real",
      "Fregenal de la Sierra",
      "Jerez de los Caballeros",
      "Rena",
      "Valencia del Mombuey",
      "Olivenza",
      "Mérida",
      "Higuera de Vargas",
      "Puebla de Sancho Pérez",
      "Azuaga",
      "Almendralejo",
      "Los Santos de Maimona",
      "Villanueva de la Serena",
      "Feria",
      "Acedera",
      "Valle de Santa Ana",
      "Villalba de los Barros",
      "Ribera del Fresno",
      "Fregenal de la Sierra",
      "Don Benito",
      "Medina de las Torres",
      "Valencia de Alcántara",
      "Barcarrota",
      "Almendral",
      "Calzadilla de los Barros",
      "San Vicente de Alcántara",
      "Olivenza",
      "Villafranca de los Barros",
      "La Garrovilla",
      "Puebla de la Calzada",
      "Montijo",
      "Azuaga",
      "Mérida",
      "Llerena",
      "Zafra",
      "Talarrubias",
      "Guareña",
      "Monesterio",
      "Cabeza del Buey",
      "Villanueva de la Serena",
      "Jerez de los Caballeros",
      "Los Santos de Maimona",
      "La Roca de la Sierra",
      "Valverde de Mérida",
      "Ribera del Fresno",
      "Puebla de Sancho Pérez",
      "Villanueva del Fresno",
      "Santa Amalia",
      "Alange",
      "Badajoz",
      "Almendralejo",
      "Mérida",
      "Don Benito",
      "Montijo",
      "Zafra",
      "Villanueva de la Serena",
      "Olivenza",
      "Jerez de los Caballeros",
      "Navalmoral de la Mata",
      "Miajadas",
      "Los Santos de Maimona",
      "Fregenal de la Sierra",
      "Castuera",
      "Villanueva del Fresno",
      "Coria",
      "Llerena",
      "Trujillo",
      "Arroyo de la Luz",
      "Valverde de Leganés",
      "Monesterio",
      "Campanario",
      "Ahillones",
      "Arroyo de San Serván",
      "Alburquerque",
      "Alcántara",
      "Valencia de Alcántara",
      "Montánchez",
      "Jaraíz de la Vera",
      "Brozas",
      "Feria",
      "Puebla de Alcocer",
      "Talarrubias",
      "Villar del Rey",
      "Guareña",
      "Azuaga",
      "Puebla de la Calzada",
      "Calamonte",
      "Herrera del Duque",
      "Gévora",
      "La Roca de la Sierra",
      "Valverde de Mérida",
      "Puebla de Obando",
      "Almendral",
      "La Codosera",
      "Ribera del Fresno",
      "Villagonzalo",
      "Cabeza del Buey",
      "Corte de Peleas",
      "Valencia del Ventoso",
      "San Vicente de Alcántara",
      "Burguillos del Cerro",
      "Aceuchal",
      "Zahínos",
      "Berlanga",
      "Fuente del Arco",
      "Solana de los Barros",
      "Fuente del Maestre",
      "Cabeza la Vaca",
      "Valencia de las Torres",
      "Puebla de la Reina",
      "Valdecaballeros",
      "Medellín",
      "Los Santos de Maimona",
      "Azuaga",
      "Siruela",
      "Casas de Don Pedro",
      "Talarrubias",
      "Corte de Peleas",
      "Guareña",
      "Castañar de Ibor",
      "Alange",
      "Monesterio",
      "Barcarrota",
      "Valdetorres",
      "Villar del Rey",
      "Aceuchal",
      "Villanueva del Fresno",
      "San Vicente de Alcántara",
      "La Garrovilla",
      "Salorino",
      "Valencia de Alcántara",
      "Táliga",
      "Montijo",
      "Puebla de Alcocer",
      "Talavera la Real",
      "Fregenal de la Sierra",
      "Jerez de los Caballeros",
      "Rena",
      "Valencia del Mombuey",
      "Olivenza",
      "Mérida",
      "Higuera de Vargas",
      "Puebla de Sancho Pérez",
      "Azuaga",
      "Almendralejo",
      "Los Santos de Maimona",
      "Villanueva de la Serena",
      "Feria",
      "Acedera",
      "Valle de Santa Ana",
      "Villalba de los Barros",
      "Ribera del Fresno",
      "Fregenal de la Sierra",
      "Don Benito",
      "Medina de las Torres",
      "Valencia de Alcántara",
      "Barcarrota",
      "Almendral",
      "Calzadilla de los Barros",
      "San Vicente de Alcántara",
      "Olivenza",
      "Villafranca de los Barros",
      "La Garrovilla",
      "Puebla de la Calzada",
      "Montijo",
      "Azuaga",
      "Mérida",
      "Llerena",
      "Zafra",
      "Talarrubias",
      "Guareña",
      "Monesterio",
      "Cabeza del Buey",
      "Villanueva de la Serena",
      "Jerez de los Caballeros",
      "Los Santos de Maimona",
      "La Roca de la Sierra",
      "Valverde de Mérida",
      "Ribera del Fresno",
      "Puebla de Sancho Pérez",
      "Villanueva del Fresno",
      "Santa Amalia",
      "Alange",
    ],
  },
  {
    name: "Galicia",
    alias: ["Galicia"],
    municipalities: [
      "A Coruña",
      "Lugo",
      "Ourense",
      "Pontevedra",
      "Vigo",
      "Santiago de Compostela",
      "Ferrol",
      "Lugo",
      "Ourense",
      "Pontevedra",
      "Vigo",
      "Santiago de Compostela",
      "Ferrol",
      "Narón",
      "Oleiros",
      "Ribeira",
      "Carballo",
      "Redondela",
      "Ames",
      "Culleredo",
      "Boiro",
      "Teo",
      "Marín",
      "Cambados",
      "O Porriño",
      "Vilagarcía de Arousa",
      "Nigrán",
      "A Estrada",
      "O Grove",
      "As Pontes de García Rodríguez",
      "Cambre",
      "Arteijo",
      "Cangas",
      "Vilanova de Arousa",
      "Ponteareas",
      "Sada",
      "Fene",
      "Mos",
      "Burela",
      "Viveiro",
      "Lalín",
      "Pobra do Caramiñal",
      "Laxe",
      "Vimianzo",
      "O Carballiño",
      "Silleda",
      "Vila de Cruces",
      "Cee",
      "Rianxo",
      "Ponte Caldelas",
      "Monforte de Lemos",
      "Ribadavia",
      "A Pobra do Brollón",
      "Noia",
      "A Laracha",
      "A Baña",
      "Arousa",
      "Rianxo",
      "Padrón",
      "Sanxenxo",
      "Bueu",
      "Rodeiro",
      "A Illa de Arousa",
      "Ordes",
      "Catoira",
      "Monforte de Lemos",
      "Tui",
      "Sarria",
      "Allariz",
      "Meaño",
      "Cerceda",
      "Portas",
      "Outes",
      "Vilaboa",
      "Outes",
      "Rianxo",
      "Fisterra",
      "Vilarmaior",
      "Carnota",
      "Cedeira",
      "Sada",
      "Cariño",
      "Mondoñedo",
      "Arzúa",
      "Vilalba",
      "Cervo",
      "Ribadeo",
      "Viveiro",
      "Barbadás",
      "Ribadumia",
      "Agolada",
      "A Estrada",
      "Cerdedo-Cotobade",
      "Bergondo",
      "Muros",
      "Moaña",
      "Santa Comba",
      "Vila de Cruces",
      "Ribeira",
      "Vilagarcía de Arousa",
      "Cambre",
      "A Pobra do Caramiñal",
      "Rianxo",
      "O Grove",
      "Ponteareas",
      "Laxe",
      "Fene",
      "Mos",
      "Vimianzo",
      "O Carballiño",
      "Silleda",
      "Cangas",
      "O Porriño",
      "Marín",
      "Nigrán",
      "A Illa de Arousa",
      "Oleiros",
      "Rodeiro",
      "Boiro",
      "Teo",
      "A Laracha",
      "Culleredo",
      "Vila de Cruces",
      "Ponte Caldelas",
      "Catoira",
      "Outes",
      "Monforte de Lemos",
      "Ordes",
      "Cariño",
      "Carnota",
      "Rianxo",
      "Cerceda",
      "Santa Comba",
      "Ribadavia",
      "Tui",
      "Sarria",
      "Allariz",
      "Mondoñedo",
      "Vilalba",
      "Cervo",
      "Ribadeo",
      "Bueu",
      "Cedeira",
      "Agolada",
      "Vilaboa",
      "Barbadás",
      "Bergondo",
      "Meaño",
      "Moaña",
      "Muros",
      "Padrón",
      "Sanxenxo",
      "A Baña",
      "Vilarmaior",
      "Fisterra",
      "Arzúa",
      "Monforte de Lemos",
      "Portas",
      "Viveiro",
      "Sada",
      "Rianxo",
      "Cambre",
      "Ponteareas",
      "Fene",
      "Laxe",
      "Vimianzo",
      "Mos",
      "O Carballiño",
      "A Pobra do Caramiñal",
      "Cangas",
      "Nigrán",
      "O Grove",
      "Marín",
      "Culleredo",
      "Rodeiro",
      "A Laracha",
      "Vilagarcía de Arousa",
      "Teo",
      "Vila de Cruces",
      "O Porriño",
      "Silleda",
      "Outes",
      "Catoira",
      "Ordes",
      "Monforte de Lemos",
      "Rianxo",
      "Boiro",
      "Cariño",
      "Santa Comba",
      "Carnota",
      "Ribadavia",
      "Sarria",
      "Allariz",
      "Mondoñedo",
      "Cedeira",
      "Vilalba",
      "Ribadeo",
      "Cervo",
      "Bueu",
      "Vilaboa",
      "Agolada",
      "Barbadás",
      "Bergondo",
      "Moaña",
      "Muros",
      "Meaño",
      "Padrón",
      "Sanxenxo",
      "A Baña",
      "Vilarmaior",
      "Fisterra",
      "Arzúa",
      "Portas",
      "Viveiro",
      "Sada",
      "Cambre",
      "Fene",
      "Laxe",
      "Vimianzo",
      "Mos",
      "O Carballiño",
      "A Pobra do Caramiñal",
      "Cangas",
      "Nigrán",
      "O Grove",
      "Marín",
      "Culleredo",
      "Rodeiro",
      "A Laracha",
      "Teo",
      "Vilagarcía de Arousa",
      "Vila de Cruces",
      "O Porriño",
      "Silleda",
      "Outes",
      "Catoira",
      "Ordes",
      "Monforte de Lemos",
      "Rianxo",
      "Boiro",
      "Cariño",
      "Santa Comba",
      "Carnota",
      "Ribadavia",
      "Sarria",
      "Allariz",
      "Cedeira",
      "Vilalba",
      "Ribadeo",
      "Cervo",
      "Bueu",
      "Vilaboa",
      "Agolada",
      "Barbadás",
      "Bergondo",
      "Moaña",
      "Muros",
      "Padrón",
      "Sanxenxo",
      "A Baña",
      "Vilarmaior",
      "Fisterra",
      "Arzúa",
    ],
  },
  {
    name: "Murcia",
    alias: ["Murcia", "Región de Murcia"],
    municipalities: [
      "Murcia",
      "Cartagena",
      "Lorca",
      "Molina de Segura",
      "Alcantarilla",
      "Cieza",
      "Yecla",
      "Águilas",
      "Totana",
      "Torre-Pacheco",
      "Jumilla",
      "Caravaca de la Cruz",
      "San Javier",
      "San Pedro del Pinatar",
      "Mazarrón",
      "La Unión",
      "Archena",
      "Abarán",
      "Fuente Álamo de Murcia",
      "Las Torres de Cotillas",
      "Alhama de Murcia",
      "Los Alcázares",
      "Cehegín",
      "Ceutí",
      "Calasparra",
      "Blanca",
      "Alguazas",
      "Aledo",
      "Beniel",
      "Puerto Lumbreras",
      "Lorquí",
      "Torres de Cotillas",
      "Ricote",
      "Ulea",
      "Ojós",
      "Villanueva del Río Segura",
      "Abanilla",
      "Albudeite",
      "Alcantarilla",
      "Aledo",
      "Los Alcázares",
      "Alguazas",
      "Alhama de Murcia",
      "Archena",
      "Beniel",
      "Blanca",
      "Bullas",
      "Calasparra",
      "Campos del Río",
      "Caravaca de la Cruz",
      "Cartagena",
      "Cehegín",
      "Ceutí",
      "Cieza",
      "Fortuna",
      "Fuente Álamo de Murcia",
      "Jumilla",
      "Librilla",
      "Lorca",
      "Lorquí",
      "Mazarrón",
      "Molina de Segura",
      "Moratalla",
      "Mula",
      "Murcia",
      "Ojós",
      "Pliego",
      "Puerto Lumbreras",
      "Ricote",
      "San Javier",
      "San Pedro del Pinatar",
      "Santomera",
      "Torre-Pacheco",
      "Las Torres de Cotillas",
      "Totana",
      "Ulea",
      "La Unión",
      "Villanueva del Río Segura",
      "Yecla",
    ],
  },
  {
    name: "La Rioja",
    alias: ["La Rioja"],
    municipalities: [
      "Logroño",
      "Calahorra",
      "Arnedo",
      "Haro",
      "Alfaro",
      "Nájera",
      "Lardero",
      "Autol",
      "Santo Domingo de la Calzada",
      "Cervera del Río Alhama",
      "Rincón de Soto",
      "Aldeanueva de Ebro",
      "Pradejón",
      "Fuenmayor",
      "Quel",
      "Villamediana de Iregua",
      "Cenicero",
      "Agoncillo",
      "Nalda",
      "Hervías",
      "Hormilla",
      "Casalarreina",
      "Cuzcurrita de Río Tirón",
      "Rodezno",
      "Baños de Río Tobía",
      "San Asensio",
      "Alberite",
      "Oyón",
      "Entrena",
      "Corera",
      "Murillo de Río Leza",
      "Pradillo",
      "Lagunilla del Jubera",
      "Galilea",
      "Soto en Cameros",
      "Santurde de Rioja",
      "Tirgo",
      "Santurdejo",
      "Tormantos",
      "Sojuela",
      "Villar de Torre",
      "Castroviejo",
      "Cirueña",
      "Treviana",
      "Anguciana",
      "Tobía",
      "Ajamil",
      "Viguera",
      "Bañares",
      "Anguiano",
      "Zarratón",
      "Valgañón",
      "Tormantos",
      "Villaverde de Rioja",
      "Badarán",
      "Ojacastro",
      "Leiva",
      "Castañares de Rioja",
      "Alesanco",
      "Cordovín",
      "Soto en Cameros",
      "Huércanos",
      "Torrecilla en Cameros",
      "Matute",
      "Santa Coloma",
      "Corporales",
      "Sorzano",
      "Brieva de Cameros",
      "Valdemadera",
      "Clavijo",
      "Baños de Rioja",
      "Berceo",
      "Cellorigo",
      "Cihuri",
      "Tondeluna",
    ],
  },
  {
    name: "Comunidad Valenciana",
    alias: ["Valencia", "Valenciana"],
    municipalities: [
      "Castellón de la Plana",
      "Vila-real",
      "Benicarló",
      "Oropesa del Mar",
      "Almassora",
      "Burriana",
      "Peñíscola",
      "Vinaròs",
      "Alcalà de Xivert",
      "La Vall d'Uixó",
      "Benicàssim",
      "Nules",
      "Segorbe",
      "Betxí",
      "Borriol",
      "Almenara",
      "Morella",
      "Villarreal/Vila-real",
      "Moncofa",
      "Sant Mateu",
      "Traiguera",
      "Alcora",
      "l'Alcora",
      "Cabanes",
      "Vall d'Alba",
      "Valencia",
      "Gandía",
      "Torrent",
      "Paterna",
      "Sagunto",
      "Mislata",
      "Alzira",
      "Burjassot",
      "Ontinyent",
      "Xirivella",
      "Manises",
      "Xàtiva",
      "Aldaia",
      "Silla",
      "Cullera",
      "Buñol",
      "Llíria",
      "Sueca",
      "Massamagrell",
      "Carcaixent",
      "Oliva",
      "Catarroja",
      "Algemesí",
      "Requena",
      "Alboraya",
      "Chiva",
      "Alcàsser",
      "Massanassa",
      "Riba-roja de Túria",
      "Moncada",
      "Benaguasil",
      "Xeraco",
      "Gandia",
      "L'Eliana",
      "Picassent",
      "Meliana",
      "Paiporta",
      "Alaquàs",
      "Almussafes",
      "Bétera",
      "Albal",
      "Quart de Poblet",
      "Canals",
      "Turís",
      "Monserrat",
      "Puebla de Farnals",
      "Alcudia de Crespins",
      "Godella",
      "Ollería",
      "Foios",
      "Almàssera",
      "Llombai",
      "Beniparrell",
      "Piles",
      "Alzira",
      "Sueca",
      "Alaquàs",
      "Picassent",
      "Llíria",
      "Catarroja",
      "Massamagrell",
      "Alicante/Alacant",
      "Elche/Elx",
      "Torrevieja",
      "Orihuela",
      "Benidorm",
      "Alcoy/Alcoi",
      "Elda",
      "Dénia",
      "Altea",
      "Santa Pola",
      "Calp",
      "Petrer",
      "Villena",
      "San Vicente del Raspeig/Sant Vicent del Raspeig",
      "Jávea/Xàbia",
      "Novelda",
      "Crevillent",
      "Campello",
      "Villajoyosa/La Vila Joiosa",
      "Benissa",
      "San Juan de Alicante/Sant Joan d'Alacant",
      "Ibi",
      "Mutxamel",
      "El Campello",
      "Alfaz del Pi",
      "Aspe",
      "Almoradí",
      "Guardamar del Segura",
      "Cocentaina",
      "Rojales",
      "Pilar de la Horadada",
      "Monóvar/Monòver",
      "Sax",
      "La Nucia",
      "Onil",
      "Albatera",
      "Pego",
      "Castalla",
      "La Romana",
      "Agost",
      "Callosa de Segura",
      "Biar",
      "Daya Nueva",
      "Algorfa",
      "Dolores",
      "Gata de Gorgos",
      "Sant Joan d'Alacant",
      "Tibi",
      "El Verger",
      "Monforte del Cid",
      "Orba",
      "Benijófar",
      "Finestrat",
      "Pedreguer",
      "San Fulgencio",
      "Bigastro",
      "Daya Vieja",
      "Vall de Gallinera",
      "San Miguel de Salinas",
      "Beniarbeig",
      "Teulada",
      "Benitachell/Poble Nou de Benitatxell",
      "Benimantell",
      "L'Alfàs del Pi",
      "Facheca",
      "La Vall de Laguar",
      "Sagra",
      "Altea la Vella",
      "Beniarres",
      "Benidoleig",
      "Parcent",
      "Beniarjó",
      "Tormos",
      "Murla",
      "Senija",
      "L'Orxa",
      "Jalón/Xaló",
      "Sanet y Negrals",
      "Orxeta",
    ],
  },
  {
    name: "País Vasco",
    alias: ["País Vasco", "Euskadi"],
    municipalities: [
      "Bilbao",
      "Vitoria-Gasteiz",
      "Donostia / San Sebastián",
      "Barakaldo",
      "Getxo",
      "Irun",
      "Portugalete",
      "Santurtzi",
      "Basauri",
      "Leioa",
      "Sestao",
      "Algorta",
      "Erandio",
      "Durango",
      "Arrasate / Mondragón",
      "Eibar",
      "Amorebieta-Etxano",
      "Galdakao",
      "Hernani",
      "Zarautz",
      "Hondarribia",
      "Lasarte-Oria",
      "Pasaia",
      "Ibarra",
      "Elgoibar",
      "Tolosa",
      "Zumarraga",
      "Urnieta",
      "Andoain",
      "Oiartzun",
      "Ermua",
      "Bermeo",
      "Ondarroa",
      "Azpeitia",
      "Mungia",
      "Beasain",
      "Lekeitio",
      "Eibar",
      "Bergara",
      "Zalla",
      "Balmaseda",
      "Mendaro",
      "Muskiz",
      "Orio",
      "Zumaia",
      "Deba",
      "Mallabia",
      "Mondragón",
      "Gernika-Lumo",
      "Arrigorriaga",
      "Guetxo",
      "Beasain",
      "Erandio",
      "Zumarraga",
      "Amurrio",
      "Etxebarri",
      "Elgoibar",
      "Gernika-Lumo",
      "Santurtzi",
      "Sestao",
      "Galdakao",
      "Durango",
      "Zarautz",
      "Pasaia",
      "Ondarroa",
      "Leioa",
      "Zalla",
      "Irun",
      "Eibar",
      "Mungia",
      "Arrasate / Mondragón",
      "Barakaldo",
      "Basauri",
      "Hernani",
      "Lekeitio",
      "Lasarte-Oria",
      "Markina-Xemein",
      "Orio",
      "Bermeo",
      "Tolosa",
      "Getxo",
      "Muskiz",
      "Bilbao",
      "Portugalete",
      "Arrigorriaga",
      "Hondarribia",
      "Oiartzun",
      "Sopela",
      "Lezama",
      "Oiartzun",
      "Amorebieta-Etxano",
      "Azpeitia",
      "Mallabia",
      "Ermua",
      "Eibar",
      "Balmaseda",
      "Eibar",
      "Zumaia",
      "Elorrio",
      "Mendaro",
      "Muskiz",
      "Arrigorriaga",
      "Lekeitio",
      "Eibar",
      "Galdakao",
      "Zarautz",
      "Zalla",
      "Bermeo",
      "Santurtzi",
      "Bergara",
      "Oiartzun",
      "Sopelana",
      "Bilbao",
      "Barakaldo",
      "Durango",
      "Arrasate / Mondragón",
      "Portugalete",
      "Guetxo",
      "Etxebarri",
      "Donostia / San Sebastián",
      "Lezama",
      "Leioa",
      "Gernika-Lumo",
      "Sestao",
      "Getxo",
      "Hernani",
      "Irun",
      "Markina-Xemein",
      "Basauri",
      "Mungia",
      "Mondragón",
      "Amurrio",
      "Mallabia",
      "Ondarroa",
      "Arrigorriaga",
      "Orio",
      "Zumaia",
      "Muskiz",
      "Oiartzun",
      "Ermua",
      "Lekeitio",
      "Eibar",
      "Elgoibar",
      "Azpeitia",
      "Santurtzi",
      "Leioa",
      "Tolosa",
      "Zalla",
      "Balmaseda",
      "Bermeo",
      "Hondarribia",
      "Zarautz",
      "Eibar",
      "Zalla",
      "Sopelana",
      "Bermeo",
      "Arrasate / Mondragón",
      "Portugalete",
      "Basauri",
      "Oiartzun",
      "Getxo",
      "Lekeitio",
      "Gernika-Lumo",
      "Sestao",
      "Bilbao",
      "Irun",
      "Hernani",
      "Muskiz",
      "Arrigorriaga",
      "Mungia",
      "Sopela",
      "Durango",
      "Etxebarri",
      "Orio",
      "Elgoibar",
      "Mallabia",
      "Lezama",
      "Ermua",
      "Azpeitia",
      "Markina-Xemein",
      "Donostia / San Sebastián",
      "Tolosa",
      "Amurrio",
      "Mendaro",
      "Zumaia",
      "Oiartzun",
      "Balmaseda",
      "Eibar",
      "Eibar",
      "Arrasate / Mondragón",
      "Zarautz",
      "Santurtzi",
      "Barakaldo",
      "Bermeo",
      "Lekeitio",
      "Eibar",
      "Arrasate / Mondragón",
      "Portugalete",
      "Basauri",
      "Lekeitio",
      "Oiartzun",
      "Getxo",
      "Gernika-Lumo",
      "Sestao",
      "Bilbao",
      "Hernani",
      "Mungia",
      "Irun",
      "Arrigorriaga",
      "Muskiz",
      "Sopela",
      "Durango",
      "Etxebarri",
      "Orio",
      "Elgoibar",
      "Mallabia",
      "Lezama",
      "Ermua",
      "Azpeitia",
      "Markina-Xemein",
      "Donostia / San Sebastián",
      "Tolosa",
      "Amurrio",
      "Mendaro",
      "Zumaia",
      "Balmaseda",
      "Eibar",
      "Arrasate / Mondragón",
      "Zarautz",
      "Santurtzi",
      "Barakaldo",
      "Bermeo",
      "Lekeitio",
      "Eibar",
      "Ondarroa",
      "Getxo",
      "Gernika-Lumo",
      "Sestao",
      "Bilbao",
      "Hernani",
      "Mungia",
      "Irun",
      "Arrigorriaga",
      "Muskiz",
      "Sopela",
      "Durango",
      "Etxebarri",
      "Orio",
      "Elgoibar",
      "Mallabia",
      "Lezama",
      "Ermua",
      "Azpeitia",
      "Markina-Xemein",
      "Donostia / San Sebastián",
      "Tolosa",
      "Amurrio",
      "Mendaro",
      "Zumaia",
      "Balmaseda",
      "Eibar",
    ],
  },
];
