export interface LivenessProps {
  x: number;
  y: number;
  showIndicator: boolean;
  state?: faceSquareState;
  remainingSeconds: number;
}

export enum faceSquareState {
  idle,
  correct,
}
