/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import Logo from "../../../../assets/icons/id_logo.png";
import Button from "../../../../components/elements/buttons/button";
import { useNavigate } from "react-router-dom";
import Sheet from "react-modal-sheet";
import { RoutesList } from "../../../../utils/routes/routes_utils";
import FinishVerificationIdentity from "../finish/finish_verification_identity";
import { toast } from "react-hot-toast";
import useAppContext from "../../../../utils/context/useAppContext/use_app_context";

function IntroVerificationIdentity() {
  const navigate = useNavigate();
  const appContext = useAppContext();

  return (
    <div className="intro_verification_identity">
      <img src={Logo} />

      <h2>Verificación de identidad</h2>

      <p className="verification-paragraph">
        {appContext.state.has_completed_challenge
          ? "Felicidades por haber completado la verificación de identidad! ya puedes cerrar esta pestaña"
          : " Bienvenido a la verificación de identidad de Adamo ID. Presiona en el botón para tomar la fotografía de tu documento:"}
      </p>

      {!appContext.state.has_completed_challenge && (
        <Button
          label="Comenzar"
          onTap={() => navigate(RoutesList.IdentityCountry)}
        />
      )}
    </div>
  );
}

export default IntroVerificationIdentity;
