/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Logo from "../../assets/icons/id_logo.png";
import Email from "../../assets/icons/email.svg";
import Button from "../../components/elements/buttons/button";
import TextInputField from "../../components/elements/inputs/textField";
import { useState } from "react";
import { LivenessService } from "../../service/liveness/liveness_service";
import useAppContext from "../../utils/context/useAppContext/use_app_context";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import VinculatedSuccessModal from "./vinculated_success_modal";
import { toast } from "react-hot-toast";

function VinculateEmailScreen() {
  const [emailController, setEmailController] = useState("");
  const [emailStatus, setEmailStatus] = useState(0);

  const appContext = useAppContext();

  const onSubmit = async (hasVinculatedEmail: boolean) => {
    console.log("onSubmit");

    const emailIsValid = validRegex.test(emailController);
    setEmailStatus(emailIsValid ? 0 : 1);

    if (!emailIsValid && hasVinculatedEmail) return;

    appContext.toggleLoading(true);

    try {
      await new LivenessService().finishProcess(
        hasVinculatedEmail ? emailController : null
      );

      withReactContent(Swal).fire({
        showCloseButton: false,
        width: "88vw",
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        showCancelButton: false,
        padding: "0 0 0 0",
        html: (
          <VinculatedSuccessModal hasVinculatedEmail={hasVinculatedEmail} />
        ),
      });
    } catch (error) {
      console.log("here");
      setEmailStatus(2);
    } finally {
      appContext.toggleLoading(false);
    }
  };

  useEffect(() => {
    appContext.changeContext({ blockFacialVerf: true });
  }, []);

  return (
    <div className="vinculate_email_screen">
      <img src={Logo} alt="logo" />

      <p
        className="verification-paragraph"
        style={{ color: "#2bbfed", margin: "3vh 0", fontWeight: "bold" }}
      >
        ¡Ya casi finalizamos!
      </p>

      <p className="verification-paragraph">
        Solo faltaria asociar tu correo electrónico. Esto hará que tu registro
        en Adamo sea más ágil y seguro en el futuro
      </p>

      <div className="" style={{ margin: "3vh 0 6vh" }}>
        <TextInputField
          placeholder="Correo electronico"
          onChange={setEmailController}
          preffixComponent={<img src={Email} alt="email" />}
        />

        {emailStatus !== 0 && (
          <div
            style={{
              backgroundColor: "#FEE2E2 ",
              color: "#EF4444",
              borderRadius: "10px",
              fontSize: "3vw",
              padding: "2vh 2vw",
            }}
          >
            {emailStatus === 2
              ? "Lo sentimos, este correo electrónico ya está registrado en nuestra plataforma. Por favor, utiliza otro correo o inicia sesión si ya tienes una cuenta."
              : "Parece que has ingresado una dirección de correo electrónico no válida. Por favor, verifica y asegúrate de que sea una dirección de correo electrónico válida."}
          </div>
        )}
      </div>

      <Button
        label="Vincular correo"
        disabled={emailController.length === 0}
        width="100%"
        onTap={() => onSubmit(true)}
      />

      <div
        className=""
        style={{
          margin: "4vh 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          className="line"
          style={{ background: "#e1e1e1", height: "1px", width: "40vw" }}
        />
        <b className="verification-paragraph">ó</b>

        <div
          className="line"
          style={{ background: "#e1e1e1", height: "1px", width: "40vw" }}
        />
      </div>

      <p
        className="verification-paragraph"
        style={{
          color: "#2bbfed",
          margin: "1vh 0",
          fontWeight: "bold",
          textDecoration: "underline",
        }}
        onClick={() => onSubmit(false)}
      >
        Finalizar sin vincular correo
      </p>
    </div>
  );
}

export default VinculateEmailScreen;

const validRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
