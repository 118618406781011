/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import SelectInputField from "../../../elements/inputs/select";
import { countries } from "../../../../utils/countries/countries";
import ChevronDown from "../../../../assets/icons/chevron_down.svg";
import { StylesConfig, components } from "react-select";
import useAppContext from "../../../../utils/context/useAppContext/use_app_context";
import { IdentityService } from "../../../../service/identity/identity_service";
import { CountryWithOCR } from "../../../../models/verifications/verifications_models";
import { useNavigate } from "react-router-dom";

function VerfIdentityCountrySelector({
  setCountrySelected,
  countrySelected,
}: any) {
  //const countriesList: any = countries;
  const context = useAppContext();

  const [countriesList, setCountriesList] = useState<[]>([]);

  const colourStyles: StylesConfig = {
    control: (styles) => ({
      ...styles,
      border: "none",
      backgroundColor: "#F5F5F5",
      borderRadius: "15px",
      padding: "2vw 2vw",
    }),
    option: (styles) => {
      return {
        ...styles,
        backgroundColor: "#F5F5F5",
        color: "black",
      };
    },
  };

  const CustomOption = (props: any) => {
    return (
      <div className="custom_option_country" {...props.innerProps}>
        <img src={props.data.flag_image} alt="flag" />
        <p>{props.data.name}</p>
      </div>
    );
  };

  const CustomControl = ({ children, ...rest }: any) => (
    <components.Control {...rest}>
      {" "}
      <div className="custom_option_country custom_option_country_control">
        <img src={countrySelected.flag_image} alt="flag" />
      </div>{" "}
      {children}
    </components.Control>
  );

  const getCountries = async () => {
    context.toggleLoading(true);

    try {
      const response = await new IdentityService().getCountriesWithOCR();
      setCountriesList(response);

      context.toggleLoading(false);

      setCountrySelected(response[0]);
    } catch (error) {
      context.toggleLoading(false);
    }
  };

  useEffect(() => {
    getCountries();

    return () => {};
  }, []);

  return (
    <div className="verf_identity_select_country">
      <h3>Selecciona el país:</h3>
      <SelectInputField
        label="Ciudad"
        value={countrySelected}
        getOptionLabel={(option) => option.name}
        options={countriesList}
        onChange={(e) => setCountrySelected(e)}
        styles={colourStyles}
        components={{ Option: CustomOption, Control: CustomControl }}
      />
    </div>
  );
}

export default VerfIdentityCountrySelector;
