import { GroupBase, StylesConfig } from "react-select";
import { SelectComponents } from "react-select/dist/declarations/src/components";

/* eslint-disable no-useless-escape */
export interface InputFieldProps {
  label?: string;
  placeholder?: string;
  width?: string;
  filled?: true;
  onChange?: (e: any) => void;
  preffixComponent?: JSX.Element;
  style?: React.CSSProperties;
  styleContainer?: React.CSSProperties;
  useForm?: any;
  disabled?: boolean;
}

export interface SelectProps extends InputFieldProps {
  options: [];
  getOptionLabel?: (option: any) => string;
  onChange?: (value: any) => void;
  value?: any;
  styles?: StylesConfig;
  components?: Partial<SelectComponents<any, boolean, GroupBase<unknown>>>;
}

export class helpersTexts {
  emailInvalid = "El correo electrónico es inválido";
  number = "El valor debe ser un número";
  required = "Este campo es obligatorio";
  emailUsed = "El correo electrónico ya está en uso";
  password =
    "La contraseña debe tener más de 8 caracteres, una mayúscula, un número y un carácter especial";
}

export const ValidRegexp = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  number: /^[0-9]*$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
};
