import React, { useState } from "react";
import Button from "../../elements/buttons/button";
import { useNavigate } from "react-router-dom";
import { RoutesList } from "../../../utils/routes/routes_utils";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import VerificationCancelledScreen from "../../../screens/verifications/misc/verification_cancelled_screen";

function VerificationCancelledModal({ fullScreen }: any) {
  const [showFullPage, setShowFullPage] = useState(false);

  if (showFullPage || fullScreen) {
    return <VerificationCancelledScreen />;
  }

  return (
    <div className="LayoutVerification modal-cancelled-verf">
      <h2>Oh, ha ocurrido un error!</h2>
      <p>
        Se ha cerrado inesperadamente el QR de verificación en la pagina web.
        Porfavor escanea el nuevo código para proceder con la verificación.
      </p>
      <Button
        label="Continuar"
        width="80vw"
        onTap={() => {
          setShowFullPage(true);
        }}
      />
    </div>
  );
}

export default VerificationCancelledModal;
