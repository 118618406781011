/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { isRegisterMode } from "../../../utils/local_storage/local_storage";
import RegisterCancelledScreen from "../../modals/register_cancelled/register_cancelled_screen";
import VerificationCancelledModal from "../../modals/verf_cancelled/verification_cancelled_modal";
import useAppContext from "../../../utils/context/useAppContext/use_app_context";
import { useNavigate } from "react-router-dom";

function LayoutAppLoad(props: any) {
  return props.children;
}

export default LayoutAppLoad;
