/* eslint-disable eqeqeq */
import { IdentityVerfErrors } from "../../models/verifications/verifications_models";

interface getTextParagraphParams {
  error: IdentityVerfErrors;
  isStepCompleted: boolean;
}

export class IdentityUtils {
  detectError(response: any): IdentityVerfErrors {
    const responseString = JSON.stringify(response).toUpperCase();

    if (responseString.includes(IdentityVerfErrors.badImage)) {
      return IdentityVerfErrors.badImage;
    }
    if (responseString.includes(IdentityVerfErrors.tinyBufferImage)) {
      return IdentityVerfErrors.tinyBufferImage;
    }
    if (responseString.includes(IdentityVerfErrors.duplicatedDocumentNumber)) {
      return IdentityVerfErrors.duplicatedDocumentNumber;
    }
    if (responseString.includes(IdentityVerfErrors.errorFetchingData)) {
      return IdentityVerfErrors.errorFetchingData;
    }
    if (responseString.includes(IdentityVerfErrors.unathorized)) {
      return IdentityVerfErrors.unathorized;
    }

    return IdentityVerfErrors.tinyBufferImage;
  }

  getTextParagraph({ error, isStepCompleted }: getTextParagraphParams): string {
    if (isStepCompleted) return "La imagen es correcta.";

    switch (error) {
      case IdentityVerfErrors.badImage:
        return "La imagen no es válida. Por favor, inténtalo de nuevo.";
      case IdentityVerfErrors.tinyBufferImage:
        return "La imagen es muy pequeña. Por favor, inténtalo de nuevo.";
      case IdentityVerfErrors.duplicatedDocumentNumber:
        return "El número de documento ya está en uso. Por favor, inténtalo de nuevo.";
      case IdentityVerfErrors.errorFetchingData:
        return "La imagen no es válida. Por favor, inténtalo de nuevo.";
      case IdentityVerfErrors.unathorized:
        return "El tiempo para completar el proceso ha expirado. Por favor, inténtalo de nuevo.";
    }

    return "Revisa que la foto sea clara y legible.";
  }

  getColorParagraph({
    error,
    isStepCompleted,
  }: getTextParagraphParams): string {
    if (error != IdentityVerfErrors.idle) return "red";

    if (isStepCompleted) return "red";

    return "grey";
  }
}
