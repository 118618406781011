/* eslint-disable no-script-url */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import Button from "../../elements/buttons/button";
import { useNavigate } from "react-router-dom";
import { RoutesList } from "../../../utils/routes/routes_utils";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import VerificationCancelledScreen from "../../../screens/verifications/misc/verification_cancelled_screen";
import Ilustration from "../../../assets/ilustrations/verificationfailed.svg";
import useAppContext from "../../../utils/context/useAppContext/use_app_context";
import { UserUtils } from "../../../utils/user/user_utils";
import { toast } from "react-hot-toast";

function CameraDeniedModal() {
  const appContext = useAppContext();

  const handleOpenSettings = () => {
    var userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.indexOf("chrome") !== -1) {
      window.location.href = "chrome://settings/";
    } else if (userAgent.indexOf("edge") !== -1) {
      window.location.href = "edge://settings/";
    } else if (userAgent.indexOf("firefox") !== -1) {
      window.location.href = "about:preferences";
    } else if (userAgent.indexOf("safari") !== -1) {
      window.location.href = 'javascript:window.open("prefs:root");';
    }
  };

  useEffect(() => {
    appContext.state.ws?.close();
  }, []);

  return (
    <div
      className={`verification_identity_screen verification_identity_screen_finished verification_cancelled verification_identity_screen_finished_true`}
    >
      <img src={Ilustration} />
      <h6 style={{ fontWeight: 500, fontSize: "4vw" }}>
        <b>
          El acceso a la cámara se ha denegado. Para poder seguir con la
          verificación, sigue estos pasos:
        </b>{" "}
        <br /> <br />
        1. Abre la configuración de tu navegador. <br /> <br />
        2. Busca la sección de "Permisos" o tambien llamada "Configuración de
        sitios". <br /> <br />
        3. Habilita el permiso de cámara para esta página. <br /> <br />
        4. Escanea nuevamente el código QR desde la aplicación web que tienes
        abierta <br />
      </h6>
    </div>
  );
}

export default CameraDeniedModal;
