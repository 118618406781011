/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import Ilustration from "../../../assets/ilustrations/verificationfailed.svg";

function VerificationCancelledScreen() {
  return (
    <div
      className={`verification_identity_screen verification_identity_screen_finished verification_cancelled verification_identity_screen_finished_true`}
    >
      <img src={Ilustration} />

      <h2>Proceso de verificación no culminado</h2>

      <p>
        para continuar porfavor escanea nuevamente el código QR en la web e
        inicia el proceso.
      </p>
    </div>
  );
}

export default VerificationCancelledScreen;
