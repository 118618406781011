/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LocalStorageItems } from "../../models/local_storage/local_storage";
import { RoutesList } from "../../utils/routes/routes_utils";
import { basename } from "path/posix";
import useAppContext from "../../utils/context/useAppContext/use_app_context";
import { SocketUtils } from "../../utils/socket/socket_utils";
import { getSettings } from "../../service/settings/settings";

function StartScreen() {
  const { jwtt, verificationMode }: any = useParams();
  const navigate = useNavigate();
  const appContext = useAppContext();

  const onStart = () => {
    localStorage.setItem(LocalStorageItems.REGISTERMODE, "false");

    if (jwtt) {
      localStorage.setItem(LocalStorageItems.JWTT, jwtt);
      const ws = new WebSocket(
        `${process.env.REACT_APP_URL_SOCKET}?Authorization=${jwtt}`
      );

      ws.onmessage = (e) => new SocketUtils().onMessage(e, navigate);
      ws.onopen = () => new SocketUtils().onOpen(ws);
      ws.onclose = () => {
        console.log("socket disconnected");
      };
      appContext.changeContext({ ws });
    }

    getSettings(appContext);

    let initialRoute: string = RoutesList.IdentityIntro;

    switch (verificationMode) {
      case "facial":
        initialRoute = RoutesList.StartVerificationFacial;
        break;
      case "identity":
        initialRoute = RoutesList.IdentityIntro;
        break;
      case "address":
        initialRoute = RoutesList.AddressDataVerifications;
        break;
    }

    navigate(initialRoute);
  };

  useEffect(onStart, []);

  return <div>StartScreen</div>;
}

export default StartScreen;
