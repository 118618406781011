import { RouteObject } from "react-router-dom";
import LivenessChallenge from "../../screens/verifications/verification_facial/liveness_challenge/liveness_challenge";
import InstructionOneVerificationFacial from "../../screens/verifications/verification_facial/instructions/instruction_one_verification_facial";
import InstructionTwoVerificationFacial from "../../screens/verifications/verification_facial/instructions/instruction_two_verification_facial";
import InstructionThreeVerificationFacial from "../../screens/verifications/verification_facial/instructions/instruction_three_verification_facial";
import InstructionFourVerificationFacial from "../../screens/verifications/verification_facial/instructions/instruction_four_verification_facial";
import StartScreen from "../../screens/misc/start_screen";
import CompletedLivenessChallenge from "../../screens/verifications/verification_facial/liveness_challenge/completed_liveness_challenge";
import IntroVerificationIdentity from "../../screens/verifications/verification_identity/intro/intro_verification_identity";
import VerificationIdentityImage from "../../screens/verifications/verification_identity/images/images_verification_identity";
import FinishVerificationIdentity from "../../screens/verifications/verification_identity/finish/finish_verification_identity";
import VerificationIdentityReview from "../../screens/verifications/verification_identity/review/verification_identity_review";
import FieldsVerificationAddress from "../../screens/verifications/verification_address/data/verification_address_data";
import DcoumentsVerificationAddress from "../../screens/verifications/verification_address/document/verification_address_document";
import FinishedVerificationAddress from "../../screens/verifications/verification_address/finish/verification_address_finish";
import VerificationCancelledScreen from "../../screens/verifications/misc/verification_cancelled_screen";
import IntroVerificationFacial from "../../screens/verifications/verification_facial/intro/intro_verification_facial";
import VerificationIdentitySelectCountry from "../../screens/verifications/verification_identity/country/verification_identity_select_country";
import { RegisterWelcomeScreen } from "../../screens/misc/register_welcome";
import RegisterScreen from "../../screens/misc/register_screen";
import IntroRegisterFacial from "../../screens/verifications/verification_facial/intro/intro_register_facial";
import EndFlowScreen from "../../screens/verifications/misc/end_flow_screen";
import UseAppScreen from "../../screens/misc/use_app_screen";
import VinculateEmailScreen from "../../screens/misc/vinculate_email";

export enum RoutesList {
  // Verification facial
  RegisterVerificationFacial = "/verification-facial/register",
  StartVerificationFacial = "/verification-facial/start",
  InstructionOneVerificationFacial = "/verification-facial/instructions/one",
  InstructionTwoVerificationFacial = "/verification-facial/instructions/two",
  InstructionThreeVerificationFacial = "/verification-facial/instructions/three",
  InstructionFourVerificationFacial = "/verification-facial/instructions/four",
  LivenessChallenge = "/liveness-challenge",
  CompletedLivenessChallenge = "/verification-facial/completed",
  // Verification identity
  IdentityIntro = "/verification-identity",
  IdentityCountry = "/verification-identity/country",
  FrontImageDocument = "/verification-identity/documents/front",
  BackImageDocument = "/verification-identity/documents/back",
  ReviewDataIdentity = "/verification-identity/review-data",
  SuccessVerificationIdentity = "/verification-identity/success",
  FailedVerificationIdentity = "/verification-identity/failed",
  // Verification address
  AddressDataVerifications = "/verifications/address/data",
  AddressDocumentVerifications = "/verifications/address/document",
  AddressFinishedVerifications = "/verifications/address/finish",
  // Misc
  VerificationCancelled = "/verifications/cancelled",
  useApp = "/useApp",
  vinculateEmail = "/vinculate-email",
  // Welcome
  WelcomeRegister = "/register/welcome",
  EndFlowScreen = "/register/end",
}

export const routes = (): RouteObject[] => {
  return [
    {
      path: "/",
      element: <div />,
    },
    {
      path: "/start/:jwtt/:verificationMode",
      element: <StartScreen />,
    },
    // Register
    {
      path: "/register",
      element: <RegisterScreen />,
    },
    {
      path: RoutesList.WelcomeRegister,
      element: <RegisterWelcomeScreen />,
    },
    {
      path: RoutesList.RegisterVerificationFacial,
      element: <IntroRegisterFacial />,
    },
    {
      path: RoutesList.EndFlowScreen,
      element: <EndFlowScreen />,
    },
    // Verf. facial
    {
      path: RoutesList.StartVerificationFacial,
      element: <IntroVerificationFacial />,
    },
    {
      path: RoutesList.InstructionOneVerificationFacial,
      element: <InstructionOneVerificationFacial />,
    },
    {
      path: RoutesList.InstructionTwoVerificationFacial,
      element: <InstructionTwoVerificationFacial />,
    },
    {
      path: RoutesList.InstructionThreeVerificationFacial,
      element: <InstructionThreeVerificationFacial />,
    },
    {
      path: RoutesList.InstructionFourVerificationFacial,
      element: <InstructionFourVerificationFacial />,
    },
    {
      path: RoutesList.LivenessChallenge,
      element: <LivenessChallenge />,
    },
    {
      path: RoutesList.CompletedLivenessChallenge,
      element: <CompletedLivenessChallenge />,
    },
    // Verf. identity
    {
      path: RoutesList.IdentityIntro,
      element: <IntroVerificationIdentity />,
    },
    {
      path: RoutesList.IdentityCountry,
      element: <VerificationIdentitySelectCountry />,
    },
    {
      path: RoutesList.FrontImageDocument,
      element: <VerificationIdentityImage isFrontSide />,
    },
    {
      path: RoutesList.BackImageDocument,
      element: <VerificationIdentityImage isFrontSide={false} />,
    },
    {
      path: RoutesList.ReviewDataIdentity,
      element: <VerificationIdentityReview />,
    },
    {
      path: RoutesList.SuccessVerificationIdentity,
      element: <FinishVerificationIdentity isComplete />,
    },
    {
      path: RoutesList.FailedVerificationIdentity,
      element: <FinishVerificationIdentity isComplete={false} />,
    },
    // Address
    {
      path: RoutesList.AddressDataVerifications,
      element: <FieldsVerificationAddress />,
    },
    {
      path: RoutesList.AddressDocumentVerifications,
      element: <DcoumentsVerificationAddress />,
    },
    {
      path: RoutesList.AddressFinishedVerifications,
      element: <FinishedVerificationAddress />,
    },
    // Misc
    {
      path: RoutesList.VerificationCancelled,
      element: <VerificationCancelledScreen />,
    },
    {
      path: RoutesList.useApp,
      element: <UseAppScreen />,
    },
    {
      path: RoutesList.vinculateEmail,
      element: <VinculateEmailScreen />,
    },
  ];
};
