/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import LayoutVerificationsHeader from "../../../../components/layouts/veriifcations_layout/header/layout_verifications_header";
import {
  Abbreviation,
  CountryWithOCR,
  SupportedDocument,
  Type,
  VerificationsMode,
} from "../../../../models/verifications/verifications_models";
import Ilustration from "../../../../assets/ilustrations/verification_check.svg";
import Button from "../../../../components/elements/buttons/button";
import VerfIdentityTypeDocument, {
  DocumentType,
  getIdOFDocumentType,
} from "../../../../components/verf_identity/country/verf_identity_type_document/verf_identity_type_document";
import VerfIdentityCountrySelector from "../../../../components/verf_identity/country/selector/verf_identity_country_selector";
import { useNavigate } from "react-router-dom";
import { RoutesList } from "../../../../utils/routes/routes_utils";
import useAppContext from "../../../../utils/context/useAppContext/use_app_context";
import { IdentityService } from "../../../../service/identity/identity_service";
import { toast } from "react-hot-toast";
import LayoutAppLoad from "../../../../components/layouts/veriifcations_layout/layout_app_load";

function VerificationIdentitySelectCountry() {
  const navigate = useNavigate();
  const context = useAppContext();

  const [countrySelected, setCountrySelected] = useState<CountryWithOCR>({
    name: "Argentina",
    abbreviation: "ARG",
    flag_image:
      "https://don-fido-resources.s3.us-east-2.amazonaws.com/currencies-resources/fiat/AR.png",
    supported_documents: [
      {
        type: Type.Pasaporte,
        abbreviation: Abbreviation.Pp,
        double_sided: false,
      },
      {
        type: Type.LicenciaDeConducir,
        abbreviation: Abbreviation.DL,
        double_sided: true,
      },
      {
        type: Type.CedulaDeIdentidad,
        abbreviation: Abbreviation.DL,
        double_sided: true,
      },
    ],
  });

  const [messageError, setMessageError] = useState("");
  const [documentTypeSelected, setDocumentType] =
    React.useState<SupportedDocument | null>(null);

  const onSubmit = () => {
    const hasSelectedDocumentType = documentTypeSelected !== null;

    setMessageError(
      hasSelectedDocumentType
        ? ""
        : "Debes seleccionar el tipo de país y documento para poder continuar el proceso"
    );

    if (!hasSelectedDocumentType) return;

    context.changeContext({
      countrySelected: countrySelected,
      documentTypeSelected: documentTypeSelected,
    });

    navigate(RoutesList.FrontImageDocument, {
      state: {
        countrySelected: countrySelected,
        documentTypeSelected: documentTypeSelected,
      },
    });
  };

  return (
    <LayoutAppLoad>
      <div className="LayoutVerification verification_identity_select_country">
        <LayoutVerificationsHeader
          mode={VerificationsMode.identity}
          hideTitle
        />
        <div className="ilustration">
          <img src={Ilustration} alt="ilustration" />
        </div>

        <p className="verification-paragraph">
          Para continuar, por favor, proporciona la información solicitada a
          continuación.
        </p>

        <VerfIdentityCountrySelector
          countrySelected={countrySelected}
          setCountrySelected={(e: CountryWithOCR) => {
            setCountrySelected(e);
            setDocumentType(null);
          }}
        />
        <VerfIdentityTypeDocument
          countrySelected={countrySelected}
          setDocumentType={setDocumentType}
          documentTypeSelected={documentTypeSelected}
        />

        {messageError && (
          <p className="verification-paragraph colorred">{messageError}</p>
        )}

        <div className="footer">
          <Button label="Continuar" width="100%" onTap={onSubmit} />
        </div>
      </div>
    </LayoutAppLoad>
  );
}

export default VerificationIdentitySelectCountry;
