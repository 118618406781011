/* eslint-disable eqeqeq */
import {
  LivenessChallengeConfig,
  LivenessTestsModes,
  LivenessTestsSides,
} from "../../../../models/verifications/verifications_models";

export class LivenessChallengeUtils {
  getHelper = (config: LivenessChallengeConfig): string => {
    if (config.faceIsNotLikeDocument) {
      return "El rostro no coincide con el documento";
    }

    if (config.faceIsOutTheBox) {
      return "Tu cara está fuera del recuadro";
    }
    if (config.faceIsTooClose) {
      return "Tu cara está muy cerca de la cámara";
    }

    if (config.faceIsTooFar) {
      return "Tu cara está muy lejos de la cámara";
    }

    if (config.challengeType == LivenessTestsModes.checkpoints) {
      if (config.hasStartedProcess) {
        return "Centra tu nariz en el cuadro y aguarda unos segundos";
      }
      return "Para validar prueba de vida realizaremos una pequeña grabación. Centra tu nariz en el punto y presiona el botón para comenzar la grabación.";
    }

    if (config.challengeType == LivenessTestsModes.yaw) {
      return `Ahora mira tu hombro ${
        config.challengeModeSide == LivenessTestsSides.left
          ? "izquierdo"
          : "derecho"
      }`;
    }

    if (config.challengeType == LivenessTestsModes.smile) {
      return "Ahora sonríe";
    }

    if (config.challengeType == LivenessTestsModes.eye) {
      return "Cierra los ojos hasta escuchar el sonido";
    }

    return config.challengeType;
  };
}
