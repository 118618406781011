import React, { useEffect, useRef, useState } from "react";
import FooterCamera from "./footer_camera/footer_camera";
import Webcam from "react-webcam";

import Button from "../elements/buttons/button";
import ImageCropper from "../image_cropper/image_cropper";
import LivenessIndicator from "./liveness_indicator/liveness_indicator";
import { LivenessProps } from "../../models/camera/camera_models";
import { isApp } from "../../utils/responsive/responsive";
import FaceSquareIndicator from "./face_square/face_square";
import { toast } from "react-hot-toast";
import LivenessTimeIndicator from "./liveness_time_indicator/liveness_time_indicator";
import CameraDeniedModal from "../modals/camera_denied/camera_denied_modal";
import { iOS } from "../../utils/devices/devices_utils";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import useAppContext from "../../utils/context/useAppContext/use_app_context";

interface Props {
  isVideo: boolean;
  hasStartedProcces?: boolean;
  onTakePhoto?: (base64: string) => void;
  livenessProps?: LivenessProps;
  cameraRef?: typeof React.useRef;
  overlay?: React.ReactNode;
  videoConstraints?: any;
  mirrored: boolean;
}

function Camera(props: Props) {
  const webcamRef: any = React.useRef(null);
  const mySwal = withReactContent(Swal);
  const appContext = useAppContext();

  const checkCameraPermissionIos = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      mySwal.close();
      // Aquí puedes continuar con el uso de la cámara
    } catch (error) {
      onRejectPermission();
    }
  };

  const checkCameraPermission = async () => {
    try {
      const { state } = await navigator.permissions.query({
        name: "camera" as PermissionName,
      });

      if (state === "denied") {
        onRejectPermission();
      } else {
        mySwal.close();
      }
    } catch (error) {}
  };

  const requestCameraPermissios = async () => {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: "camera" as PermissionName,
      });
    } catch (error) {
      onRejectPermission();
    }
  };

  const checkPermissionByPlatform = () => {
    if (iOS()) {
      checkCameraPermissionIos();
    } else {
      checkCameraPermission();
    }
  };

  const onRejectPermission = () => {
    appContext.state.ws?.close();

    mySwal.fire({
      showCloseButton: false,
      width: "88vw",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      showCancelButton: false,
      padding: "0 0 0 0",
      html: <CameraDeniedModal />,
    });
  };

  useEffect(() => {
    try {
      if (iOS()) {
        requestCameraPermissios();
      }
      checkPermissionByPlatform();
      const permissionPromise = navigator.permissions.query({
        name: "camera" as PermissionName,
      });
      permissionPromise.then((permissionStatus) => {
        permissionStatus.onchange = checkPermissionByPlatform;
      });

      return () => {
        permissionPromise.then((permissionStatus) => {
          permissionStatus.onchange = null;
        });
      };
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const width: string = isApp() ? "100%" : "50%";

  return (
    <div className="camera-content">
      <div className="web-cam-content" style={{ width }}>
        <Webcam
          width="100%"
          screenshotFormat="image/jpeg"
          audio={false}
          forceScreenshotSourceSize={true}
          ref={props.cameraRef ?? webcamRef}
          videoConstraints={props.videoConstraints}
          onUserMediaError={(error) => {
            console.log("error", error);
          }}
          mirrored={props.mirrored}
        />
        {props.livenessProps?.showIndicator && (
          <LivenessIndicator
            width={width}
            livenessProps={props.livenessProps}
          />
        )}

        {props.isVideo && (
          <FaceSquareIndicator state={props.livenessProps?.state} />
        )}

        {props.isVideo && (
          <LivenessTimeIndicator
            remainingSeconds={props.livenessProps?.remainingSeconds || 120}
          />
        )}

        {props.overlay}
      </div>
    </div>
  );
}

export default Camera;
