/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { AdviceMode } from "../../models/component_props/button_props";
import AdviceIcon from "../../assets/icons/warning.svg";

interface Props {
  text: string;
  title: string;
  mode: AdviceMode;
}

function Advice({ text, title, ...props }: Props) {
  return (
    <div className="advice">
      <div className="header">
        <img src={AdviceIcon} /> <h3>{title}</h3>
      </div>
      <p>{text}</p>
    </div>
  );
}

export default Advice;
