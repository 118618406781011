import React from "react";
import CheckboxActive from "../../../../assets/icons/checkbox_active.svg";
import CheckboxInactive from "../../../../assets/icons/checkbox_inactive.svg";

interface PropsCheckBox {
  value: boolean;
  onToggle: () => void;
}

function Checkbox(props: PropsCheckBox) {
  return (
    <div className="checkbox hover" onClick={props.onToggle}>
      <img
        src={props.value ? CheckboxActive : CheckboxInactive}
        alt="checkbox"
      />
    </div>
  );
}

export default Checkbox;
