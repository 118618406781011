/* eslint-disable eqeqeq */
import React from "react";
import { LivenessProps } from "../../../models/camera/camera_models";

interface Props {
  width: string;
  livenessProps?: LivenessProps;
}

function LivenessIndicator({ livenessProps, ...props }: Props) {
  const getStyles = (): React.CSSProperties => {
    const defaultStyle = {
      top: "46%",
      left: "46%",
    };

    if (livenessProps == null) return defaultStyle;

    const { x, y } = livenessProps;
    if (x == 0 && y == 0) return defaultStyle;

    const positionedStyles: React.CSSProperties = {};

    if (x != null && x != 0) {
      positionedStyles["left"] = `${x}%`;
    }

    if (y != null && y != 0) {
      positionedStyles["top"] = `${y}%`;
    }

    return positionedStyles;
  };

  return (
    <div className="liveness-indicator-content">
      <div className="square" style={getStyles()}></div>
    </div>
  );
}

export default LivenessIndicator;
