import React from "react";
import LayoutVerification from "../../../../components/layouts/veriifcations_layout/layout_verifications";
import { VerificationsMode } from "../../../../models/verifications/verifications_models";
import SummaryItem from "../../../../components/summary/summary_item";
import Button from "../../../../components/elements/buttons/button";
import { useNavigate } from "react-router-dom";
import { RoutesList } from "../../../../utils/routes/routes_utils";

function IntroRegisterFacial() {
  const navigate = useNavigate();

  return (
    <LayoutVerification mode={VerificationsMode.facial} hideTitle>
      <p
        className="verification-paragraph"
        style={{ textAlign: "start", marginTop: "0" }}
      >
        Para finalizar, realizaremos una verificación facial.
      </p>

      <div className="summary-container">
        <SummaryItem
          title="Verificación de identidad"
          isCompleted={true}
          disabled={false}
        />
        <SummaryItem
          title="Verificación facial"
          isCompleted={false}
          disabled={false}
        />
      </div>

      <div
        className="footer"
        style={{ position: "absolute", bottom: "5%", width: "90%" }}
      >
        <Button
          label="Continuar"
          width="100%"
          onTap={() => navigate(RoutesList.InstructionOneVerificationFacial)}
        />
      </div>
    </LayoutVerification>
  );
}

export default IntroRegisterFacial;
