/* eslint-disable eqeqeq */
import React, { useRef, useState } from "react";
import { Cropper, ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import Button from "../elements/buttons/button";
import "react-image-crop/src/ReactCrop.scss";
import { toast } from "react-hot-toast";
import useAppContext from "../../utils/context/useAppContext/use_app_context";

interface Props {
  base64: string;
  setBase64(base64: string): void;
  onCrop(base64: string): void;
  takePhotoAgain: () => void;
  isFrontSide: boolean;
}

function ImageCropper({
  isFrontSide,
  base64,
  takePhotoAgain,
  setBase64,
  onCrop,
}: Props) {
  const appContext = useAppContext();
  const cropperRef: any = useRef(null);

  const rotateImage = (angle: number) => {
    try {
      appContext.toggleLoading();
      var img = new Image();

      img.onload = function () {
        var canvas = document.createElement("canvas");

        canvas.width = img.height;
        canvas.height = img.width;
        var ctx: any = canvas.getContext("2d");

        ctx.translate(Math.floor(img.height / 2), Math.floor(img.width / 2));

        ctx.rotate(angle * (Math.PI / 180));

        ctx.drawImage(img, -img.width / 2, -img.height / 2);

        setBase64(canvas.toDataURL("image/webp", 1));
        appContext.toggleLoading(false);
      };

      // Carga la imagen base64
      img.src = base64!;
    } catch (error) {
      appContext.toggleLoading(false);
      toast.error("Error al rotar la imagen");
    }
  };

  const onConfirmChanges = () => {
    onCrop(base64);
    /*  const cropper = cropperRef.current?.cropper;
    const base64Cropped = cropper?.getCroppedCanvas().toDataURL();

    if (base64Cropped != null) {
      onCrop(base64Cropped);
    } */
  };

  if (base64 == "") return <div></div>;

  return (
    <div className="image_cutter ">
      <h2>Parte {isFrontSide ? "frontal" : "posterior"} de identificación</h2>

      <div style={{ width: "85vw" }} className="center_text">
        {/*   <Cropper
          width={"60vw"}
          dragMode="none"
          src={base64}
          guides={true}
          movable={false}
          crop={(e) => {}}
          ref={cropperRef}
        /> */}
        <img src={base64} width={"100%"} alt="preview" />
      </div>

      <Button label="Enviar a verificar" onTap={onConfirmChanges} />
      <Button
        label="Tomar fotografía otra vez"
        outlined
        onTap={takePhotoAgain!}
      />
    </div>
  );
}

export default ImageCropper;
