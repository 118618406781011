import { State } from "./address_service";

export const usaCities: State[] = [
  {
    name: "Alabama",
    alias: ["Alabama"],
    municipalities: [
      "Birmingham",
      "Montgomery",
      "Mobile",
      "Huntsville",
      "Tuscaloosa",
      "Hoover",
      "Dothan",
      "Auburn",
      "Decatur",
      "Madison",
      "Florence",
      "Gadsden",
      "Vestavia Hills",
      "Prattville",
      "Phenix City",
      "Alabaster",
      "Bessemer",
      "Enterprise",
      "Opelika",
      "Homewood",
      "Northport",
      "Anniston",
      "Prichard",
      "Athens",
      "Daphne",
      "Pelham",
      "Oxford",
      "Albertville",
      "Selma",
      "Mountain Brook",
      "Trussville",
      "Troy",
      "Helena",
      "Foley",
      "Hueytown",
      "Center Point",
      "Talladega",
      "Fairhope",
      "Ozark",
      "Alexander City",
      "Cullman",
      "Scottsboro",
      "Millbrook",
      "Jasper",
      "Hartselle",
      "Saraland",
      "Fort Payne",
      "Muscle Shoals",
      "Eufaula",
      "Sylacauga",
      "Pell City",
      "Jacksonville",
      "Fairfield",
      "Gulf Shores",
      "Pleasant Grove",
      "Atmore",
      "Bay Minette",
      "Russellville",
      "Boaz",
      "Clay",
      "Moody",
      "Rainbow City",
      "Southside",
      "Gardendale",
      "Irondale",
      "Leeds",
      "Calera",
      "Selmont-West Selmont",
      "Valley",
      "Tuscumbia",
      "Sheffield",
      "Fultondale",
      "Spanish Fort",
      "Saks",
      "Glencoe",
      "Brewton",
      "Satsuma",
      "Moulton",
      "Childersburg",
      "Chickasaw",
      "Rainsville",
      "Margaret",
      "Roanoke",
      "Monroeville",
      "Lanett",
      "Brent",
      "Montevallo",
      "Jackson",
      "Attalla",
    ],
  },
  {
    name: "Alaska",
    alias: ["Alaska"],
    municipalities: [
      "Anchorage",
      "Fairbanks",
      "Juneau",
      "Sitka",
      "Ketchikan",
      "Wasilla",
      "Kenai",
      "Kodiak",
      "Bethel",
      "Palmer",
      "Homer",
      "Unalaska",
      "Barrow (Utqiaġvik)",
      "Soldotna",
      "Valdez",
      "Nome",
      "Seward",
      "Wrangell",
      "Dillingham",
      "Cordova",
      "North Pole",
      "Petersburg",
      "Kotzebue",
      "Haines",
      "Skagway",
      "Delta Junction",
      "Tok",
      "Gustavus",
      "Eagle River",
      "Willow",
      "Eielson Air Force Base",
      "Diamond Ridge",
      "Big Lake",
      "Sitka",
      "Knik-Fairview",
      "Farmers Loop",
      "Lakes",
      "Gateway",
      "College",
      "Ridgeway",
      "Bear Creek",
      "Meadow Lakes",
      "Sterling",
      "Seward",
      "Fritz Creek",
      "Kalifornsky",
      "Butte",
      "Funny River",
      "Lazy Mountain",
      "Fishhook",
      "Anchor Point",
      "Soldotna",
      "Cordova",
      "Badger",
      "Craig",
      "Alakanuk",
      "Mountain Village",
      "Sand Point",
      "King Cove",
      "Emmonak",
      "Chevak",
      "Unalakleet",
      "Hooper Bay",
      "Stebbins",
      "Pilot Station",
      "Kake",
      "Gambell",
      "Wales",
      "Diomede",
      "Kivalina",
      "Point Lay",
      "Buckland",
      "Selawik",
      "Noorvik",
      "Kiana",
      "Shungnak",
      "Huslia",
      "Hughes",
      "Nulato",
      "Kaltag",
      "Galena",
      "Ruby",
      "Tanana",
      "Rampart",
      "Allakaket",
      "Koyukuk",
      "Alatna",
      "Anvik",
      "Shageluk",
      "Grayling",
      "Holy Cross",
      "Aniak",
      "Crooked Creek",
      "Chuathbaluk",
      "Red Devil",
      "Sleetmute",
      "Stony River",
      "Napaimute",
      "Lime Village",
    ],
  },
  {
    name: "Arizona",
    alias: ["Arizona"],
    municipalities: [
      "Phoenix",
      "Tucson",
      "Mesa",
      "Chandler",
      "Scottsdale",
      "Glendale",
      "Gilbert",
      "Tempe",
      "Peoria",
      "Surprise",
      "Yuma",
      "Avondale",
      "Flagstaff",
      "Goodyear",
      "Buckeye",
      "Lake Havasu City",
      "Casa Grande",
      "Sierra Vista",
      "Maricopa",
      "Oro Valley",
      "Prescott",
      "Bullhead City",
      "Prescott Valley",
      "Apache Junction",
      "Marana",
      "El Mirage",
      "Kingman",
      "Queen Creek",
      "Florence",
      "San Luis",
      "Sahuarita",
      "Fountain Hills",
      "Nogales",
      "Eloy",
      "Somerton",
      "Paradise Valley",
      "Coolidge",
      "Cottonwood",
      "Chino Valley",
      "Camp Verde",
      "Show Low",
      "Sedona",
      "Winslow",
      "Safford",
      "Globe",
      "Page",
      "Tolleson",
      "Wickenburg",
      "Youngtown",
      "South Tucson",
      "Snowflake",
      "Bisbee",
      "Guadalupe",
      "Litchfield Park",
      "Holbrook",
      "Thatcher",
      "Cave Creek",
      "Benson",
      "Sanders",
      "Wellton",
      "Colorado City",
      "Pinetop-Lakeside",
      "Taylor",
      "Clarkdale",
      "Dewey-Humboldt",
      "Willcox",
      "Quartzsite",
      "St. Johns",
      "Clifton",
      "Parker",
      "Williams",
      "Superior",
      "Pima",
      "Star Valley",
      "Springerville",
      "Kearny",
      "Gila Bend",
      "Miami",
      "Oatman",
      "Fredonia",
      "Patagonia",
      "Mammoth",
      "Duncan",
      "Jerome",
      "Hayden",
      "Winkelman",
      "Kanab",
    ],
  },
  {
    name: "Arkansas",
    alias: ["Arkansas"],
    municipalities: [
      "Little Rock",
      "Fort Smith",
      "Fayetteville",
      "Springdale",
      "Jonesboro",
      "North Little Rock",
      "Conway",
      "Rogers",
      "Bentonville",
      "Pine Bluff",
      "Benton",
      "Hot Springs",
      "Texarkana",
      "Sherwood",
      "Jacksonville",
      "Russellville",
      "Bella Vista",
      "West Memphis",
      "Paragould",
      "Cabot",
      "Searcy",
      "Van Buren",
      "El Dorado",
      "Maumelle",
      "Bryant",
      "Blytheville",
      "Forrest City",
      "Siloam Springs",
      "Harrison",
      "Hot Springs Village",
      "Mountain Home",
      "Marion",
      "Camden",
      "Magnolia",
      "Malvern",
      "Arkadelphia",
      "Hope",
      "Centerton",
      "Monticello",
      "Stuttgart",
      "Clarksville",
      "Greenwood",
      "Wynne",
      "Newport",
      "Osceola",
      "Lowell",
      "Beebe",
      "Trumann",
      "Heber Springs",
      "Pocahontas",
      "De Queen",
      "Morrilton",
      "Warren",
      "Mena",
      "Crossett",
      "Alma",
      "Berryville",
      "White Hall",
      "Pea Ridge",
      "Cave Springs",
      "Dardanelle",
      "Nashville",
      "Walnut Ridge",
      "Ashdown",
      "Lonoke",
      "Dumas",
      "Booneville",
      "Haskell",
      "Piggott",
      "Gentry",
      "Fordyce",
      "McGehee",
      "Ozark",
      "Waldron",
      "Brinkley",
      "Marianna",
      "Paris",
      "Atkins",
      "Osceola",
      "Manila",
      "Prescott",
      "Gosnell",
      "Corning",
      "Brinkley",
    ],
  },
  {
    name: "California",
    alias: ["California"],
    municipalities: [
      "Los Angeles",
      "San Diego",
      "San Jose",
      "San Francisco",
      "Fresno",
      "Sacramento",
      "Long Beach",
      "Oakland",
      "Bakersfield",
      "Anaheim",
      "Santa Ana",
      "Riverside",
      "Stockton",
      "Chula Vista",
      "Irvine",
      "Fremont",
      "San Bernardino",
      "Modesto",
      "Fontana",
      "Oxnard",
      "Moreno Valley",
      "Huntington Beach",
      "Glendale",
      "Santa Clarita",
      "Oceanside",
      "Rancho Cucamonga",
      "Ontario",
      "Lancaster",
      "Elk Grove",
      "Palmdale",
      "Corona",
      "Salinas",
      "Pomona",
      "Torrance",
      "Hayward",
      "Escondido",
      "Sunnyvale",
      "Orange",
      "Pasadena",
      "Fullerton",
      "Thousand Oaks",
      "Visalia",
      "Simi Valley",
      "Concord",
      "Roseville",
      "Victorville",
      "Santa Clara",
      "Vallejo",
      "Berkeley",
      "El Monte",
      "Downey",
      "Costa Mesa",
      "Inglewood",
      "Carlsbad",
      "San Buenaventura (Ventura)",
      "Fairfield",
      "West Covina",
      "Murrieta",
      "Richmond",
      "Norwalk",
      "Antioch",
      "Temecula",
      "Burbank",
      "Daly City",
      "Rialto",
      "Santa Maria",
      "El Cajon",
      "San Mateo",
      "Clovis",
      "Compton",
      "Jurupa Valley",
      "Vista",
      "South Gate",
      "Mission Viejo",
      "Vacaville",
      "Carson",
      "Hesperia",
      "Santa Monica",
      "Westminster",
      "Redding",
      "Santa Barbara",
      "Chico",
      "Whittier",
      "Newport Beach",
      "San Leandro",
      "San Marcos",
      "Lakewood",
      "Pompano Beach",
      "Visalia",
      "Mountain View",
      "Palm Springs",
      "Lakewood",
      "Pompano Beach",
    ],
  },
  {
    name: "Colorado",
    alias: ["Colorado"],
    municipalities: [
      "Denver",
      "Colorado Springs",
      "Aurora",
      "Fort Collins",
      "Lakewood",
      "Thornton",
      "Arvada",
      "Westminster",
      "Pueblo",
      "Centennial",
      "Boulder",
      "Greeley",
      "Longmont",
      "Loveland",
      "Broomfield",
      "Grand Junction",
      "Castle Rock",
      "Commerce City",
      "Parker",
      "Littleton",
      "Northglenn",
      "Brighton",
      "Englewood",
      "Wheat Ridge",
      "Fountain",
      "Lafayette",
      "Windsor",
      "Erie",
      "Evans",
      "Golden",
      "Louisville",
      "Montrose",
      "Durango",
      "Cañon City",
      "Greenwood Village",
      "Sterling",
      "Lone Tree",
      "Johnstown",
      "Superior",
      "Craig",
      "Fruita",
      "Steamboat Springs",
      "Federal Heights",
      "Firestone",
      "Fort Morgan",
      "Frederick",
      "Castle Pines",
      "Delta",
      "Glenwood Springs",
      "Alamosa",
      "Rifle",
      "Trinidad",
      "Woodland Park",
      "Wellington",
      "Lamar",
      "La Junta",
      "Aspen",
      "Gypsum",
      "Eagle",
      "Avon",
      "Sheridan",
      "Fort Lupton",
      "Milliken",
      "Carbondale",
      "Cherry Hills Village",
      "Monument",
      "Orchard City",
      "Estes Park",
      "Brush",
      "Gunnison",
      "Cortez",
      "Vail",
      "La Salle",
      "Salida",
      "Berthoud",
      "Glendale",
      "Mountain Village",
      "Edgewater",
      "Rifle",
      "Cedaredge",
      "Bennett",
      "Manitou Springs",
      "Kremmling",
      "Severance",
      "Burlington",
      "Yuma",
      "Dacono",
      "Greenwood Village",
      "Glenwood Springs",
    ],
  },
  {
    name: "Connecticut",
    alias: ["Connecticut"],
    municipalities: [
      "Bridgeport",
      "New Haven",
      "Hartford",
      "Stamford",
      "Waterbury",
      "Norwalk",
      "Danbury",
      "New Britain",
      "West Hartford",
      "Greenwich",
      "Fairfield",
      "Hamden",
      "Bristol",
      "Meriden",
      "Manchester",
      "West Haven",
      "Milford",
      "Stratford",
      "East Hartford",
      "Middletown",
      "Enfield",
      "Wallingford",
      "Southington",
      "Shelton",
      "Groton",
      "Norwich",
      "Torrington",
      "Trumbull",
      "Glastonbury",
      "Naugatuck",
      "Newington",
      "Cheshire",
      "Vernon",
      "Windsor",
      "Branford",
      "New London",
      "New Milford",
      "Westport",
      "Newtown",
      "Farmington",
      "South Windsor",
      "Simsbury",
      "Guilford",
      "Watertown",
      "Darien",
      "Bloomfield",
      "Ridgefield",
      "Wethersfield",
      "North Haven",
      "Monroe",
      "Avon",
      "Wilton",
      "Wolcott",
      "Brookfield",
      "Southbury",
      "East Haven",
      "Easton",
      "Berlin",
      "Bethel",
      "East Lyme",
      "Orange",
      "Plainville",
      "Killingly",
      "New Fairfield",
      "Seymour",
      "Canton",
      "Windsor Locks",
      "Clinton",
      "Colchester",
      "Ellington",
      "Southington",
      "Tolland",
      "Madison",
      "Ansonia",
      "Woodbridge",
      "Granby",
      "Winchester",
      "Rocky Hill",
      "Old Saybrook",
      "Putnam",
      "Thompson",
      "Portland",
      "Prospect",
      "Cromwell",
      "Killingworth",
      "Brooklyn",
      "Haddam",
      "Suffield",
      "Ledyard",
      "East Windsor",
    ],
  },
  {
    name: "Delaware",
    alias: ["Delaware"],
    municipalities: [
      "Wilmington",
      "Dover",
      "Newark",
      "Middletown",
      "Smyrna",
      "Milford",
      "Seaford",
      "Georgetown",
      "Elsmere",
      "New Castle",
      "Millsboro",
      "Laurel",
      "Harrington",
      "Lewes",
      "Camden",
      "Clayton",
      "Felton",
      "Delaware City",
      "Greenwood",
      "Newport",
      "Frankford",
      "Dagsboro",
      "Selbyville",
      "Bridgeville",
      "Ocean View",
      "Frederica",
      "Millville",
      "Rehoboth Beach",
      "Wyoming",
      "Ardencroft",
      "Ardentown",
      "Bellefonte",
      "Bethany Beach",
      "Blades",
      "Bowers",
      "Camden",
      "Cheswold",
      "Clayton",
      "Dagsboro",
      "Delaware City",
      "Delmar",
      "Dewey Beach",
      "Ellendale",
      "Elsmere",
      "Farmington",
      "Felton",
      "Fenwick Island",
      "Frankford",
      "Frederica",
      "Georgetown",
      "Greenwood",
      "Harrington",
      "Hartly",
      "Henlopen Acres",
      "Houston",
      "Kenton",
      "Laurel",
      "Leipsic",
      "Lewes",
      "Little Creek",
      "Magnolia",
      "Middletown",
      "Milford",
      "Millsboro",
      "Millville",
      "Milton",
      "New Castle",
      "Newark",
      "Newport",
      "Ocean View",
      "Odessa",
      "Rehoboth Beach",
      "Seaford",
      "Selbyville",
      "Slaughter Beach",
      "Smyrna",
      "South Bethany",
      "Townsend",
      "Viola",
      "Woodside",
      "Wyoming",
    ],
  },
  {
    name: "Florida",
    alias: ["Florida"],
    municipalities: [
      "Jacksonville",
      "Miami",
      "Tampa",
      "Orlando",
      "St. Petersburg",
      "Hialeah",
      "Tallahassee",
      "Fort Lauderdale",
      "Port St. Lucie",
      "Cape Coral",
      "Pembroke Pines",
      "Hollywood",
      "Miramar",
      "Gainesville",
      "Coral Springs",
      "Miami Gardens",
      "Clearwater",
      "Palm Bay",
      "Pompano Beach",
      "West Palm Beach",
      "Lakeland",
      "Davie",
      "Miami Beach",
      "Sunrise",
      "Plantation",
      "Boca Raton",
      "Deltona",
      "Largo",
      "Deerfield Beach",
      "Palm Coast",
      "Melbourne",
      "Boynton Beach",
      "Lauderhill",
      "Weston",
      "Fort Myers",
      "Kissimmee",
      "Homestead",
      "Tamarac",
      "Delray Beach",
      "Daytona Beach",
      "North Miami",
      "Wellington",
      "North Port",
      "Jupiter",
      "Ocala",
      "Port Orange",
      "Margate",
      "Coconut Creek",
      "Sanford",
      "Sarasota",
      "Doral",
      "Bradenton",
      "Palm Beach Gardens",
      "Pinellas Park",
      "Coral Gables",
      "Bonita Springs",
      "Apopka",
      "Titusville",
      "North Lauderdale",
      "Fort Pierce",
      "Oakland Park",
      "North Miami Beach",
      "Cutler Bay",
      "Altamonte Springs",
      "St. Cloud",
      "Greenacres",
      "Ormond Beach",
      "Ocoee",
      "Dunedin",
      "DeLand",
      "Hallandale Beach",
      "Plant City",
      "Winter Garden",
      "Aventura",
      "Lake Worth",
      "Panama City",
      "Winter Haven",
      "Clermont",
      "Miami Lakes",
      "Rockledge",
      "Casselberry",
      "Key West",
      "Parkland",
      "Winter Springs",
      "Sebastian",
      "Leesburg",
      "Sweetwater",
      "Dania Beach",
      "Edgewater",
      "DeBary",
      "Doral",
      "Bayonet Point",
      "Venice",
    ],
  },
  {
    name: "Georgia",
    alias: ["Georgia"],
    municipalities: [
      "Atlanta",
      "Augusta",
      "Columbus",
      "Savannah",
      "Athens",
      "Sandy Springs",
      "Macon",
      "Roswell",
      "Albany",
      "Johns Creek",
      "Warner Robins",
      "Alpharetta",
      "Marietta",
      "Valdosta",
      "Smyrna",
      "Dunwoody",
      "Rome",
      "East Point",
      "Milton",
      "Gainesville",
      "Newnan",
      "Peachtree Corners",
      "Dalton",
      "Kennesaw",
      "Douglasville",
      "Hinesville",
      "Forest Park",
      "College Park",
      "Lawrenceville",
      "LaGrange",
      "Tifton",
      "Savannah",
      "Decatur",
      "Statesboro",
      "Carrollton",
      "Woodstock",
      "Griffin",
      "Canton",
      "McDonough",
      "Acworth",
      "Cartersville",
      "Pooler",
      "Union City",
      "Sugar Hill",
      "Thomasville",
      "St. Marys",
      "Americus",
      "Suwanee",
      "Dublin",
      "Fayetteville",
      "Calhoun",
      "Moultrie",
      "Bainbridge",
      "Waycross",
      "Winder",
      "Stockbridge",
      "Doraville",
      "Toccoa",
      "Riverdale",
      "Fitzgerald",
      "Covington",
      "Monroe",
      "Garden City",
      "Loganville",
      "Buford",
      "Fairburn",
      "Vidalia",
      "Jesup",
      "Holly Springs",
      "Villa Rica",
      "Cordele",
      "Cedartown",
      "Fort Valley",
      "Jefferson",
      "Hampton",
      "Thomaston",
      "LaFayette",
      "Cusseta-Chattahoochee County",
      "Kingsland",
      "Grovetown",
      "Byron",
      "Dahlonega",
      "Barnesville",
      "West Point",
      "Hapeville",
      "Braselton",
      "Adel",
      "Chickamauga",
    ],
  },
  {
    name: "Hawái",
    alias: ["Hawái"],
    municipalities: [
      "Honolulu",
      "East Honolulu",
      "Pearl City",
      "Hilo",
      "Kailua",
      "Waipahu",
      "Kaneohe",
      "Mililani Town",
      "Kahului",
      "Ewa Gentry",
      "Mililani Mauka",
      "Kihei",
      "Makakilo",
      "Wahiawa",
      "Schofield Barracks",
      "Wailuku",
      "Kapolei",
      "Ewa Beach",
      "Royal Kunia",
      "Halawa",
      "Waimalu",
      "Waianae",
      "Nanakuli",
      "Lahaina",
      "Schofield Barracks",
      "Wailuku",
      "Kapolei",
      "Ewa Beach",
      "Royal Kunia",
      "Halawa",
      "Waimalu",
      "Waianae",
      "Nanakuli",
      "Lahaina",
      "Aiea",
      "Makaha",
      "Waimea",
      "Kalaheo",
      "Hawaiian Paradise Park",
      "Haiku-Pauwela",
      "Kapaa",
      "Pukalani",
      "Holualoa",
      "Makawao",
      "Ewa Villages",
      "Kula",
      "Waikoloa Village",
      "Kahaluu-Keauhou",
      "Laie",
      "Lihue",
      "Waihee-Waiehu",
      "Pahoa",
      "Wailea",
      "Hickam Housing",
      "Keaau",
      "Kahului",
      "Honomu",
      "Waimanalo",
      "Naalehu",
      "Paia",
      "Captain Cook",
      "Kurtistown",
      "Anahola",
      "Hauula",
      "Mountain View",
      "Waikapu",
      "Hana",
      "Fern Acres",
      "Honokaa",
      "Hawi",
      "Kekaha",
      "Waimea",
      "Waikane",
      "Hanalei",
      "Kalaupapa",
      "Pahala",
      "Paukaa",
      "Waiohinu",
      "Honomu",
      "Kealakekua",
      "Maunaloa",
      "Wailua",
      "Wailea",
      "Pepeekeo",
    ],
  },
  {
    name: "Idaho",
    alias: ["Idaho"],
    municipalities: [
      "Boise",
      "Nampa",
      "Meridian",
      "Idaho Falls",
      "Pocatello",
      "Caldwell",
      "Coeur d'Alene",
      "Twin Falls",
      "Lewiston",
      "Post Falls",
      "Rexburg",
      "Moscow",
      "Eagle",
      "Kuna",
      "Ammon",
      "Chubbuck",
      "Hayden",
      "Mountain Home",
      "Blackfoot",
      "Garden City",
      "Jerome",
      "Burley",
      "Hailey",
      "Sandpoint",
      "Rathdrum",
      "Middleton",
      "Emmett",
      "Weiser",
      "Preston",
      "Moscow",
      "Fruitland",
      "Payette",
      "Rupert",
      "Star",
      "Grangeville",
      "Gooding",
      "St. Anthony",
      "Soda Springs",
      "Kimberly",
      "American Falls",
      "McCall",
      "Shelley",
      "Filer",
      "Heyburn",
      "Grangeville",
      "Orofino",
      "Salmon",
      "Pinehurst",
      "Sagle",
      "Kellogg",
      "Wendell",
      "Homedale",
      "Buhl",
      "Montpelier",
      "Ketchum",
      "Malad City",
      "McCammon",
      "Roberts",
      "Hagerman",
      "Pierce",
      "Wilder",
      "Kamiah",
      "Firth",
      "Marsing",
      "New Plymouth",
      "Kooskia",
      "Mullan",
      "Victor",
      "Osburn",
      "Paul",
      "Cottonwood",
      "Driggs",
      "Hansen",
      "Cascade",
      "Grace",
      "Parma",
      "Plummer",
      "Deary",
      "Horseshoe Bend",
      "McCall",
      "Soda Springs",
      "Challis",
      "Troy",
      "Downey",
      "Council",
    ],
  },
  {
    name: "Illinois",
    alias: ["Illinois"],
    municipalities: [
      "Chicago",
      "Aurora",
      "Rockford",
      "Joliet",
      "Naperville",
      "Springfield",
      "Peoria",
      "Elgin",
      "Waukegan",
      "Cicero",
      "Champaign",
      "Bloomington",
      "Arlington Heights",
      "Evanston",
      "Schaumburg",
      "Decatur",
      "Champaign",
      "Bloomington",
      "Arlington Heights",
      "Evanston",
      "Schaumburg",
      "Decatur",
      "Palatine",
      "Skokie",
      "Des Plaines",
      "Orland Park",
      "Tinley Park",
      "Oak Lawn",
      "Berwyn",
      "Mount Prospect",
      "Wheaton",
      "Normal",
      "Hoffman Estates",
      "Oak Park",
      "Downers Grove",
      "Glenview",
      "Belleville",
      "Elmhurst",
      "DeKalb",
      "Moline",
      "Lombard",
      "Buffalo Grove",
      "Urbana",
      "Bartlett",
      "Crystal Lake",
      "Quincy",
      "Streamwood",
      "Carol Stream",
      "Romeoville",
      "Plainfield",
      "Rock Island",
      "Hanover Park",
      "Carpentersville",
      "Wheeling",
      "Park Ridge",
      "Addison",
      "Calumet City",
      "Norridge",
      "Northbrook",
      "Elk Grove Village",
      "St. Charles",
      "Woodridge",
      "Galesburg",
      "Pekin",
      "North Chicago",
      "Elmhurst",
      "Mundelein",
      "Oswego",
      "Chicago Heights",
      "Granite City",
      "Glen Ellyn",
      "Niles",
      "Highland Park",
      "Danville",
      "Gurnee",
      "O'Fallon",
      "Round Lake Beach",
      "East St. Louis",
      "Algonquin",
      "Huntley",
      "New Lenox",
      "Burbank",
      "Urbana",
      "Bartlett",
      "Crystal Lake",
      "Quincy",
      "Streamwood",
      "Carol Stream",
      "Romeoville",
      "Plainfield",
      "Rock Island",
      "Hanover Park",
      "Carpentersville",
      "Wheeling",
      "Park Ridge",
      "Addison",
      "Calumet City",
      "Norridge",
      "Northbrook",
      "Elk Grove Village",
      "St. Charles",
    ],
  },
  {
    name: "Indiana",
    alias: ["Indiana"],
    municipalities: [
      "Indianápolis",
      "Fort Wayne",
      "Evansville",
      "South Bend",
      "Carmel",
      "Bloomington",
      "Fishers",
      "Hammond",
      "Gary",
      "Muncie",
      "Lafayette",
      "Noblesville",
      "Terre Haute",
      "Greenwood",
      "Kokomo",
      "Anderson",
      "Elkhart",
      "Mishawaka",
      "Lawrence",
      "Jeffersonville",
      "Columbus",
      "Portage",
      "Richmond",
      "New Albany",
      "Westfield",
      "Valparaiso",
      "Goshen",
      "Michigan City",
      "West Lafayette",
      "East Chicago",
      "Marion",
      "Hobart",
      "Crown Point",
      "Frankfort",
      "Seymour",
      "Shelbyville",
      "Logansport",
      "Vincennes",
      "New Castle",
      "La Porte",
      "Huntington",
      "Franklin",
      "Greenfield",
      "Highland",
      "Martinsville",
      "Clarksville",
      "Griffith",
      "Yorktown",
      "Schererville",
      "Connersville",
      "Auburn",
      "Plainfield",
      "Merrillville",
      "Lebanon",
      "Warsaw",
      "Decatur",
      "Danville",
      "Crawfordsville",
      "Franklin",
      "Jasper",
      "Madison",
      "Bedford",
      "Chesterton",
      "Avon",
      "Mooresville",
      "Washington",
      "Plymouth",
      "Munster",
      "New Haven",
      "Speedway",
      "Columbia City",
      "Peru",
      "Greensburg",
      "Martinsville",
      "Angola",
      "Princeton",
      "Elwood",
      "Beech Grove",
      "Charlestown",
      "Sellersburg",
      "Zionsville",
      "Warsaw",
      "Bluffton",
      "Converse",
      "Culver",
      "Cumberland",
      "Dyer",
      "Garrett",
      "Griffith",
      "Lowell",
      "Madison",
      "Nappanee",
      "Syracuse",
      "Tell City",
      "Wabash",
      "Winchester",
    ],
  },
  {
    name: "Iowa",
    alias: ["Iowa"],
    municipalities: [
      "Des Moines",
      "Cedar Rapids",
      "Davenport",
      "Sioux City",
      "Iowa City",
      "Waterloo",
      "Ames",
      "West Des Moines",
      "Ankeny",
      "Council Bluffs",
      "Dubuque",
      "Urbandale",
      "Cedar Falls",
      "Marion",
      "Bettendorf",
      "Mason City",
      "Clinton",
      "Marshalltown",
      "Fort Dodge",
      "Burlington",
      "Ottumwa",
      "Muscatine",
      "Coralville",
      "Johnston",
      "Clive",
      "Newton",
      "Indianola",
      "Altoona",
      "Waukee",
      "North Liberty",
      "Boone",
      "Oskaloosa",
      "Spencer",
      "Storm Lake",
      "Fort Madison",
      "Norwalk",
      "Keokuk",
      "Pella",
      "Fairfield",
      "Grinnell",
      "Webster City",
      "Decorah",
      "Clear Lake",
      "Charles City",
      "Creston",
      "Le Mars",
      "Denison",
      "Knoxville",
      "Carroll",
      "Boone",
      "Atlantic",
      "Algona",
      "Shenandoah",
      "Estherville",
      "Grimes",
      "Decorah",
      "Waverly",
      "Washington",
      "Oelwein",
      "Maquoketa",
      "Johnston",
      "Norwalk",
      "Indianola",
      "Keokuk",
      "Manchester",
      "Glenwood",
      "Harlan",
      "Clarinda",
      "Centerville",
      "Osceola",
      "Bondurant",
      "Winterset",
      "Evansdale",
      "Independence",
      "Orange City",
      "Eldridge",
      "Hiawatha",
      "Webster City",
      "Carlisle",
      "Adel",
      "Mount Pleasant",
      "Humboldt",
      "Perry",
      "Nevada",
      "Griswold",
      "Belle Plaine",
      "Hudson",
    ],
  },
  {
    name: "Kansas",
    alias: ["Kansas"],
    municipalities: [
      "Wichita",
      "Overland Park",
      "Kansas City",
      "Topeka",
      "Olathe",
      "Lawrence",
      "Shawnee",
      "Manhattan",
      "Lenexa",
      "Salina",
      "Hutchinson",
      "Leavenworth",
      "Garden City",
      "Emporia",
      "Dodge City",
      "Prairie Village",
      "Hays",
      "Newton",
      "Derby",
      "Liberal",
      "Junction City",
      "Great Bend",
      "McPherson",
      "Ottawa",
      "El Dorado",
      "Winfield",
      "Arkansas City",
      "Andover",
      "Lansing",
      "Merriam",
      "Haysville",
      "Atchison",
      "Parsons",
      "Coffeyville",
      "Mission",
      "Independence",
      "Pittsburg",
      "Leawood",
      "Newton",
      "Gardner",
      "Hays",
      "Wellington",
      "Pratt",
      "Fort Scott",
      "Chanute",
      "McPherson",
      "Bel Aire",
      "Roeland Park",
      "Abilene",
      "Mulvane",
      "Ulysses",
      "Paola",
      "Larned",
      "Colby",
      "Iola",
      "Concordia",
      "Tonganoxie",
      "Goodland",
      "Baldwin City",
      "Holton",
      "Wamego",
      "Goddard",
      "Eudora",
      "Hugoton",
      "Clay Center",
      "Kingman",
      "De Soto",
      "Norton",
      "Lindsborg",
      "Russell",
      "Horton",
      "Phillipsburg",
      "Columbus",
      "Pratt",
      "Baxter Springs",
      "Scott City",
      "Hoisington",
      "Ellsworth",
      "Fredonia",
      "Osage City",
      "Sabetha",
      "Ellinwood",
      "Oakley",
      "Wellsville",
      "Baldwin City",
      "Hoisington",
      "Osawatomie",
      "Oberlin",
      "Perry",
      "Nortonville",
    ],
  },
  {
    name: "Kentucky",
    alias: ["Kentucky", "KY"],
    municipalities: [
      "Louisville",
      "Lexington",
      "Bowling Green",
      "Owensboro",
      "Covington",
      "Hopkinsville",
      "Frankfort",
      "Henderson",
      "Richmond",
      "Jeffersontown",
      "Paducah",
      "Pleasure Ridge Park",
      "Florence",
      "Elizabethtown",
      "Valley Station",
      "Ashland",
      "Georgetown",
      "Nicholasville",
      "Jeffersonville",
      "Radcliff",
      "Fern Creek",
      "Madisonville",
      "Newburg",
      "Erlanger",
      "Winchester",
      "Glasgow",
      "St. Matthews",
      "Murray",
      "Okolona",
      "Newport",
      "Danville",
      "Shively",
      "Highview",
      "Independence",
      "Fort Campbell North",
      "Fort Thomas",
      "Middletown",
      "Shelbyville",
      "Bardstown",
      "Lyndon",
      "Mount Washington",
      "Lawrenceburg",
      "Campbellsville",
      "Mayfield",
      "Maysville",
      "Edgewood",
      "Paris",
      "Fort Knox",
      "Radcliff",
      "Hillview",
      "Alexandria",
      "Elsmere",
      "Franklin",
      "Harrodsburg",
      "Fort Mitchell",
      "London",
      "Versailles",
      "La Grange",
      "Oakbrook",
      "Flatwoods",
      "Villa Hills",
      "Mount Sterling",
      "Fort Wright",
      "Hazard",
      "Morehead",
      "Pikeville",
      "Leitchfield",
      "Princeton",
      "Central City",
      "Cynthiana",
      "Monticello",
      "Wilmore",
      "Bellevue",
      "Greenville",
      "Marshall",
      "Lebanon",
      "Williamsburg",
      "Carrollton",
      "Williamstown",
      "Cold Spring",
      "Middlesborough",
      "Crestview Hills",
      "Scottsville",
      "Union",
      "Hartford",
      "Campbellsville",
      "Dawson Springs",
    ],
  },
  {
    name: "Luisiana",
    alias: ["Luisiana", "LA"],
    municipalities: [
      "New Orleans",
      "Baton Rouge",
      "Shreveport",
      "Lafayette",
      "Lake Charles",
      "Kenner",
      "Bossier City",
      "Monroe",
      "Alexandria",
      "Houma",
      "New Iberia",
      "Slidell",
      "Central",
      "Ruston",
      "Sulphur",
      "Hammond",
      "Natchitoches",
      "Gretna",
      "Opelousas",
      "Zachary",
      "Thibodaux",
      "Pineville",
      "Youngsville",
      "Baker",
      "Crowley",
      "West Monroe",
      "Minden",
      "Morgan City",
      "Abbeville",
      "Eunice",
      "Jennings",
      "Denham Springs",
      "Harahan",
      "Carencro",
      "Covington",
      "Scott",
      "Breaux Bridge",
      "Donaldsonville",
      "Rayne",
      "Oakdale",
      "Mandeville",
      "Bogalusa",
      "Franklin",
      "DeRidder",
      "Bastrop",
      "Moss Bluff",
      "Broussard",
      "Claiborne",
      "Gonzales",
      "Westwego",
      "Luling",
      "Estelle",
      "Eunice",
      "Minden",
      "Baker",
      "St. Gabriel",
      "Scott",
      "Sulphur",
      "Donaldsonville",
      "Harahan",
      "West Monroe",
      "Prairieville",
      "Laplace",
      "Woodmere",
      "Ponchatoula",
      "Zachary",
      "Moss Bluff",
      "Bogalusa",
      "St. Martinville",
      "Patterson",
      "Winnfield",
      "Mamou",
      "Marksville",
      "Brownsville",
      "Delhi",
      "Ville Platte",
      "Westlake",
      "Jonesboro",
      "Church Point",
      "Rayne",
      "Baldwin",
      "Lake Providence",
      "Richwood",
      "Bunkie",
      "Benton",
      "Winnfield",
      "St. Francisville",
      "Winnsboro",
      "Jackson",
      "Jonesville",
      "Erath",
    ],
  },
  {
    name: "Maine",
    alias: ["Maine", "ME"],
    municipalities: [
      "Portland",
      "Lewiston",
      "Bangor",
      "South Portland",
      "Auburn",
      "Biddeford",
      "Sanford",
      "Augusta",
      "Scarborough",
      "Saco",
      "Westbrook",
      "Waterville",
      "Brunswick",
      "Ogunquit",
      "York",
      "Kennebunk",
      "Freeport",
      "Gorham",
      "Windham",
      "Bath",
      "Falmouth",
      "Old Orchard Beach",
      "Wells",
      "Standish",
      "Yarmouth",
      "Gray",
      "Cape Elizabeth",
      "Lisbon",
      "Topsham",
      "Presque Isle",
      "Brewer",
      "Orono",
      "Old Town",
      "Rockland",
      "Belfast",
      "Skowhegan",
      "Ellsworth",
      "Caribou",
      "Gardiner",
      "Winslow",
      "Camden",
      "Houlton",
      "Kittery",
      "Millinocket",
      "Berwick",
      "South Berwick",
      "Waldoboro",
      "Somersworth",
      "Rockport",
      "Buxton",
      "North Berwick",
      "Winthrop",
      "Poland",
      "Bridgton",
      "Freeport",
      "Harpswell",
      "Raymond",
      "Rumford",
      "Oakland",
      "Hampden",
      "Norway",
      "Dexter",
      "Pittsfield",
      "Damariscotta",
      "Cumberland",
      "Searsport",
      "Turner",
      "Harrison",
      "Bridgton",
      "Boothbay Harbor",
      "Cape Neddick",
      "North Windham",
      "Cape Elizabeth",
      "Lincolnville",
      "Cumberland Center",
      "Farmington",
      "South Paris",
    ],
  },
  {
    name: "Maryland",
    alias: ["Maryland", "MD"],
    municipalities: [
      "Baltimore",
      "Columbia",
      "Germantown",
      "Silver Spring",
      "Waldorf",
      "Glen Burnie",
      "Ellicott City",
      "Frederick",
      "Dundalk",
      "Rockville",
      "Bethesda",
      "Gaithersburg",
      "Towson",
      "Bowie",
      "Aspen Hill",
      "Wheaton",
      "Bel Air South",
      "Potomac",
      "Severn",
      "North Bethesda",
      "Catonsville",
      "Hagerstown",
      "Annapolis",
      "Odenton",
      "Clinton",
      "Essex",
      "Chillum",
      "Germantown",
      "Olney",
      "Randallstown",
      "Montgomery Village",
      "Oxon Hill",
      "South Laurel",
      "Severna Park",
      "St. Charles",
      "Pikesville",
      "North Potomac",
      "Elkton",
      "Parkville",
      "Wheaton-Glenmont",
      "Bel Air North",
      "Carney",
      "Landover",
      "Lochearn",
      "Middle River",
      "Milford Mill",
      "Woodlawn",
      "Ballenger Creek",
      "Arnold",
      "Scaggsville",
      "Suitland",
      "Reisterstown",
      "Camp Springs",
      "Laurel",
      "Greenbelt",
      "Cumberland",
      "Westminster",
      "St. Charles",
      "Salisbury",
      "Langley Park",
      "North Bethesda",
      "Takoma Park",
      "Fairland",
      "White Marsh",
      "Timonium",
      "Odenton",
      "Perry Hall",
      "Pikesville",
      "Calverton",
      "Beltsville",
      "Brunswick",
      "Easton",
      "Hyattsville",
      "Glassmanor",
      "Havre de Grace",
      "New Carrollton",
      "Cambridge",
      "Edgewood",
      "Fort Washington",
      "Lexington Park",
      "Adelphi",
      "Cumberland",
      "Clinton",
      "Rosaryville",
      "Greenbelt",
      "Seabrook",
    ],
  },
  {
    name: "Massachusetts",
    alias: ["Massachusetts", "MA"],
    municipalities: [
      "Boston",
      "Worcester",
      "Springfield",
      "Lowell",
      "Cambridge",
      "New Bedford",
      "Brockton",
      "Quincy",
      "Lynn",
      "Fall River",
      "Newton",
      "Lawrence",
      "Somerville",
      "Haverhill",
      "Waltham",
      "Malden",
      "Medford",
      "Taunton",
      "Chicopee",
      "Weymouth",
      "Revere",
      "Peabody",
      "Methuen",
      "Barnstable Town",
      "Pittsfield",
      "Attleboro",
      "Everett",
      "Salem",
      "Westfield",
      "Leominster",
      "Fitchburg",
      "Holyoke",
      "Beverly",
      "Marlborough",
      "Woburn",
      "Chelsea",
      "Braintree",
      "Watertown",
      "Franklin",
      "Gloucester",
      "Hingham",
      "Lexington",
      "Agawam",
      "Northampton",
      "Milton",
      "Randolph",
      "Needham",
      "Dracut",
      "Gardner",
      "Norwood",
      "Wellesley",
      "Rockland",
      "Newburyport",
      "Saugus",
      "Easthampton",
      "Peabody",
      "Milton",
      "West Springfield",
      "Hudson",
      "Wakefield",
      "Marblehead",
      "Southbridge",
      "Amesbury",
      "Belmont",
      "North Attleborough",
      "Melrose",
      "Natick",
      "Sandwich",
      "Wilmington",
      "Falmouth",
      "Milford",
      "Oxford",
      "Sudbury",
      "Agawam",
      "Greenfield",
      "Easton",
      "Winchester",
      "Holden",
      "Spencer",
      "Northbridge",
      "Webster",
      "Blackstone",
      "Marlborough",
      "Ludlow",
      "Grafton",
    ],
  },
  {
    name: "Míchigan",
    alias: ["Míchigan", "MI"],
    municipalities: [
      "Detroit",
      "Grand Rapids",
      "Warren",
      "Sterling Heights",
      "Lansing",
      "Ann Arbor",
      "Flint",
      "Dearborn",
      "Livonia",
      "Westland",
      "Troy",
      "Farmington Hills",
      "Kalamazoo",
      "Wyoming",
      "Southfield",
      "Rochester Hills",
      "Taylor",
      "Pontiac",
      "St. Clair Shores",
      "Royal Oak",
      "Novi",
      "Dearborn Heights",
      "Battle Creek",
      "Saginaw",
      "Kentwood",
      "East Lansing",
      "Roseville",
      "Portage",
      "Midland",
      "Lincoln Park",
      "Muskegon",
      "Holland",
      "Bay City",
      "Jackson",
      "Eastpointe",
      "Madison Heights",
      "Oak Park",
      "Port Huron",
      "Southgate",
      "Birmingham",
      "Ypsilanti",
      "Plymouth",
      "Saginaw",
      "East Lansing",
      "Marquette",
      "West Bloomfield Township",
      "Redford",
      "Mount Pleasant",
      "Roseville",
      "Wyandotte",
      "Kentwood",
      "Eastpointe",
      "Wylie",
      "Burton",
      "Garden City",
      "Inkster",
      "Oak Park",
      "Pontiac",
      "Walker",
      "Norton Shores",
      "Bay City",
      "Romulus",
      "Hamtramck",
      "Auburn Hills",
      "Adrian",
      "Ferndale",
      "Monroe",
      "Marysville",
      "Birmingham",
      "Forest Hills",
      "Hazel Park",
      "Grandville",
      "Traverse City",
      "Lapeer",
      "Owosso",
      "Holly",
      "Mount Clemens",
      "Alpena",
      "Coldwater",
      "Big Rapids",
      "Three Rivers",
      "Sturgis",
      "Muskegon Heights",
      "Cadillac",
    ],
  },
  {
    name: "Minnesota",
    alias: ["Minnesota", "MN"],
    municipalities: [
      "Minneapolis",
      "St. Paul",
      "Rochester",
      "Duluth",
      "Bloomington",
      "Brooklyn Park",
      "Plymouth",
      "St. Cloud",
      "Woodbury",
      "Eagan",
      "Maple Grove",
      "Coon Rapids",
      "Eden Prairie",
      "Burnsville",
      "Blaine",
      "Lakeville",
      "Minnetonka",
      "Apple Valley",
      "Edina",
      "St. Louis Park",
      "Mankato",
      "Maplewood",
      "Moorhead",
      "Shakopee",
      "Richfield",
      "Cottage Grove",
      "Inver Grove Heights",
      "Roseville",
      "Andover",
      "Brooklyn Center",
      "Savage",
      "Oakdale",
      "Winona",
      "Fridley",
      "Shoreview",
      "Owatonna",
      "Chaska",
      "White Bear Lake",
      "Prior Lake",
      "Ramsey",
      "Champlin",
      "Chanhassen",
      "Elk River",
      "Faribault",
      "Crystal",
      "New Brighton",
      "Hastings",
      "Golden Valley",
      "Lino Lakes",
      "New Hope",
      "Northfield",
      "West St. Paul",
      "Columbia Heights",
      "Willmar",
      "Forest Lake",
      "Stillwater",
      "Hopkins",
      "Albert Lea",
      "Anoka",
      "Red Wing",
      "Hibbing",
      "Hutchinson",
      "Eagan",
      "Marshall",
      "Rosemount",
      "St. Michael",
      "Ham Lake",
      "Otsego",
      "Buffalo",
      "Sartell",
      "Bemidji",
      "North Mankato",
      "Alexandria",
      "Big Lake",
      "Brainerd",
      "Fairmont",
      "New Ulm",
      "Monticello",
      "Fergus Falls",
    ],
  },
  {
    name: "Misisipi",
    alias: ["Misisipi", "MS"],
    municipalities: [
      "Jackson",
      "Gulfport",
      "Southaven",
      "Hattiesburg",
      "Biloxi",
      "Meridian",
      "Tupelo",
      "Greenville",
      "Olive Branch",
      "Horn Lake",
      "Clinton",
      "Pearl",
      "Madison",
      "Ridgeland",
      "Starkville",
      "Vicksburg",
      "Columbus",
      "Pascagoula",
      "Brandon",
      "Oxford",
      "Laurel",
      "Ocean Springs",
      "Natchez",
      "Greenwood",
      "Long Beach",
      "Corinth",
      "Moss Point",
      "Grenada",
      "McComb",
      "Brookhaven",
      "Canton",
      "Clarksdale",
      "Hernando",
      "Saltillo",
      "Holly Springs",
      "Horn Lake",
      "Senatobia",
      "Indianola",
      "Philadelphia",
      "Batesville",
      "Bay St. Louis",
      "Gautier",
      "New Albany",
      "Picayune",
      "West Point",
      "Flowood",
      "Booneville",
      "Cleveland",
      "Amory",
      "Byram",
      "Kosciusko",
      "Yazoo City",
      "Pontotoc",
      "Richland",
      "West Hattiesburg",
      "Louisville",
      "Columbia",
      "Pass Christian",
      "Aberdeen",
      "Waynesboro",
      "Crystal Springs",
      "Ripley",
      "Carthage",
      "Ellisville",
      "Waveland",
      "Hazlehurst",
      "Canton",
      "Magee",
      "Newton",
      "Fulton",
      "Morton",
      "Houston",
      "Leland",
      "Kosciusko",
      "Verona",
      "Pearl River",
      "Farmington",
      "Durant",
      "Collins",
      "Macomb",
      "Belzoni",
      "Itta Bena",
      "D'iberville",
      "Shelby",
      "Bruce",
    ],
  },
  {
    name: "Misuri",
    alias: ["Misuri", "MO"],
    municipalities: [
      "Kansas City",
      "St. Louis",
      "Springfield",
      "Columbia",
      "Independence",
      "Lee's Summit",
      "O'Fallon",
      "St. Joseph",
      "St. Charles",
      "St. Peters",
      "Blue Springs",
      "Florissant",
      "Joplin",
      "Chesterfield",
      "Jefferson City",
      "Cape Girardeau",
      "Wildwood",
      "University City",
      "Ballwin",
      "Raytown",
      "Liberty",
      "Wentzville",
      "Maryland Heights",
      "Hazelwood",
      "Gladstone",
      "Grandview",
      "Belton",
      "Webster Groves",
      "Sedalia",
      "Ferguson",
      "Arnold",
      "Nixa",
      "Raymore",
      "Warrensburg",
      "Rolla",
      "Ozark",
      "Creve Coeur",
      "Farmington",
      "Manchester",
      "Hannibal",
      "Kirksville",
      "Poplar Bluff",
      "Clayton",
      "Sikeston",
      "Overland",
      "Lebanon",
      "Jackson",
      "Carthage",
      "Republic",
      "Fulton",
      "St. Ann",
      "Marshall",
      "West Plains",
      "Moberly",
      "Neosho",
      "Crestwood",
      "Mexico",
      "Bridgeton",
      "Union",
      "Maryville",
      "Excelsior Springs",
      "Kearney",
      "Town and Country",
      "Festus",
      "Webb City",
      "Bolivar",
      "Warrenton",
      "Kennett",
      "St. John",
      "Marshfield",
      "Bellefontaine Neighbors",
      "Troy",
      "Branson",
      "Eureka",
      "Vandalia",
      "Cameron",
    ],
  },
  {
    name: "Montana",
    alias: ["Montana", "MT"],
    municipalities: [
      "Billings",
      "Missoula",
      "Great Falls",
      "Bozeman",
      "Butte",
      "Helena",
      "Kalispell",
      "Havre",
      "Anaconda",
      "Miles City",
      "Belgrade",
      "Livingston",
      "Laurel",
      "Whitefish",
      "Lewistown",
      "Sidney",
      "Glendive",
      "Columbia Falls",
      "Polson",
      "Hamilton",
      "Dillon",
      "Hardin",
      "Shelby",
      "Cut Bank",
      "Glasgow",
      "Deer Lodge",
      "Lame Deer",
      "Wolf Point",
      "Conrad",
      "Red Lodge",
      "Colstrip",
      "Malta",
      "Browning",
      "Three Forks",
      "Stevensville",
      "Townsend",
      "Lolo",
      "Roundup",
      "Big Timber",
      "Fort Benton",
      "Thompson Falls",
      "West Yellowstone",
      "Choteau",
      "Plains",
      "Superior",
      "Whitehall",
      "Eureka",
      "Troy",
      "Poplar",
      "Scobey",
      "Harlowton",
      "Chester",
      "Fairfield",
      "Ennis",
      "Forsyth",
      "Boulder",
      "Baker",
      "St. Ignatius",
      "Philipsburg",
      "Drummond",
      "Sheridan",
      "Culbertson",
      "Cascade",
    ],
  },
  {
    name: "Nebraska",
    alias: ["Nebraska", "NE"],
    municipalities: [
      "Omaha",
      "Lincoln",
      "Bellevue",
      "Grand Island",
      "Kearney",
      "Fremont",
      "Hastings",
      "North Platte",
      "Norfolk",
      "Columbus",
      "Papillion",
      "La Vista",
      "Scottsbluff",
      "South Sioux City",
      "Beatrice",
      "Lexington",
      "Gering",
      "Alliance",
      "Blair",
      "York",
      "McCook",
      "Nebraska City",
      "Seward",
      "Crete",
      "Sidney",
      "Plattsmouth",
      "Schuyler",
      "Ralston",
      "Chadron",
      "Wayne",
      "Holdrege",
      "Ogallala",
      "Wahoo",
      "Aurora",
      "Gretna",
      "Cozad",
      "Fairbury",
      "O'Neill",
      "Gothenburg",
      "Broken Bow",
      "Auburn",
      "Central City",
      "West Point",
      "Waverly",
      "Ashland",
      "Valentine",
      "Kimball",
      "Ord",
      "Syracuse",
      "Milford",
      "Plattsmouth",
      "David City",
      "Pierce",
      "Albion",
      "Atkinson",
      "Tekamah",
      "Mitchell",
      "Gordon",
    ],
  },
  {
    name: "Nevada",
    alias: ["Nevada", "NV"],
    municipalities: [
      "Las Vegas",
      "Henderson",
      "Reno",
      "North Las Vegas",
      "Sparks",
      "Carson City",
      "Elko",
      "Mesquite",
      "Boulder City",
      "Fernley",
      "Fallon",
      "Winnemucca",
      "West Wendover",
      "Ely",
      "Yerington",
      "Lovelock",
      "Wells",
      "Caliente",
      "Hawthorne",
      "Carlin",
      "Winnemucca",
      "Lovelock",
      "Elko",
      "Wells",
      "Fernley",
      "Winnemucca",
      "Reno",
      "West Wendover",
      "Ely",
      "Carlin",
      "Lovelock",
      "Wells",
      "Caliente",
      "Hawthorne",
      "Carlin",
      "Winnemucca",
      "Lovelock",
      "Elko",
      "Wells",
      "Fernley",
      "Winnemucca",
      "Reno",
      "West Wendover",
      "Ely",
      "Carlin",
      "Lovelock",
      "Wells",
      "Caliente",
      "Hawthorne",
      "Carlin",
      "Winnemucca",
      "Lovelock",
      "Elko",
      "Wells",
      "Fernley",
      "Winnemucca",
      "Reno",
      "West Wendover",
      "Ely",
      "Carlin",
      "Lovelock",
      "Wells",
      "Caliente",
      "Hawthorne",
    ],
  },
  {
    name: "New Hampshire",
    alias: ["New Hampshire", "NH"],
    municipalities: [
      "Manchester",
      "Nashua",
      "Concord",
      "Derry",
      "Dover",
      "Rochester",
      "Salem",
      "Merrimack",
      "Hudson",
      "Londonderry",
      "Keene",
      "Bedford",
      "Portsmouth",
      "Goffstown",
      "Laconia",
      "Windham",
      "Claremont",
      "Lebanon",
      "Pelham",
      "Somersworth",
      "Amherst",
      "Hooksett",
      "Exeter",
      "Durham",
      "Raymond",
      "Hampton",
      "Milford",
      "Hanover",
      "Hollis",
      "Litchfield",
      "Plaistow",
      "Bedford",
      "Newmarket",
      "Atkinson",
      "Gilford",
      "Bow",
      "Hampstead",
      "Epping",
      "Barrington",
      "Wolfeboro",
      "Franklin",
      "Newport",
      "Conway",
      "Sandown",
      "Belmont",
      "Seabrook",
      "Pembroke",
      "Farmington",
      "Kingston",
      "Berlin",
      "Gilsum",
      "Groveton",
      "Lancaster",
      "Lisbon",
      "Milan",
      "Whitefield",
    ],
  },
  {
    name: "New Jersey",
    alias: ["New Jersey", "NJ"],
    municipalities: [
      "Newark",
      "Jersey City",
      "Paterson",
      "Elizabeth",
      "Edison",
      "Woodbridge",
      "Lakewood",
      "Toms River",
      "Hamilton",
      "Trenton",
      "Clifton",
      "Camden",
      "Brick",
      "Cherry Hill",
      "Passaic",
      "Middletown",
      "Union City",
      "Old Bridge",
      "Gloucester Township",
      "Bayonne",
      "Franklin",
      "East Orange",
      "Vineland",
      "North Bergen",
      "Union",
      "Montclair",
      "Hoboken",
      "West New York",
      "Howell",
      "Piscataway",
      "Parsippany-Troy Hills",
      "Perth Amboy",
      "Hoboken",
      "Linden",
      "Passaic",
      "Paterson",
      "Plainfield",
      "Sayreville",
      "Hackensack",
      "Atlantic City",
      "New Brunswick",
      "Bloomfield",
      "Kearny",
      "Englewood",
      "East Brunswick",
      "Westfield",
      "Teaneck",
      "Montclair",
      "Hoboken",
      "West New York",
      "Hackensack",
      "Englewood",
      "Hoboken",
      "West New York",
      "Englewood",
      "Hoboken",
      "West New York",
      "Englewood",
      "Hoboken",
    ],
  },
  {
    name: "Nuevo México",
    alias: ["Nuevo México", "NM"],
    municipalities: [
      "Albuquerque",
      "Las Cruces",
      "Rio Rancho",
      "Santa Fe",
      "Roswell",
      "Farmington",
      "South Valley",
      "Clovis",
      "Hobbs",
      "Alamogordo",
      "Carlsbad",
      "Gallup",
      "Los Lunas",
      "Deming",
      "Sunland Park",
      "Las Vegas",
      "Portales",
      "Los Alamos",
      "North Valley",
      "Artesia",
      "Silver City",
      "Lovington",
      "Espanola",
      "Ruidoso",
      "Socorro",
      "Bernalillo",
      "Bloomfield",
      "Aztec",
      "Grants",
      "Taos",
      "Truth or Consequences",
      "Corrales",
      "Shiprock",
      "Raton",
      "White Rock",
      "Anthony",
      "Zuni Pueblo",
      "Kirtland",
      "Los Ranchos de Albuquerque",
      "Santa Teresa",
      "Tucumcari",
      "Placitas",
      "Belen",
      "Rio Communities",
      "Bosque Farms",
      "Peralta",
      "Edgewood",
      "Ranchos de Taos",
      "Lordsburg",
      "El Cerro-Monterey Park",
      "Paradise Hills",
      "Eldorado at Santa Fe",
      "Los Chaves",
      "Vado",
      "Chimayo",
      "Jarales",
      "San Felipe Pueblo",
      "Moriarty",
      "Questa",
      "Carnuel",
      "Hatch",
    ],
  },
  {
    name: "New York",
    alias: ["New York", "NY"],
    municipalities: [
      "New York City",
      "Buffalo",
      "Rochester",
      "Yonkers",
      "Syracuse",
      "Albany",
      "New Rochelle",
      "Mount Vernon",
      "Schenectady",
      "Utica",
      "White Plains",
      "Hempstead",
      "Troy",
      "Niagara Falls",
      "Binghamton",
      "Freeport",
      "Valley Stream",
      "Long Beach",
      "Rome",
      "North Tonawanda",
      "Ithaca",
      "Poughkeepsie",
      "Jamestown",
      "Port Chester",
      "Harrison",
      "Newburgh",
      "Elmira",
      "Saratoga Springs",
      "Middletown",
      "Glen Cove",
      "Lindenhurst",
      "Auburn",
      "Watertown",
      "Ossining",
      "Rockville Centre",
      "Peekskill",
      "Kingston",
      "Kiryas Joel",
      "Garden City",
      "Lockport",
      "Plattsburgh",
      "Lynbrook",
      "Mamaroneck",
      "Mineola",
      "Cortland",
      "Amsterdam",
      "Lackawanna",
      "Scarsdale",
      "Batavia",
      "Oswego",
      "Ilion",
      "Cohoes",
      "Beacon",
      "Ogdensburg",
      "Glen Cove",
      "Oneonta",
      "Geneva",
      "Dunkirk",
      "Fulton",
      "Woodbury",
    ],
  },
  {
    name: "Carolina del Norte",
    alias: ["Carolina del Norte", "NC"],
    municipalities: [
      "Charlotte",
      "Raleigh",
      "Greensboro",
      "Durham",
      "Winston-Salem",
      "Fayetteville",
      "Cary",
      "Wilmington",
      "High Point",
      "Greenville",
      "Asheville",
      "Concord",
      "Gastonia",
      "Jacksonville",
      "Chapel Hill",
      "Rocky Mount",
      "Burlington",
      "Huntersville",
      "Kannapolis",
      "Apex",
      "Hickory",
      "Goldsboro",
      "Indian Trail",
      "Mooresville",
      "Wake Forest",
      "Monroe",
      "Salisbury",
      "New Bern",
      "Matthews",
      "Sanford",
      "Garner",
      "Thomasville",
      "Statesville",
      "Asheboro",
      "Cornelius",
      "Kernersville",
      "Wilson",
      "Fuquay-Varina",
      "Mint Hill",
      "Morrisville",
      "Lumberton",
      "Boone",
      "Clayton",
      "Elizabeth City",
      "Havelock",
      "Shelby",
      "Lexington",
      "Laurinburg",
      "Morganton",
      "Kinston",
      "Knightdale",
      "Indian Trail",
      "Lumberton",
      "Goldsboro",
      "Kannapolis",
      "Lenoir",
      "Matthews",
      "Holly Springs",
      "Reidsville",
      "Graham",
      "Mint Hill",
      "Henderson",
      "Eden",
      "Roanoke Rapids",
      "Mount Holly",
      "Pinehurst",
    ],
  },
  {
    name: "Dakota del Norte",
    alias: ["Dakota del Norte", "ND"],
    municipalities: [
      "Fargo",
      "Bismarck",
      "Grand Forks",
      "Minot",
      "West Fargo",
      "Williston",
      "Mandan",
      "Dickinson",
      "Jamestown",
      "Wahpeton",
      "Devils Lake",
      "Valley City",
      "Minot AFB",
      "Grafton",
      "Beulah",
      "Rugby",
      "Stanley",
      "Horace",
      "Lincoln",
      "Hazen",
      "New Town",
      "Mayville",
      "Belcourt",
      "Bottineau",
      "Lisbon",
      "Carrington",
      "Langdon",
      "Cavalier",
      "Oakes",
      "Park River",
      "Harvey",
      "Hillsboro",
      "Tioga",
      "Rugby",
      "Horace",
      "Rolla",
      "Ellendale",
      "Velva",
      "Larimore",
      "Belfield",
      "Kenmare",
      "Cando",
      "Linton",
      "Beach",
      "Hettinger",
      "Northwood",
      "New Rockford",
      "Garrison",
      "Turtle Lake",
      "Portland",
    ],
  },
  {
    name: "Ohio",
    alias: ["Ohio", "OH"],
    municipalities: [
      "Columbus",
      "Cleveland",
      "Cincinnati",
      "Toledo",
      "Akron",
      "Dayton",
      "Parma",
      "Canton",
      "Youngstown",
      "Lorain",
      "Hamilton",
      "Springfield",
      "Kettering",
      "Elyria",
      "Lakewood",
      "Cuyahoga Falls",
      "Middletown",
      "Newark",
      "Euclid",
      "Mansfield",
      "Cleveland Heights",
      "Warren",
      "Strongsville",
      "Fairfield",
      "Beavercreek",
      "Dublin",
      "Lima",
      "Huber Heights",
      "Westerville",
      "Lancaster",
      "Marion",
      "Grove City",
      "Reynoldsburg",
      "Delaware",
      "Brunswick",
      "Stow",
      "Upper Arlington",
      "Gahanna",
      "North Ridgeville",
      "Massillon",
      "Westlake",
      "Fairborn",
      "Findlay",
      "Mason",
      "North Olmsted",
      "Bowling Green",
      "North Royalton",
      "Kent",
      "Garfield Heights",
      "Shaker Heights",
      "Hilliard",
      "Wooster",
      "Medina",
      "Barberton",
      "Xenia",
      "Green",
      "Zanesville",
    ],
  },
  {
    name: "Oklahoma",
    alias: ["Oklahoma", "OK"],
    municipalities: [
      "Oklahoma City",
      "Tulsa",
      "Norman",
      "Broken Arrow",
      "Lawton",
      "Edmond",
      "Moore",
      "Midwest City",
      "Enid",
      "Stillwater",
      "Muskogee",
      "Bartlesville",
      "Owasso",
      "Shawnee",
      "Yukon",
      "Bixby",
      "Ardmore",
      "Ponca City",
      "Duncan",
      "Del City",
      "Jenks",
      "Sapulpa",
      "Mustang",
      "Sand Springs",
      "Bethany",
      "Altus",
      "Claremore",
      "El Reno",
      "McAlester",
      "Ada",
      "Durant",
      "Tahlequah",
      "Chickasha",
      "Miami",
      "Glenpool",
      "Woodward",
      "Choctaw",
      "Okmulgee",
      "Elk City",
      "Guthrie",
      "Guymon",
      "Warr Acres",
      "Newcastle",
      "Coweta",
      "The Village",
      "Pryor Creek",
      "Weatherford",
      "Cushing",
    ],
  },
  {
    name: "Oregón",
    alias: ["Oregón", "OR"],
    municipalities: [
      "Portland",
      "Eugene",
      "Salem",
      "Gresham",
      "Hillsboro",
      "Beaverton",
      "Bend",
      "Medford",
      "Springfield",
      "Corvallis",
      "Albany",
      "Tigard",
      "Lake Oswego",
      "Keizer",
      "Grants Pass",
      "Oregon City",
      "McMinnville",
      "Redmond",
      "Tualatin",
      "West Linn",
      "Woodburn",
      "Forest Grove",
      "Newberg",
      "Wilsonville",
      "Roseburg",
      "Klamath Falls",
      "Ashland",
      "Milwaukie",
      "Happy Valley",
      "Sherwood",
      "Central Point",
      "Canby",
      "Hermiston",
      "Lebanon",
      "Pendleton",
      "Dallas",
      "Coos Bay",
      "Troutdale",
      "The Dalles",
      "St. Helens",
      "La Grande",
      "Cornelius",
      "Gladstone",
      "Sandy",
      "Ontario",
      "Newport",
      "Silverton",
      "Cottage Grove",
      "Prineville",
      "Fairview",
    ],
  },
  {
    name: "Pensilvania",
    alias: ["Pensilvania", "PA"],
    municipalities: [
      "Filadelfia",
      "Pittsburgh",
      "Allentown",
      "Erie",
      "Reading",
      "Scranton",
      "Bethlehem",
      "Lancaster",
      "Harrisburg",
      "York",
      "Altoona",
      "Wilkes-Barre",
      "Chester",
      "Williamsport",
      "Easton",
      "Lebanon",
      "Hazleton",
      "New Castle",
      "Johnstown",
      "McKeesport",
      "Hermitage",
      "Pottstown",
      "Sharon",
      "Williamsport",
      "West Chester",
      "Bloomsburg",
      "Norristown",
      "Greensburg",
      "Cranberry Township",
      "Warren",
      "New Kensington",
      "Phoenixville",
      "Butler",
      "Meadville",
      "Pottsville",
      "King of Prussia",
      "Greenville",
      "Corry",
      "Beaver Falls",
      "Bradford",
      "Dunmore",
      "Oil City",
      "Uniontown",
      "Nanticoke",
      "Carbondale",
      "Jeannette",
      "Aliquippa",
      "Lower Burrell",
      "Jeannette",
      "Columbia",
      "Darby",
    ],
  },
  {
    name: "Rhode Island",
    alias: ["Rhode Island", "RI"],
    municipalities: [
      "Providence",
      "Warwick",
      "Cranston",
      "Pawtucket",
      "East Providence",
      "Woonsocket",
      "Newport",
      "Central Falls",
      "North Providence",
      "West Warwick",
      "Bristol",
      "Barrington",
      "Cumberland",
      "Coventry",
      "North Kingstown",
      "South Kingstown",
      "Johnston",
      "Smithfield",
      "Lincoln",
      "East Greenwich",
      "Portsmouth",
      "Middletown",
      "Narragansett",
      "Tiverton",
      "North Smithfield",
      "Westerly",
      "Wakefield-Peacedale",
      "Valley Falls",
      "Newport East",
      "North Scituate",
      "Pascoag",
      "Harrisville",
      "Chepachet",
      "Hope Valley",
      "Ashaway",
      "Charlestown",
      "Bradford",
      "Foster Center",
      "Kingston",
      "Melville",
      "Carolina",
      "Quonochontaug",
      "Weekapaug",
      "Misquamicut",
    ],
  },
  {
    name: "Carolina del Sur",
    alias: ["Carolina del Sur", "SC"],
    municipalities: [
      "Columbia",
      "Charleston",
      "North Charleston",
      "Mount Pleasant",
      "Rock Hill",
      "Greenville",
      "Summerville",
      "Sumter",
      "Hilton Head Island",
      "Spartanburg",
      "Florence",
      "Goose Creek",
      "Aiken",
      "Myrtle Beach",
      "Anderson",
      "Greer",
      "Greenwood",
      "Mauldin",
      "North Augusta",
      "Easley",
      "Simpsonville",
      "Hanahan",
      "Lexington",
      "Conway",
      "West Columbia",
      "North Myrtle Beach",
      "Clemson",
      "Orangeburg",
      "Cayce",
      "Bluffton",
      "Beaufort",
      "Irmo",
      "Port Royal",
      "Newberry",
      "Gaffney",
      "Lancaster",
      "Clinton",
      "Union",
      "Seneca",
      "Moncks Corner",
      "Camden",
      "York",
      "Bennettsville",
      "Laurens",
      "Fountain Inn",
      "Hartsville",
      "Marion",
      "Lake City",
      "Dillon",
      "Chester",
      "Walterboro",
      "Batesburg-Leesville",
      "Abbeville",
      "Cheraw",
      "Central",
      "Tega Cay",
      "Edgefield",
      "Belton",
      "Mullins",
    ],
  },
  {
    name: "Dakota del Sur",
    alias: ["Dakota del Sur", "SD"],
    municipalities: [
      "Sioux Falls",
      "Rapid City",
      "Aberdeen",
      "Brookings",
      "Watertown",
      "Mitchell",
      "Yankton",
      "Pierre",
      "Huron",
      "Vermillion",
      "Spearfish",
      "Brandon",
      "Box Elder",
      "Sturgis",
      "Madison",
      "Belle Fourche",
      "Ellsworth AFB",
      "Hot Springs",
      "Milbank",
      "Lead",
      "North Sioux City",
      "Dell Rapids",
      "Tea",
      "Canton",
      "Harrisburg",
      "Hartford",
      "Pine Ridge",
      "Dakota Dunes",
      "Mobridge",
      "Sisseton",
      "Winner",
      "Chamberlain",
      "Lennox",
      "Flandreau",
      "Redfield",
      "Custer",
      "Fort Pierre",
      "Colonial Pine Hills",
      "Crooks",
      "Parkston",
      "Hill City",
      "Volga",
      "Springfield",
      "Garretson",
      "Webster",
      "Eagle Butte",
      "Wagner",
      "Miller",
      "Gregory",
      "Britton",
      "Canistota",
    ],
  },
  {
    name: "Tennessee",
    alias: ["Tennessee", "TN"],
    municipalities: [
      "Nashville",
      "Memphis",
      "Knoxville",
      "Chattanooga",
      "Clarksville",
      "Murfreesboro",
      "Franklin",
      "Jackson",
      "Johnson City",
      "Bartlett",
      "Hendersonville",
      "Kingsport",
      "Collierville",
      "Cleveland",
      "Smyrna",
      "Germantown",
      "Brentwood",
      "Columbia",
      "La Vergne",
      "Gallatin",
      "Spring Hill",
      "Cookeville",
      "Mount Juliet",
      "Lebanon",
      "Morristown",
      "Oak Ridge",
      "Maryville",
      "Bristol",
      "Farragut",
      "Shelbyville",
      "East Ridge",
      "Tullahoma",
      "Goodlettsville",
      "McMinnville",
      "Dyersburg",
      "Springfield",
      "Greeneville",
      "Sevierville",
      "Dickson",
      "Elizabethton",
      "Athens",
      "Soddy-Daisy",
      "McNairy",
      "Collegedale",
      "Lewisburg",
      "Lawrenceburg",
      "Union City",
      "Brownsville",
      "Portland",
      "Paris",
      "Pulaski",
    ],
  },
  {
    name: "Texas",
    alias: ["Texas", "TX"],
    municipalities: [
      "Houston",
      "San Antonio",
      "Dallas",
      "Austin",
      "Fort Worth",
      "El Paso",
      "Arlington",
      "Corpus Christi",
      "Plano",
      "Laredo",
      "Lubbock",
      "Garland",
      "Irving",
      "Amarillo",
      "McKinney",
      "Frisco",
      "Brownsville",
      "Pasadena",
      "Killeen",
      "McAllen",
      "Mesquite",
      "Midland",
      "Denton",
      "Waco",
      "Carrollton",
      "Round Rock",
      "Abilene",
      "Pearland",
      "Richardson",
      "Odessa",
      "Sugar Land",
      "Beaumont",
      "College Station",
      "Lewisville",
      "Tyler",
      "League City",
      "Wichita Falls",
      "Allen",
      "San Angelo",
      "Edinburg",
      "Conroe",
      "Bryan",
      "Mission",
      "Longview",
      "Atascocita",
      "Pharr",
      "Baytown",
      "Missouri City",
      "Temple",
      "Flower Mound",
      "North Richland Hills",
      "Harlingen",
      "Victoria",
      "New Braunfels",
      "Mansfield",
      "Cedar Park",
      "Rowlett",
      "Georgetown",
      "Spring",
    ],
  },
  {
    name: "Utah",
    alias: ["Utah", "UT"],
    municipalities: [
      "Salt Lake City",
      "West Valley City",
      "Provo",
      "West Jordan",
      "Orem",
      "Sandy",
      "Ogden",
      "St. George",
      "Layton",
      "South Jordan",
      "Lehi",
      "Millcreek",
      "Taylorsville",
      "Logan",
      "Murray",
      "Draper",
      "Bountiful",
      "Riverton",
      "Roy",
      "Spanish Fork",
      "Pleasant Grove",
      "Kearns",
      "Cottonwood Heights",
      "Tooele",
      "Clearfield",
      "Springville",
      "Midvale",
      "Herriman",
      "Holladay",
      "American Fork",
      "Magna",
      "Syracuse",
      "South Salt Lake",
      "Eagle Mountain",
      "Clinton",
      "Kaysville",
      "Farmington",
      "Saratoga Springs",
      "Payson",
      "Brigham City",
      "Washington",
      "North Ogden",
      "South Ogden",
      "Highland",
      "Centerville",
      "Hurricane",
      "Heber",
      "West Haven",
      "Lindon",
      "Cedar City",
      "Alpine",
      "Smithfield",
      "North Salt Lake",
      "Santaquin",
    ],
  },
  {
    name: "Vermont",
    alias: ["Vermont", "VT"],
    municipalities: [
      "Burlington",
      "South Burlington",
      "Rutland",
      "Essex",
      "Bennington",
      "Brattleboro",
      "Hartford",
      "Barre",
      "Montpelier",
      "Williston",
      "St. Albans",
      "Winooski",
      "St. Johnsbury",
      "Middlebury",
      "Vergennes",
      "Newport",
      "Swanton",
      "Morristown",
      "Milton",
      "Northfield",
      "Lyndon",
      "Rockingham",
      "Fairfax",
      "Colchester",
      "Shelburne",
      "Springfield",
      "Randolph",
      "Hinesburg",
      "Hartland",
      "Castleton",
      "Poultney",
      "Georgia",
      "Richmond",
      "Derby",
      "Brandon",
      "Jericho",
      "Bristol",
      "Waterbury",
      "Waitsfield",
      "Fair Haven",
      "Pownal",
      "Hardwick",
      "Enosburg",
      "Johnson",
      "Norwich",
      "Arlington",
      "Chester",
      "Bradford",
    ],
  },
  {
    name: "Virginia",
    alias: ["Virginia", "VA"],
    municipalities: [
      "Virginia Beach",
      "Norfolk",
      "Chesapeake",
      "Richmond",
      "Arlington",
      "Newport News",
      "Hampton",
      "Alexandria",
      "Roanoke",
      "Portsmouth",
      "Suffolk",
      "Lynchburg",
      "Harrisonburg",
      "Leesburg",
      "Charlottesville",
      "Blacksburg",
      "Danville",
      "Manassas",
      "Petersburg",
      "Fredericksburg",
      "Winchester",
      "Salem",
      "Herndon",
      "Staunton",
      "Fairfax",
      "Hopewell",
      "Christiansburg",
      "Waynesboro",
      "Colonial Heights",
      "Radford",
      "Bristol",
      "Martinsville",
      "Falls Church",
      "Culpeper",
      "Poquoson",
      "Warrenton",
      "Lexington",
      "Buena Vista",
      "Franklin",
      "Purcellville",
      "Emporia",
      "Norton",
      "Big Stone Gap",
      "South Boston",
      "Farmville",
      "Wytheville",
      "Strasburg",
    ],
  },
  {
    name: "Washington",
    alias: ["Washington", "WA"],
    municipalities: [
      "Seattle",
      "Spokane",
      "Tacoma",
      "Vancouver",
      "Bellevue",
      "Kent",
      "Everett",
      "Renton",
      "Spokane Valley",
      "Federal Way",
      "Yakima",
      "Bellingham",
      "Kennewick",
      "Auburn",
      "Pasco",
      "Marysville",
      "Lakewood",
      "Redmond",
      "Shoreline",
      "Richland",
      "Sammamish",
      "Burien",
      "Olympia",
      "Lacey",
      "Edmonds",
      "Bremerton",
      "Puyallup",
      "Longview",
      "Lynnwood",
      "Bothell",
      "Issaquah",
      "Mount Vernon",
      "Wenatchee",
      "University Place",
      "Pullman",
      "Walla Walla",
      "Des Moines",
      "SeaTac",
      "Maple Valley",
      "Mercer Island",
      "Bainbridge Island",
      "Moses Lake",
      "Camas",
      "Oak Harbor",
      "Kenmore",
      "Mukilteo",
      "Mountlake Terrace",
      "Tumwater",
      "Mill Creek",
      "Anacortes",
      "Battle Ground",
    ],
  },
  {
    name: "Virginia Occidental",
    alias: ["Virginia Occidental", "WV"],
    municipalities: [
      "Charleston",
      "Huntington",
      "Morgantown",
      "Parkersburg",
      "Wheeling",
      "Weirton",
      "Fairmont",
      "Beckley",
      "Clarksburg",
      "Martinsburg",
      "South Charleston",
      "Vienna",
      "St. Albans",
      "Bluefield",
      "Moundsville",
      "Bridgeport",
      "Dunbar",
      "Oak Hill",
      "Elkins",
      "Nitro",
      "Hurricane",
      "Princeton",
      "New Martinsville",
      "Buckhannon",
      "Grafton",
      "Keyser",
      "Ranson",
      "Point Pleasant",
      "Weston",
      "Lewisburg",
      "Ravenswood",
      "Summersville",
      "Kenova",
      "Williamson",
      "Kingwood",
      "Follansbee",
      "Wellsburg",
      "Williamstown",
      "Madison",
      "Philippi",
      "Petersburg",
      "Granville",
      "Hinton",
      "Spencer",
      "Chester",
      "Salem",
      "Barboursville",
      "Beech Bottom",
      "Moorefield",
      "Ripley",
      "New Cumberland",
      "Harrisville",
    ],
  },
  {
    name: "Wisconsin",
    alias: ["Wisconsin", "WI"],
    municipalities: [
      "Milwaukee",
      "Madison",
      "Green Bay",
      "Kenosha",
      "Racine",
      "Appleton",
      "Waukesha",
      "Oshkosh",
      "Eau Claire",
      "Janesville",
      "West Allis",
      "La Crosse",
      "Sheboygan",
      "Wauwatosa",
      "Fond du Lac",
      "New Berlin",
      "Wausau",
      "Brookfield",
      "Beloit",
      "Greenfield",
      "Franklin",
      "Menomonee Falls",
      "Oak Creek",
      "Manitowoc",
      "West Bend",
      "Sun Prairie",
      "Superior",
      "Stevens Point",
      "Neenah",
      "Fitchburg",
      "Muskego",
      "De Pere",
      "Mequon",
      "Watertown",
      "South Milwaukee",
      "Pleasant Prairie",
      "Germantown",
      "Cudahy",
      "Marshfield",
      "Wisconsin Rapids",
      "Onalaska",
      "Middleton",
      "Menasha",
      "Beaver Dam",
      "New Richmond",
      "Chippewa Falls",
      "Waukesha",
      "River Falls",
      "Hartford",
      "Whitewater",
      "Greenfield",
      "Caledonia",
      "Baraboo",
      "Mount Pleasant",
      "Kaukauna",
      "Burlington",
    ],
  },
  {
    name: "Wyoming",
    alias: ["Wyoming", "WY"],
    municipalities: [
      "Cheyenne",
      "Casper",
      "Laramie",
      "Gillette",
      "Rock Springs",
      "Sheridan",
      "Green River",
      "Evanston",
      "Riverton",
      "Jackson",
      "Cody",
      "Rawlins",
      "Lander",
      "Torrington",
      "Powell",
      "Douglas",
      "Ranchettes",
      "Worland",
      "Buffalo",
      "South Greeley",
      "Wheatland",
      "Mills",
      "Newcastle",
      "Thermopolis",
      "Evansville",
      "Lovell",
      "Bar Nunn",
      "Kemmerer",
      "Glenrock",
      "Pinedale",
      "North Rock Springs",
      "Wright",
      "Lyman",
      "Afton",
      "Greybull",
      "Saratoga",
      "Lusk",
      "Star Valley Ranch",
      "Basin",
      "Mountain View",
      "Sleepy Hollow",
      "Moorcroft",
      "Riverside",
      "Guernsey",
    ],
  },
];
