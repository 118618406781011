/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Button from "../../components/elements/buttons/button";
import Ilustration from "../../assets/ilustrations/useapp_ilustration.svg";

function UseAppScreen() {
  const mySwal = withReactContent(Swal);

  useEffect(() => {
    mySwal.fire({
      showCloseButton: false,
      width: "88vw",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      showCancelButton: false,
      padding: "0 0 0 0",
      html: (
        <div className="LayoutVerification" style={{ background: "white" }}>
          <img src={Ilustration} alt="ilustration" style={{ width: "75vw" }} />
          <p className="verification-paragraph" style={{ margin: "4vh 0" }}>
            <b>¡Hola!</b> Para realizar tu verificación, te recomendamos hacerlo
            en un dispositivo de escritorio o descargar nuestra aplicación.
          </p>
          <Button
            label="Descargar Aplicación"
            width="80vw"
            onTap={() => {
              window.open(
                "https://adamo-flutter-deploy-qa.s3.us-east-2.amazonaws.com/AdamoID-qa.apk"
              );
            }}
          />
          <div
            className=""
            style={{
              margin: "4vh 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              className="line"
              style={{ background: "#e1e1e1", height: "1px", width: "40vw" }}
            />
            <b className="verification-paragraph">ó</b>

            <div
              className="line"
              style={{ background: "#e1e1e1", height: "1px", width: "40vw" }}
            />
          </div>
          <p className="verification-paragraph">
            <span style={{ color: "#2bbfed" }}>
              {" "}
              <b>Si prefieres usar un ordenador,</b>
            </span>{" "}
            visita‏‏‎ ‎
            <span style={{ textDecoration: "underline" }}>
              https://adamo-client-qa.limboteams.com
            </span>{" "}
            para una verificacion sin problemas.
          </p>
        </div>
      ),
    });
  }, []);

  return <div></div>;
}

export default UseAppScreen;
