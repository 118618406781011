/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import Logo from "../../../../assets/icons/id_logo.png";
import Button from "../../../../components/elements/buttons/button";
import { useNavigate } from "react-router-dom";
import Sheet from "react-modal-sheet";
import { RoutesList } from "../../../../utils/routes/routes_utils";
import { toast } from "react-hot-toast";
import useAppContext from "../../../../utils/context/useAppContext/use_app_context";
import VerificationsFooter from "../../../../components/layouts/veriifcations_layout/footer/verifications_footer";

function IntroVerificationFacial() {
  const navigate = useNavigate();
  const appContext = useAppContext();

  return (
    <div className="intro_verification_identity intro_verification_facial">
      <div
        className="header_layout_verification"
        style={{ justifyContent: "initial" }}
      >
        <h3>Verificación facial</h3>
      </div>
      <img src={Logo} />

      <p className="verification-paragraph">
        Bienvenido a la verificación facial de Adamo ID. Por favor, antes de
        iniciar la verificación lee atentamente las siguientes sugerencias para
        que el proceso de reconocimiento facial sea ágil y exitoso.
      </p>

      <div className="footer-start-facial">
        <Button
          width="100%"
          label="Comenzar"
          onTap={() => navigate(RoutesList.InstructionOneVerificationFacial)}
        />
      </div>
    </div>
  );
}

export default IntroVerificationFacial;
