import React from "react";
import { faceSquareState } from "../../../models/camera/camera_models";

function FaceSquareIndicator({ state }: any) {
  const getColorBorder = (): string => {
    switch (state) {
      case faceSquareState.idle:
        return "#2bbfed91";
      case faceSquareState.correct:
        return "#78E678";
      default:
        return "#2bbfed91";
    }
  };

  return (
    <div
      className="FaceSquareIndicator"
      style={{ borderColor: getColorBorder() }}
    />
  );
}

export default FaceSquareIndicator;
