export class Base64Utils {
  clean(base64: string): string {
    return base64
      .replace("data:image/jpeg;base64,", "")
      .replace("data:image/jpg;base64,", "")
      .replace("data:image/webp;base64,", "")
      .replace("data:image/webp;base64,", "")
      .replace("data:application/pdf;base64,", "")
      .replace("data:image/png;base64,", "");
  }

  openImage = (base64: string, mySwal: any) => {
    mySwal.fire({
      showCloseButton: false,
      width: "88vw",
      allowOutsideClick: true,
      allowEscapeKey: true,
      showConfirmButton: false,
      showCancelButton: false,
      padding: "0 0 0 0",
      html: (
        <div className="image-preview">
          <img src={base64} width="80%" alt="preview" />
          <span onClick={() => mySwal.close()} className="image-preview-close">
            X
          </span>
        </div>
      ),
    });
  };

  openPDF(base64PDF: string) {
    // Extraer la parte base64 del string
    const base64Content = base64PDF.split(";base64,")[1];

    // Convertir la cadena base64 en un ArrayBuffer
    const binaryString = atob(base64Content);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    // Crear un Blob con el ArrayBuffer
    const blob = new Blob([bytes.buffer], { type: "application/pdf" });

    // Crear un enlace para abrir el Blob en una nueva pestaña
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.target = "_blank";

    // Agregar el enlace al DOM y disparar el evento de clic
    document.body.appendChild(link);
    link.click();

    // Eliminar el enlace del DOM y liberar el objeto URL
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  }
}
