/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import CheckIcon from "../../../assets/icons/check.svg";
import CancelIcon from "../../../assets/icons/cancel.svg";
import useAppContext from "../../../utils/context/useAppContext/use_app_context";

function IdentityInstructions() {
  const instructions = [
    {
      correct: true,
      text: "Enfoca lo mejor posible el documento en la cámara.",
    },
    {
      correct: true,
      text: "Asegúrate que los limites de la foto coincidan lo mejor posible con los bordes del documento.",
    },
    {
      correct: false,
      text: "Evita brillos sobre la foto y los textos del documento.",
    },
    {
      correct: false,
      text: " Evita que en la foto aparezcan más elementos diferentes al documento.",
    },
  ];

  return (
    <div className="identity_instructions">
      {instructions.map((instruction) => (
        <div className="identity_instruction">
          <img src={instruction.correct ? CheckIcon : CancelIcon} />
          <p>{instruction["text"]}</p>
        </div>
      ))}
    </div>
  );
}

export default IdentityInstructions;
