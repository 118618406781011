/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LocalStorageItems } from "../../models/local_storage/local_storage";
import { RoutesList } from "../../utils/routes/routes_utils";
import { basename } from "path/posix";
import useAppContext from "../../utils/context/useAppContext/use_app_context";
import { SocketUtils } from "../../utils/socket/socket_utils";
import { getSettings } from "../../service/settings/settings";

function RegisterScreen() {
  const navigate = useNavigate();
  const appContext = useAppContext();

  const onStart = () => {
    localStorage.setItem(LocalStorageItems.REGISTERMODE, "true");
    appContext.changeContext({ registerMode: true });

    navigate(RoutesList.WelcomeRegister);
  };

  useEffect(onStart, []);

  return <div></div>;
}

export default RegisterScreen;
