import React from "react";
import Button from "../../components/elements/buttons/button";
import Ilustration from "../../assets/ilustrations/modal_face_incorrect.svg";
import { useNavigate } from "react-router-dom";
import { RoutesList } from "../../utils/routes/routes_utils";

function ModalFaceIncorrect() {
  const navigate = useNavigate();

  return (
    <div
      className={`verification_identity_screen verification_identity_screen_finished verification_identity_screen_finished_true`}
      style={{ height: "100vh" }}
    >
      <img
        src={Ilustration}
        alt="a"
        style={{
          width: "75%",
          marginTop: "12vh",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />

      <p style={{ color: "#2bbfed", fontWeight: "bold", marginBottom: "0" }}>
        En realidad eres tú?
      </p>
      <p
        className="verification-paragraph"
        style={{ color: "black", fontSize: "3.8vw" }}
      >
        No hemos logrado reconocerte. Por favor, vuelve a tomar la fotografia de
        tu documento.
      </p>

      <Button
        label="ir a tomar foto del documento"
        onTap={() => navigate(RoutesList.FrontImageDocument)}
      />
      <Button
        label="Finalizar"
        outlined
        onTap={() => navigate(RoutesList.WelcomeRegister)}
      />
    </div>
  );
}

export default ModalFaceIncorrect;
