import React from "react";
import Check from "../../assets/icons/check.svg";

interface Props {
  title: string;
  isCompleted: boolean;
  disabled: boolean;
}

function SummaryItem(props: Props) {
  return (
    <div className={"summary_item summary_item_" + props.disabled}>
      <p>{props.title}</p>
      {props.isCompleted && <img src={Check} alt="check" />}
    </div>
  );
}

export default SummaryItem;
