/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import LayoutVerification from "../../../../components/layouts/veriifcations_layout/layout_verifications";
import { VerificationsMode } from "../../../../models/verifications/verifications_models";
import Ilustration from "../../../../assets/ilustrations/facialstep4.svg";
import VerificationsFooter from "../../../../components/layouts/veriifcations_layout/footer/verifications_footer";
import { useNavigate } from "react-router-dom";
import { RoutesList } from "../../../../utils/routes/routes_utils";
import SelectInputField from "../../../../components/elements/inputs/select";
import {
  FieldValues,
  SubmitHandler,
  useForm,
  UseFormRegister,
} from "react-hook-form";
import countrycitystatejson from "countrycitystatejson";
import TextInputField from "../../../../components/elements/inputs/textField";
import { AddressServiceClass } from "../../../../service/address/address_service";
import {
  helpersTexts,
  ValidRegexp,
} from "../../../../models/component_props/input_props";
import useAppContext from "../../../../utils/context/useAppContext/use_app_context";
import StaticInput from "../../../../components/elements/inputs/static/static_input";

function FieldsVerificationAddress() {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    setError,
    formState: { errors },
  } = useForm();

  const appContext = useAppContext();
  const navigate = useNavigate();

  const [countrySelectedAbbr, setCountrySelectedAbbr] = useState("CO");

  const [stateSelected, setStateSelected] = useState(
    new AddressServiceClass().getStates()[0]
  );

  const onSubmit = async ({ data, setError }) => {
    try {
      appContext.toggleLoading();

      const payload = {
        department: data.department.name,
        municipality: data.municipality.name,
        address: data.address,
        country: countrySelectedAbbr,
      };

      if (data.zipcode != null && data.zipcode.length > 0) {
        payload.zipCode = data.zipcode;
      }

      await new AddressServiceClass().saveDirections(payload);

      appContext.toggleLoading(false);

      navigate(RoutesList.AddressDocumentVerifications);
    } catch (error) {
      console.log(error);
      appContext.toggleLoading(false);
    }
  };

  const getCountrySelected = async () => {
    appContext.toggleLoading(true);

    try {
      const response = await new AddressServiceClass().getDocument();

      const countrySelected = response.payload.country;
      const firstState = new AddressServiceClass().getStates(
        countrySelected ?? "CO"
      )[0];

      setStateSelected(firstState);
      setCountrySelectedAbbr(countrySelected);
    } catch (error) {
      //
    }

    appContext.toggleLoading(false);
  };

  const getCountryName = () => {
    switch (countrySelectedAbbr) {
      case "ARG":
      case "ARGENTINA":
        return "Argentina";
      case "CO":
      case "COLOMBIA":
        return "Colombia";
      case "UK":
      case "Reino Unido":
        return "Inglatera";
      case "BR":
      case "BRAZIL":
      case "BRASIL":
        return "Brasil";
      case "MX":
      case "MEXICO":
        return "Mexico";
      case "US":
      case "ESTADOS UNIDOS":
        return "Estados Unidos";
      case "ES":
      case "ESPAÑA":
        return "Español";
      case "GU":
        return "Guatemala";
      case "JM":
        return "Jamaica";
      case "AU":
        return "Australia";
      case "HO":
        return "Honduras";
      case "CR":
        return "Costa rica";
      case "DR":
        return "Republica Dominicana";
      case "NZ":
        return "Nueva Zelanda";
      default:
        return "Colombia";
    }
  };

  useEffect(() => {
    getCountrySelected();
  }, []);

  return (
    <LayoutVerification mode={VerificationsMode.address}>
      <div className="verification_address_form">
        <form onSubmit={handleSubmit((e) => onSubmit({ data: e, setError }))}>
          <div className="">
            <TextInputField
              label="Dirección"
              placeholder="Dirección"
              useForm={{
                ...register("address", { required: true }),
                errors,
              }}
            />
            <SelectInputField
              label="Estado"
              getOptionLabel={(option) => option.name}
              options={new AddressServiceClass().getStates(countrySelectedAbbr)}
              onChange={(e) => {
                setStateSelected(e);
              }}
              useForm={{
                ...register("department", { required: true }),
                errors,
              }}
              style={{
                width: "100%",
              }}
            />
            <SelectInputField
              label="Ciudad"
              getOptionLabel={(option) => option.name}
              options={new AddressServiceClass().getCitiesOfState(
                stateSelected,
                countrySelectedAbbr
              )}
              onChange={(e) => {}}
              useForm={{
                ...register("municipality", { required: true }),
                errors,
              }}
              style={{
                width: "100%",
              }}
            />
            <StaticInput title="" value={getCountryName()} />
            <TextInputField
              label="Código postal"
              placeholder="Código postal"
              useForm={{
                ...register("zipcode", {
                  pattern: {
                    value: ValidRegexp.number,
                    message: new helpersTexts().number,
                  },
                }),
                errors,
              }}
            />
          </div>

          <VerificationsFooter
            hideSkipInstructions
            submitButton
            currentStep={1}
            stepsAmount={2}
            onTap={() => {}}
          />
        </form>
      </div>
    </LayoutVerification>
  );
}

export default FieldsVerificationAddress;
