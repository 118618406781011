/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import LayoutVerification from "../../../../components/layouts/veriifcations_layout/layout_verifications";
import { VerificationsMode } from "../../../../models/verifications/verifications_models";
import VerificationsFooter from "../../../../components/layouts/veriifcations_layout/footer/verifications_footer";
import useAppContext from "../../../../utils/context/useAppContext/use_app_context";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoutesList } from "../../../../utils/routes/routes_utils";
import { AddressServiceClass } from "../../../../service/address/address_service";
import ButtonSelectPDF from "../../../../components/elements/buttons/button_pdf";
import { Base64Utils } from "../../../../utils/base64/base64_utils";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function DcoumentsVerificationAddress() {
  const appContext = useAppContext();
  const navigate = useNavigate();

  const [isErrorInPDF, setIsErrorInPDF] = useState("");
  const [fileContent, setFileContent] = useState("");
  const [isAPDF, setIsAPDF] = useState(false);

  const mySwal = withReactContent(Swal);

  const onSubmit = async () => {
    try {
      appContext.toggleLoading();
      setIsErrorInPDF("");

      const base64 = new Base64Utils().clean(fileContent);

      await new AddressServiceClass().validatePDF({
        file: base64,
      });

      appContext.toggleLoading(false);
      appContext.changeContext({ has_completed_address_verf: true });

      navigate(RoutesList.AddressFinishedVerifications);
    } catch (error: any) {
      setIsErrorInPDF(
        "El archivo no cuenta con las condiciones necesarias para extraer la información."
      );
      appContext.toggleLoading(false);
    }
  };

  const onSelectFile = (e: string, fileExtension: string) => {
    setIsErrorInPDF("");
    const fileTypesAllowed = [".pdf"];

    if (appContext.state.acceptImages === true) {
      fileTypesAllowed.push(".png", ".jpg", ".jpeg");
    }

    if (fileTypesAllowed.includes(fileExtension)) {
      setIsAPDF(fileExtension === ".pdf");
      setFileContent(e);
    } else {
      setIsErrorInPDF(
        "El formato no es válido. Por favor, intenta adjuntar un documento en formato PDF"
      );
    }
  };

  if (fileContent?.length != 0 && appContext.state.has_completed_challenge) {
    navigate(RoutesList.AddressFinishedVerifications);
  }

  useEffect(() => {
    if (appContext.state.has_completed_challenge) {
      navigate(RoutesList.AddressFinishedVerifications);
    }
  }, []);

  return (
    <LayoutVerification
      mode={VerificationsMode.address}
      footer={
        <VerificationsFooter
          hideSkipInstructions
          currentStep={2}
          stepsAmount={2}
          disabled={isErrorInPDF.length != 0 || fileContent.length == 0}
          onTap={onSubmit}
        />
      }
    >
      <div className="verification_address">
        <div className="">
          <p className="verification-paragraph">
            Sube una copia de un documento que muestre tu nombre completo en el
            que se indiquen claramente el domicilio, la fecha de emisión y el
            nombre del emisor; además, la fecha deberá ser, como máximo, tres
            meses anterior a la de la presentación del documento.
            <br />
            <br />
            Algunos ejemplos de documentos válidos son: Facturas municipales de
            suministros como electricidad, agua o alcantarillado, declaración de
            pago de tasas e impuestos municipales, factura del servicio de
            internet, extracto bancario.
            <br />
            <br />
            Archivos aceptados:{" "}
            {appContext.state.acceptImages ? "PDF e imagenes" : "PDF"}.
            <br />
            <br />
          </p>
        </div>
        {fileContent?.length === 0 ? (
          <ButtonSelectPDF
            setBase64={onSelectFile}
            allowImages={appContext.state.acceptImages === true}
          />
        ) : (
          <div className="document_preview">
            <h3>Comprobante de domicilio</h3>
            <i
              className="fa-regular fa-eye"
              style={{ color: "#1aa9d8" }}
              onClick={() => {
                if (isAPDF) {
                  new Base64Utils().openPDF(fileContent);
                } else {
                  new Base64Utils().openImage(fileContent, mySwal);
                }
              }}
            ></i>
            <i
              className="fa-regular fa-circle-xmark"
              onClick={() => setFileContent("")}
              style={{ color: "red" }}
            ></i>
          </div>
        )}
        {isErrorInPDF && (
          <p
            className="verification-paragraph center_text"
            style={{ color: "red", marginTop: "2vh" }}
          >
            {isErrorInPDF}
          </p>
        )}
      </div>
    </LayoutVerification>
  );
}

export default DcoumentsVerificationAddress;
