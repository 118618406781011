/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from "react";
import DocumentMask from "../../../assets/ilustrations/documentmask.svg";
import ObturatorIcon from "../../../assets/icons/obturator.svg";
import ImageCropper from "../../image_cropper/image_cropper";
import useAppContext from "../../../utils/context/useAppContext/use_app_context";
import { toast } from "react-hot-toast";
import Webcam from "react-webcam";
import { Camera as CameraAndroid } from "react-camera-pro";
import { IdentityService } from "../../../service/identity/identity_service";
import CameraIos from "../../cameras/camear_ios";
import { iOS } from "../../../utils/devices/devices_utils";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import VerificationCancelledModal from "../../modals/verf_cancelled/verification_cancelled_modal";
import CameraDeniedModal from "../../modals/camera_denied/camera_denied_modal";
import { FacingMode } from "react-camera-pro/dist/components/Camera/types";

interface Props {
  onCrop(base64: string): void;
  isFrontSide: boolean;
}

function IdentityCamera(props: Props) {
  const webcamRef: any = React.useRef(null);
  const camera: any = useRef(null);
  const appContext = useAppContext();
  const mySwal = withReactContent(Swal);

  const [facingMode, setFacingMode] = useState<FacingMode>("user");
  const [isTakingPhoto, setIsTakingPhoto] = useState(true);
  const [base64, setBase64] = useState("");

  const onTakePhoto = () => {
    try {
      appContext.toggleLoading();
      const base64 = camera.current?.takePhoto();
      setIsTakingPhoto(false);

      var img = new Image();

      img.onload = function () {
        var canvas = document.createElement("canvas");

        canvas.width = img.height;
        canvas.height = img.width;
        var ctx: any = canvas.getContext("2d");

        ctx.translate(Math.floor(img.height / 2), Math.floor(img.width / 2));

        ctx.rotate(270 * (Math.PI / 180));

        ctx.drawImage(img, -img.width / 2, -img.height / 2);

        appContext.toggleLoading(false);
        setBase64(canvas.toDataURL("image/webp", 1));
      };

      // Carga la imagen base64
      img.src = base64!;
    } catch (error) {
      toast.error("Error al rotar la imagen");
    }
  };

  const handleOnCrop = async (base64Cutted: string) => {
    setBase64(base64Cutted);
    props.onCrop(base64Cutted);
  };

  const checkCameraPermissionIos = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });

      mySwal.close();
    } catch (error) {
      onRejectPermission();
    }
  };

  const checkCameraPermission = async () => {
    try {
      const { state } = await navigator.permissions.query({
        name: "camera" as PermissionName,
      });

      if (state === "denied") {
        onRejectPermission();
      } else {
        mySwal.close();
      }
    } catch (error) {}
  };

  const requestCameraPermissionIos = async () => {
    try {
      await navigator.permissions.query({
        name: "camera" as PermissionName,
      });
    } catch (error) {
      onRejectPermission();
    }
  };

  const checkPermissionByPlatform = () => {
    if (iOS()) {
      checkCameraPermissionIos();
    } else {
      checkCameraPermission();
    }
  };

  const onRejectPermission = () => {
    appContext.state.ws?.close();

    mySwal.fire({
      showCloseButton: false,
      width: "88vw",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      showCancelButton: false,
      padding: "0 0 0 0",
      html: <CameraDeniedModal />,
    });
  };

  useEffect(() => {
    try {
      if (iOS()) {
        requestCameraPermissionIos();
      }
      checkPermissionByPlatform();
      const permissionPromise = navigator.permissions.query({
        name: "camera" as PermissionName,
      });
      permissionPromise.then((permissionStatus) => {
        permissionStatus.onchange = checkPermissionByPlatform;
      });

      return () => {
        permissionPromise.then((permissionStatus) => {
          permissionStatus.onchange = null;
        });
      };
    } catch (error) {
      //
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="identity_camera">
      {isTakingPhoto ? (
        <>
          <div>
            <CameraAndroid
              facingMode={facingMode}
              numberOfCamerasCallback={(numberOfCameras: number) => {
                if (numberOfCameras > 1 && facingMode === "user") {
                  camera.current.switchCamera();
                }
              }}
              errorMessages={{
                noCameraAccessible:
                  "No camera device accessible. Please connect your camera or try a different browser.",
                permissionDenied:
                  "Permission denied. Please refresh and give camera permission.",
                switchCamera:
                  "It is not possible to switch camera to different one because there is only one video device accessible.",
                canvas: "Canvas is not supported.",
              }}
              ref={camera}
            />

            <div className="overlay_container">
              <div className="borders">
                <p>Este lado arriba</p>
              </div>
            </div>
          </div>

          <img
            src={ObturatorIcon}
            className="obturator_icon"
            onClick={onTakePhoto}
          />
        </>
      ) : (
        <ImageCropper
          setBase64={setBase64}
          base64={base64}
          isFrontSide={props.isFrontSide}
          onCrop={handleOnCrop}
          takePhotoAgain={() => setIsTakingPhoto(true)}
        />
      )}
    </div>
  );
}

export default IdentityCamera;
