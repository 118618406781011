import React from "react";
import Button from "../../elements/buttons/button";
import Ilustration from "../../../assets/ilustrations/register_cancelled.svg";
import { Link, useNavigate } from "react-router-dom";
import { RoutesList } from "../../../utils/routes/routes_utils";
import RegisterScreen from "../../../screens/misc/register_screen";
import useAppContext from "../../../utils/context/useAppContext/use_app_context";
import Swal from "sweetalert2";
import { LocalStorage } from "../../../utils/local_storage/local_storage";

function RegisterCancelledScreen(props: any) {
  return (
    <div className="LayoutVerification" style={{ background: "white" }}>
      <img src={Ilustration} alt="ilustration" style={{ width: "75vw" }} />
      <p
        className="verification-paragraph"
        style={{ color: "#2bbfed", margin: "4vh 0" }}
      >
        <b>Parece que has actualizado tu navegador.</b>
      </p>
      <p className="verification-paragraph" style={{ margin: "4vh 0" }}>
        Si ya completaste el proceso de verificación, todo está en orden.
      </p>

      <div
        className=""
        style={{
          margin: "4vh 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          className="line"
          style={{ background: "#e1e1e1", height: "1px", width: "40vw" }}
        />
        <b className="verification-paragraph">ó</b>

        <div
          className="line"
          style={{ background: "#e1e1e1", height: "1px", width: "40vw" }}
        />
      </div>
      <p className="verification-paragraph">
        Si aún no has finalizado la verificación, simplemente presiona el botón
        a continuación para reiniciar el proceso. ¡Estamos aquí para ayudarte!
      </p>

      <div
        style={{
          position: "absolute",
          bottom: "10%",
          width: "90%",
        }}
      >
        <Button
          label="Reinciar proceso de verificación"
          width="100%"
          onTap={() => {
            localStorage.setItem("a", "true");
            Swal.close();
            window.location.href = RoutesList.WelcomeRegister;
          }}
        />
      </div>
    </div>
  );
}

export default RegisterCancelledScreen;
