/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import IlustrationSuccess from "../../../../assets/ilustrations/verificationfinished.svg";
import IlustrationFailed from "../../../../assets/ilustrations/verificationfailed.svg";
import Button from "../../../../components/elements/buttons/button";
import IdentityInstructions from "../../../../components/verf_identity/instructions/identity_instructions";
import { Route, useNavigate } from "react-router-dom";
import { RoutesList } from "../../../../utils/routes/routes_utils";
import { IdentityService } from "../../../../service/identity/identity_service";
import { IdentityVerfErrors } from "../../../../models/verifications/verifications_models";
import { IdentityUtils } from "../../../../utils/identity/identity_utils";
import useAppContext from "../../../../utils/context/useAppContext/use_app_context";
import { UserUtils } from "../../../../utils/user/user_utils";

interface Props {
  isComplete: boolean;
  error?: IdentityVerfErrors;
  closeModal?: () => void;
}

function FinishVerificationIdentity({ isComplete, error, closeModal }: Props) {
  const identityUtils = new IdentityUtils();
  const navigate = useNavigate();

  useEffect(() => {
    if (isComplete) {
      const preventBack = (event: any) => {
        event.preventDefault();
        window.history.pushState("", "", window.location.href);
        window.history.replaceState("", "", window.location.href);
      };

      window.history.pushState("", "", window.location.href);
      window.addEventListener("popstate", preventBack);
      return () => {
        window.removeEventListener("popstate", preventBack);
      };
    }
  }, []);

  return (
    <div
      className={`verification_identity_screen verification_identity_screen_finished verification_identity_screen_finished_${isComplete}`}
    >
      {!isComplete && (
        <i onClick={closeModal} className="fa-sharp fa-solid fa-xmark"></i>
      )}
      <img src={isComplete ? IlustrationSuccess : IlustrationFailed} />

      <h2>{isComplete ? "¡Muchas gracias!" : "Intenta nuevamente"}</h2>

      <p>
        {isComplete
          ? "Todo se ve bien hasta aquí. Por favor termina la verificación desde tu ordenador."
          : identityUtils.getTextParagraph({
              error: error || IdentityVerfErrors.idle,
              isStepCompleted: false,
            })}
      </p>

      {!isComplete && <Button label="Intentar nuevamente" onTap={closeModal} />}
    </div>
  );
}

export default FinishVerificationIdentity;
