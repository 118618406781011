import axios, { AxiosResponse } from "axios";
import { LocalStorageItems } from "../../models/local_storage/local_storage";
import {
  LocalStorage,
  getHeaders,
  isRegisterMode,
} from "../local_storage/local_storage";
import { RoutesList } from "../routes/routes_utils";

export const apiUrl = process.env.REACT_APP_API_ONBOARDING;

const Axios = axios.create({
  baseURL: apiUrl,
});

Axios.interceptors.request.use((config) => {
  if (isRegisterMode()) {
    config.baseURL = process.env.REACT_APP_API_ONBOARDING_REGISTER;
    config.url = `${config.url}?liteVersion=true`;

    if (config.method === "post" && !config.url.includes("ocr-reader")) {
      config.data = {
        ...config.data,
        userId: localStorage.getItem(LocalStorageItems.USERID),
      };
    }

    if (config?.data?.user_id != null) {
      delete config.data.user_id;
    }
  } else {
    config.headers["Authorization"] = `Bearer ${new LocalStorage().getJwtt()}`;
  }

  return config;
});

Axios.interceptors.response.use(
  (e) => e,
  function (error) {
    if (error?.response?.status === 401) {
      window.location.href = RoutesList.useApp;
    }

    return Promise.reject(error);
  }
);

export const Network = Axios;
