import React from "react";

interface Props {
  title: string;
  value: string;
}

function StaticInput({ title, value, ...props }: Props) {
  return (
    <div className="input static_input">
      <div className="title">{title}</div>
      <div className="value">{value}</div>
    </div>
  );
}

export default StaticInput;
