/* eslint-disable eqeqeq */
import CorrectSound from "../../assets/sounds/correct.mp3";

export class DeviceUtils {
  vibrate = () => {
    var customnavigator: any = navigator;
    const canVibrate =
      customnavigator.vibrate ||
      customnavigator.webkitVibrate ||
      customnavigator.mozVibrate ||
      customnavigator.msVibrate;

    if (canVibrate) {
      navigator.vibrate(150);
    }
  };

  playCorrectSound = () => {
    var audio = new Audio(CorrectSound);
    audio.play();
  };

  completedStep = () => {
    this.vibrate();
    this.playCorrectSound();
  };
}

export function iOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

const getEnviroment = () => {
  const urlBackEnd = process.env.REACT_APP_URL_USER ?? "-dev";

  return urlBackEnd.includes("-dev") ? "dev" : "qa";
};

export const isDev = (): boolean => {
  return getEnviroment() == "dev";
};
