import React from "react";
import ReactFileReader from "react-file-reader";

function ButtonSelectPDF({ setBase64, allowImages }) {
  return (
    <ReactFileReader
      as="text"
      handleFiles={(e) => {
        const fileExtension = "." + (e.fileList[0].name.split(".").pop() || "");
        setBase64(e.base64, fileExtension);
      }}
      fileTypes={allowImages}
      base64={true}
      multipleFiles={false}
    >
      <label
        htmlFor="actual-btn"
        className="button button_outlined_true"
        style={{ width: "96%", display: "block" }}
      >
        Seleccionar documento
      </label>
    </ReactFileReader>
  );
}

export default ButtonSelectPDF;
