/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import IlustrationFront from "../../../../assets/ilustrations/frontdocument.svg";
import IlustrationBack from "../../../../assets/ilustrations/backdocument.svg";
import Passport from "../../../../assets/ilustrations/passport.svg";
import CarLicense from "../../../../assets/ilustrations/iddocument.svg";
import Button from "../../../../components/elements/buttons/button";
import IdentityInstructions from "../../../../components/verf_identity/instructions/identity_instructions";
import IlustrationFailed from "../../../../assets/ilustrations/verificationfailed.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Sheet from "react-modal-sheet";
import { RoutesList } from "../../../../utils/routes/routes_utils";
import IdentityCamera from "../../../../components/verf_identity/camera/identity_camera";
import useAppContext from "../../../../utils/context/useAppContext/use_app_context";
import { IdentityService } from "../../../../service/identity/identity_service";
import {
  IdentityVerfErrors,
  VerificationsMode,
} from "../../../../models/verifications/verifications_models";
import { IdentityUtils } from "../../../../utils/identity/identity_utils";
import FinishVerificationIdentity from "../finish/finish_verification_identity";
import { toast } from "react-hot-toast";
import arrowIcon from "../../../../assets/icons/arrow_left.png";
import LayoutVerificationsHeader from "../../../../components/layouts/veriifcations_layout/header/layout_verifications_header";
import { LocalStorageItems } from "../../../../models/local_storage/local_storage";
import { frontImageBase64 } from "./base64";
import ButtonPickImage from "../../../../components/elements/buttons/button_pick_image";
import ErrorTwiceModal from "../finish/error_twice_modal";

interface Props {
  isFrontSide: boolean;
}

function VerificationIdentityImage({ isFrontSide }: Props) {
  const navigate = useNavigate();
  const appContext: any = useAppContext();
  const location = useLocation();

  const [incorrectAttempts, setIncorrectAttempts] = useState(0);
  const [isTakingPhoto, setIsTakingPhoto] = useState(false);
  const [base64, setBase64] = useState("");
  const [isStepCompleted, setIsStepCompleted] = useState(false);
  const [openModalError, setOpenModalError] = useState(false);
  const [openModalErrorTwice, setOpenModalErrorTwice] = useState(false);
  const [log, setLog] = useState("");
  const [errorResponse, setErrorResponse] = useState(IdentityVerfErrors.idle);

  const resizeImage = (imageToResize: HTMLImageElement, fileType: string) => {
    const maxWidth = 2200;
    const maxHeight = 1650;
    const canvas = document.createElement("canvas");

    let width = imageToResize.width;
    let height = imageToResize.height;

    if (width > height) {
      if (width > maxWidth) {
        height = Math.round((height *= maxWidth / width));
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width = Math.round((width *= maxHeight / height));
        height = maxHeight;
      }
    }

    const context = canvas.getContext("2d");
    if (canvas && context) {
      canvas.width = width;
      canvas.height = height;
      context.drawImage(imageToResize, 0, 0, width, height);

      return canvas.toDataURL(fileType);
    } else {
      return "";
    }
  };

  const openCamera = () => {
    console.log("a");
    // setIsTakingPhoto(true);
    const cameraFileInput = document.getElementById(
      "camera_file_input"
    ) as HTMLInputElement;

    if (cameraFileInput) {
      cameraFileInput.click();
      console.log("b");

      const onChange = async (event: Event) => {
        const target = event.target as HTMLInputElement;
        console.log("c", event);

        if (target.files) {
          const fileToUpload = target.files[0];
          const readerFile = new FileReader();
          readerFile.readAsArrayBuffer(fileToUpload);
          console.log("d");

          readerFile.onload = (event: Event) => {
            const target = event.target as FileReader;
            const fileToBlob = target.result ? new Blob([target.result]) : null;

            if (fileToBlob) {
              window.URL = window.URL || window.webkitURL;
              const blobURL = window.URL.createObjectURL(fileToBlob);

              const imageToResize = new Image();
              imageToResize.src = blobURL;
              imageToResize.onload = async () => {
                const resizedImage = resizeImage(
                  imageToResize,
                  fileToUpload.type
                );

                console.log("e");
                cameraFileInput.removeEventListener("change", onChange, false);
                onTakePhoto(resizedImage);
              };
            }
          };
        }

        cameraFileInput.value = "";
      };

      cameraFileInput.addEventListener("change", onChange);
    }
  };

  const tryAgain = () => {
    setOpenModalError(false);
    setOpenModalErrorTwice(false);
    setErrorResponse(IdentityVerfErrors.idle);
    setBase64("");
    openCamera();
  };

  const uploadImage = () => {
    onTakePhoto(frontImageBase64);
  };

  const getCountryAbbreviation = () => {
    return location.state?.countrySelected.abbreviation ?? "CO";
  };

  const getDocumentType = () => {
    return location.state?.documentTypeSelected.type ?? "PASAPORTE";
  };

  const onTakePhoto = async (image?: string) => {
    setOpenModalErrorTwice(false);
    if (image) {
      setIsTakingPhoto(false);
      setBase64(image);
    }

    try {
      appContext.toggleLoading(true);

      const service = new IdentityService();

      let response: any = {};

      if (location.pathname == RoutesList.FrontImageDocument) {
        response = await service.ocrReader({
          base64image: image || base64,
          documentType: getDocumentType(),
          documentCountry: getCountryAbbreviation(),
        });

        localStorage.setItem(
          LocalStorageItems.USERID,
          response["payload"]["userId"] ?? ""
        );

        localStorage.setItem(
          "identityDataUser",
          JSON.stringify(JSON.stringify(response["payload"]))
        );

        appContext.changeContext({ identityDataUser: response["payload"] });
        setLog(JSON.stringify(response));
      } else {
        response = await service.barCode(image || base64);
        setLog(JSON.stringify(response));
      }

      if (response["statusCode"] == 200) {
        setErrorResponse(IdentityVerfErrors.idle);
        setIsStepCompleted(true);
        onContinue();
      }
    } catch (error: any) {
      appContext.toggleLoading(false);

      setIncorrectAttempts((e) => {
        let localIncorrectAttempts = e + 1;

        if (localIncorrectAttempts < 2) {
          setOpenModalError(true);
        } else {
          setOpenModalErrorTwice(true);
        }

        return localIncorrectAttempts;
      });

      const response = error.response.data;
      setLog(JSON.stringify(response));

      setErrorResponse(new IdentityUtils().detectError(response));
    } finally {
      appContext.toggleLoading(false);
    }
  };

  const getIlustration = () => {
    const documentType = appContext.state?.documentTypeSelected?.abbreviation;

    console.log(documentType);

    if (!isFrontSide) return IlustrationBack;

    switch (documentType) {
      case "PP":
        return Passport;
      case "DL":
        return CarLicense;
      case "ID":
        return IlustrationFront;
    }

    return IlustrationFront;
  };

  const WelcomeVerificationIdentityContent = () => {
    return (
      <div className="verification_identity_screen">
        <LayoutVerificationsHeader
          mode={VerificationsMode.identity}
          title={`Parte ${
            isFrontSide ? "frontal" : "posterior"
          } de identificación`}
        />

        <img src={getIlustration()} />
        <IdentityInstructions />
        <Button label={"Tomar fotografía"} onTap={openCamera} />
        {getDocumentType() == "CEDULA DE IDENTIDAD" &&
          getCountryAbbreviation() == "CO" && (
            <ButtonPickImage setFile={onTakePhoto} />
          )}
      </div>
    );
  };

  const onContinue = () => {
    setIsTakingPhoto(false);
    setIsStepCompleted(false);
    setBase64("");
    navigate(
      isFrontSide && location.state?.documentTypeSelected.double_sided
        ? RoutesList.BackImageDocument
        : RoutesList.ReviewDataIdentity,
      {
        state: {
          countrySelected: location.state?.countrySelected,
          documentTypeSelected: location.state?.documentTypeSelected,
        },
        replace: true,
      }
    );
  };

  const ImagePreview = () => {
    const identityUtils = new IdentityUtils();

    return (
      <div className="verification_identity_screen">
        <h2 onClick={() => setOpenModalError(true)}>
          Parte {isFrontSide ? "frontal" : "posterior"} de identificación{" "}
        </h2>

        <div className="center_text">
          <img src={base64} className="document-image" />
        </div>
        <p
          className="verification-paragraph center_text"
          style={isStepCompleted ? { color: "green" } : {}}
        >
          Revisa que la foto sea clara y legible.
        </p>
        <Button label="Tomar fotografía otra vez" onTap={openCamera} outlined />
        <Button
          label="Continuar"
          onTap={onContinue}
          disabled={
            !isStepCompleted || errorResponse != IdentityVerfErrors.idle
          }
        />
      </div>
    );
  };

  const getComponent = () => {
    if (isTakingPhoto) {
      return <IdentityCamera onCrop={onTakePhoto} isFrontSide={isFrontSide} />;
    } else if (base64 != "") {
      return <ImagePreview />;
    } else {
      return <WelcomeVerificationIdentityContent />;
    }
  };

  return (
    <>
      <img src={IlustrationFailed} style={{ display: "none" }} />

      {getComponent()}

      <Sheet
        isOpen={openModalError}
        onClose={tryAgain}
        springConfig={{
          stiffness: 2000,
          damping: 10,
          mass: 0.1,
        }}
      >
        <Sheet.Container>
          <FinishVerificationIdentity
            isComplete={false}
            error={errorResponse}
            closeModal={tryAgain}
          />
        </Sheet.Container>
      </Sheet>

      <Sheet
        isOpen={openModalErrorTwice}
        onClose={tryAgain}
        springConfig={{
          stiffness: 2000,
          damping: 10,
          mass: 0.1,
        }}
      >
        <div className="modal-full-height">
          <Sheet.Container>
            <ErrorTwiceModal
              closeModal={tryAgain}
              onTakePhoto={(e: string) => {
                appContext.changeContext({
                  hadUsedCamera: true,
                });
                onTakePhoto(e);
              }}
            />
          </Sheet.Container>
        </div>
      </Sheet>

      <input
        type="file"
        accept="image/*"
        capture="environment"
        id="camera_file_input"
        className="camera_file_input"
      />
    </>
  );
}

export default VerificationIdentityImage;
