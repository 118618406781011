/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { UserUtils } from "../../../../utils/user/user_utils";
import useAppContext from "../../../../utils/context/useAppContext/use_app_context";
import IlustrationSuccess from "../../../../assets/ilustrations/verificationfinished.svg";
import { LivenessService } from "../../../../service/liveness/liveness_service";
import { toast } from "react-hot-toast";
import { isDev } from "../../../../utils/devices/devices_utils";

function CompletedLivenessChallenge() {
  const appContext = useAppContext();

  const markAsFinished = async () => {
    appContext.toggleLoading();

    const ws = appContext.state.ws;

    if (ws) {
      ws.send(
        JSON.stringify({
          action: "liveness-challenge-update",
          args: {
            status: "FINISHED",
            user: new UserUtils().getUserId(),
          },
        })
      );
    }
    await new LivenessService().finishProcess();
    appContext.toggleLoading(false);
  };

  useEffect(() => {
    const preventBack = (event: any) => {
      event.preventDefault();
      window.history.pushState("", "", window.location.href);
      window.history.replaceState("", "", window.location.href);
    };

    window.history.pushState("", "", window.location.href);
    window.addEventListener("popstate", preventBack);
    return () => {
      window.removeEventListener("popstate", preventBack);
    };
  }, []);

  useEffect(() => {
    markAsFinished();
  }, []);

  return (
    <div
      className={`verification_identity_screen verification_identity_screen_finished verification_identity_screen_finished_true`}
    >
      <img src={IlustrationSuccess} />

      <h2>¡Muchas gracias!</h2>

      <p>
        Has terminado la verificación facial, ya puedes volver a la aplicación
        de escritorio.
      </p>
    </div>
  );
}

export default CompletedLivenessChallenge;
