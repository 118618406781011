import React from "react";
import Button from "../../../elements/buttons/button";
import { useNavigate } from "react-router-dom";
import StepIndicator from "../../../elements/step_indicator/step_indicator";
import { RoutesList } from "../../../../utils/routes/routes_utils";

interface Props {
  routeNext?: string;
  onTap?: () => void;
  currentStep: number;
  hideSkipInstructions?: boolean;
  stepsAmount?: number;
  submitButton?: boolean;
  disabled?: boolean;
}

function VerificationsFooter(props: Props) {
  const navigate = useNavigate();

  const onNext = () => {
    if (props.onTap) {
      props.onTap();
    } else {
      navigate(props.routeNext || "/");
    }
  };

  return (
    <div className="">
      <StepIndicator
        currentStep={props.currentStep}
        stepsAmount={props.stepsAmount ?? 4}
      />
      <div className="VerificationsFooter">
        <Button
          isSubmit={props.submitButton}
          label="Siguiente"
          disabled={props.disabled}
          width="100%"
          onTap={onNext}
        />
        {props.hideSkipInstructions ? (
          <div />
        ) : (
          <h5
            className="skip"
            onClick={() => navigate(RoutesList.LivenessChallenge)}
          >
            Omitir instructivo
          </h5>
        )}

        <p className="version_number">Versión 32</p>
      </div>
    </div>
  );
}

export default VerificationsFooter;
