import { useContext } from "react";
import AppContext from "../initial/initial_context";

const useAppContext = () => {
  const context = useContext(AppContext);

  const toggleLoading = (value?: boolean) => {
    context.changeContextWithState((prevState: any) => {
      return {
        isLoading: value ?? !prevState.isLoading,
      };
    });
  };

  return { ...context, toggleLoading };
};

export default useAppContext;
