/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Button from "../../components/elements/buttons/button";
import { RoutesList } from "../../utils/routes/routes_utils";
import { useNavigate } from "react-router-dom";
import SummaryItem from "../../components/summary/summary_item";
import AppContext from "../../utils/context/initial/initial_context";
import useAppContext from "../../utils/context/useAppContext/use_app_context";
import { IdentityService } from "../../service/identity/identity_service";
import Sheet from "react-modal-sheet";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Logo from "../../assets/icons/id_logo.png";
import CameraIos from "../../components/cameras/camear_ios";
import LayoutAppLoad from "../../components/layouts/veriifcations_layout/layout_app_load";
import ErrorTwiceModal from "../verifications/verification_identity/finish/error_twice_modal";
import ModalFaceIncorrect from "./modal_face_incorrect";

export function RegisterWelcomeScreen() {
  const navigate = useNavigate();
  const [openModalErrorTwice, setOpenModalErrorTwice] = useState(false);

  return (
    <LayoutAppLoad>
      {" "}
      <div className="intro_verification_identity welcome_to_flow_screen">
        <img
          src={Logo}
          alt="logo"
          onClick={() => setOpenModalErrorTwice(true)}
        />
        <div
          className="header_layout_verification"
          style={{ justifyContent: "initial", marginTop: "20px" }}
        >
          <h3>Bienvenido al centro de verificación de Adamo ID</h3>
        </div>

        <Sheet
          isOpen={openModalErrorTwice}
          onClose={() => setOpenModalErrorTwice(false)}
          springConfig={{
            stiffness: 2000,
            damping: 10,
            mass: 0.1,
          }}
        >
          <div className="modal-full-height">
            <Sheet.Container>
              <ModalFaceIncorrect />
            </Sheet.Container>
          </div>
        </Sheet>

        <p
          className="verification-paragraph"
          style={{ textAlign: "start", marginTop: "0" }}
        >
          A continuación te guiaremos a través de unos simples pasos para
          validar tu identidad.
        </p>

        <div className="summary-container">
          <SummaryItem
            title="Verificación de identidad"
            isCompleted={false}
            disabled={false}
          />
          <SummaryItem
            title="Verificación facial"
            isCompleted={false}
            disabled={true}
          />
        </div>

        <div
          className="footer"
          style={{ position: "absolute", bottom: "5%", width: "83%" }}
        >
          <Button
            label="Comenzar"
            width="100%"
            onTap={() => navigate(RoutesList.IdentityCountry)}
          />
        </div>
      </div>
    </LayoutAppLoad>
  );
}
