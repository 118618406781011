import {
  interfaceLivenessChallengeResponse,
  interfaceLivenessStartChallengeResponse,
} from "../../service/liveness/liveness_service";

export class CameraUtils {
  calculateNewPosition = (
    data:
      | interfaceLivenessChallengeResponse
      | interfaceLivenessStartChallengeResponse
  ) => {
    const x = (data.challengeBoxPosition?.x || 0) * 100;
    const y = (data.challengeBoxPosition?.y || 0) * 100;

    return {
      x,
      y,
    };
  };

  checkCameraPermission = async () => {
    try {
      const mediaDevices = navigator.mediaDevices;
      if (!mediaDevices || !mediaDevices.getUserMedia) {
        // El navegador no admite la API de permisos de la cámara
        console.error("El navegador no admite la API de permisos de la cámara");

        return false;
      }

      const stream = await mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach((track) => track.stop());
      return true;
    } catch (error) {
      console.error("No se pudo acceder a la cámara:", error);
      return false;
    }
  };
}

export interface calculateNewPositionResult {
  x: number;
  y: number;
}
