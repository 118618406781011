/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Button from "../../../components/elements/buttons/button";
import { Navigate } from "react-router-dom";
import { RoutesList } from "../../../utils/routes/routes_utils";
import LayoutVerificationsHeader from "../../../components/layouts/veriifcations_layout/header/layout_verifications_header";
import { VerificationsMode } from "../../../models/verifications/verifications_models";
import Logo from "../../../assets/icons/id_logo.png";
import { useEffect } from "react";
import { LocalStorageItems } from "../../../models/local_storage/local_storage";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import VerificationCancelledModal from "../../../components/modals/verf_cancelled/verification_cancelled_modal";
import useAppContext from "../../../utils/context/useAppContext/use_app_context";
import { toast } from "react-hot-toast";

function EndFlowScreen() {
  const context = useAppContext();

  useEffect(() => {
    context.changeContext({ blockFacialVerf: true });
    localStorage.removeItem(LocalStorageItems.REGISTERMODE);
  }, []);

  return (
    <div>
      <div
        className="center"
        style={{ marginTop: "13vh", marginBottom: "3vh" }}
      >
        <img src={Logo} alt="logo" width={"40%"} />
      </div>
      <p
        className="verification-paragraph center_text"
        style={{ fontSize: "4.5vw", padding: "0 2vw 0 2vw", color: "#2fb4f2" }}
      >
        ¡Muchas gracias, la verificación ha sido exitosa!
      </p>
      <div
        className="footer"
        style={{ position: "absolute", bottom: "5%", width: "90%", left: "5%" }}
      >
        <LayoutVerificationsHeader
          title="Quieres pertenecer a la familia Adamo ID?"
          hideGoBack
          mode={VerificationsMode.facial}
        />
        <Button
          label="Descargar la aplicación"
          width="100%"
          onTap={() =>
            window.open(
              "https://adamo-flutter-deploy-qa.s3.us-east-2.amazonaws.com/AdamoID-qa.apk"
            )
          }
        />
      </div>
    </div>
  );
}

export default EndFlowScreen;
