import React from "react";
import ReactFileReader from "react-file-reader";

function ButtonPickImage(props) {
  return (
    <ReactFileReader
      as="text"
      handleFiles={(e) => {
        const fileExtension = "." + (e.fileList[0].name.split(".").pop() || "");

        props.setFile(e.base64);
      }}
      fileTypes={[".png", ".jpg", ".jpeg"]}
      base64={true}
      multipleFiles={false}
    >
      <label
        htmlFor="actual-btn"
        className="button button_outlined_true prevent-select"
        style={{ width: "96%", display: "block" }}
      >
        Sube un archivo desde tu dispositivo
      </label>
    </ReactFileReader>
  );
}

export default ButtonPickImage;
