import { State } from "./address_service";

export const argentinaCities: State[] = [
  {
    name: "Buenos Aires",
    alias: ["Buenos Aires", "CABA", "Capital Federal"],
    municipalities: [
      "Adolfo Alsina",
      "Alberti",
      "Almirante Brown",
      "Avellaneda",
      "Ayacucho",
      "Azul",
      "Bahía Blanca",
      "Balcarce",
      "Baradero",
      "Benito Juárez",
      "Berazategui",
      "Berisso",
      "Bolívar",
      "Bragado",
      "Brandsen",
      "Campana",
      "Cañuelas",
      "Capitán Sarmiento",
      "Carlos Casares",
      "Carlos Tejedor",
      "Carmen de Areco",
      "Castelli",
      "Chacabuco",
      "Chascomús",
      "Chivilcoy",
      "Coronel Dorrego",
      "Coronel Pringles",
      "Coronel Rosales",
      "Coronel Suárez",
      "Daireaux",
      "Dolores",
      "Ensenada",
      "Escobar",
      "Esteban Echeverría",
      "Exaltación de la Cruz",
      "Ezeiza",
      "Florencio Varela",
      "Florentino Ameghino",
      "General Alvarado",
      "General Alvear",
      "General Arenales",
      "General Belgrano",
      "General Guido",
      "General La Madrid",
      "General Las Heras",
      "General Lavalle",
      "General Madariaga",
      "General Paz",
      "General Pinto",
      "General Pueyrredón",
      "General Rodríguez",
      "General San Martín",
      "General Viamonte",
      "General Villegas",
      "General Juan Madariaga",
      "Guaminí",
      "Hipólito Yrigoyen",
      "Hurlingham",
      "Ituzaingó",
      "José C. Paz",
      "Junín",
      "La Matanza",
      "La Plata",
      "Lanús",
      "La Costa",
      "Leandro N. Alem",
      "Lincoln",
      "Lobería",
      "Lobos",
      "Lomas de Zamora",
      "Luján",
      "Magdalena",
      "Maipú",
      "Malvinas Argentinas",
      "Mar Chiquita",
      "Marcos Paz",
      "Mercedes",
      "Merlo",
      "Monte",
      "Monte Hermoso",
      "Moreno",
      "Morón",
      "Navarro",
      "Necochea",
      "Nueve de Julio",
      "Olavarría",
      "Patagones",
      "Pehuajó",
      "Pellegrini",
      "Pergamino",
      "Pila",
      "Pilar",
      "Pinamar",
      "Presidente Perón",
      "Puan",
      "Punta Indio",
      "Quilmes",
      "Ramallo",
      "Rauch",
      "Rivadavia",
      "Rojas",
      "Roque Pérez",
      "Saavedra",
      "Saladillo",
      "Salliqueló",
      "Salto",
      "San Andrés de Giles",
      "San Antonio de Areco",
      "San Fernando",
      "San Isidro",
      "San Miguel",
      "San Nicolás",
      "San Pedro",
      "San Vicente",
      "Suipacha",
      "Tandil",
      "Tapalqué",
      "Tigre",
      "Tordillo",
      "Tornquist",
      "Trenque Lauquen",
      "Tres Arroyos",
      "Tres de Febrero",
      "Tres Lomas",
      "Vicente López",
      "Villa Gesell",
      "Villarino",
      "Urdampilleta",
      "Zárate",
    ],
  },
  {
    name: "Catamarca",
    alias: ["Catamarca", "CAT"],
    municipalities: [
      "Ambato",
      "Ancasti",
      "Andalgalá",
      "Antofagasta de la Sierra",
      "Belén",
      "Capayán",
      "Capital",
      "Santa María",
      "Belén",
      "La Paz",
      "Paclín",
      "Pomán",
      "Santa María",
      "Santa Rosa",
      "Tinogasta",
      "Valle Viejo",
    ],
  },
  {
    name: "Chaco",
    alias: ["Chaco", "CHA"],
    municipalities: [
      "Avia Terai",
      "Barranqueras",
      "Basail",
      "Campo Largo",
      "Capitán Solari",
      "Charata",
      "Colonia Elisa",
      "Colonia Popular",
      "Colonias Unidas",
      "Concepción del Bermejo",
      "Corzuela",
      "Cote Lai",
      "El Espinillo",
      "El Sauzalito",
      "Enrique Urien",
      "Fontana",
      "Fuerte Esperanza",
      "Gancedo",
      "General Capdevila",
      "General Pinedo",
      "General Vedia",
      "Hermoso Campo",
      "Isla del Cerrito",
      "Juan José Castelli",
      "La Clotilde",
      "La Eduvigis",
      "La Escondida",
      "La Leonesa",
      "La Tigra",
      "La Verde",
      "Laguna Blanca",
      "Lapachito",
      "Las Garcitas",
      "Las Palmas",
      "Los Frentones",
      "Machagai",
      "Makallé",
      "Margarita Belén",
      "Miraflores",
      "Napenay",
      "Nueva Pompeya",
      "Pampa Almirón",
      "Pampa del Indio",
      "Pampa del Infierno",
      "Pampa del Malleo",
      "Pampa del Zorro",
      "Paso de la Patria",
      "Pampa Pozo",
      "Pampa Tardes",
      "Presidencia de la Plaza",
      "Presidencia Roca",
      "Presidencia Roque Sáenz Peña",
      "Puerto Bermejo",
      "Puerto Eva Perón",
      "Puerto Tirol",
      "Puerto Vilelas",
      "Quitilipi",
      "Resistencia",
      "Río Muerto",
      "Samuhú",
      "San Bernardo",
      "Santa Sylvina",
      "Taco Pozo",
      "Tres Isletas",
      "Villa Ángela",
      "Villa Berthet",
      "Villa Río Bermejito",
    ],
  },
  {
    name: "Chubut",
    alias: ["Chubut", "CHU"],
    municipalities: [
      "Aldea Apeleg",
      "Aldea Beleiro",
      "Aldea Epulef",
      "Alto Río Senguer",
      "Atilio Viglione",
      "Buen Pasto",
      "Camarones",
      "Carrenleufú",
      "Cholila",
      "Co. Centinela",
      "Colan Conhué",
      "Comodoro Rivadavia",
      "Corcovado",
      "Cushamen",
      "Dique F. Ameghino",
      "Dolavón",
      "El Hoyo",
      "El Maitén",
      "Epuyén",
      "Esquel",
      "Facundo",
      "Gaiman",
      "Gan Gan",
      "Gastre",
      "Gobernador Costa",
      "José de San Martín",
      "Lago Blanco",
      "Lago Puelo",
      "Lagunita Salada",
      "Las Plumas",
      "Los Altares",
      "Paso de los Indios",
      "Paso del Sapo",
      "Puerto Madryn",
      "Rada Tilly",
      "Rawson",
      "Río Mayo",
      "Río Pico",
      "Sarmiento",
      "Tecka",
      "Telsen",
      "Trelew",
      "Trevelin",
      "Veintiocho de Julio",
    ],
  },
  {
    name: "Misiones",
    alias: ["Misiones", "MIS"],
    municipalities: [
      "Alba Posse",
      "Almafuerte",
      "Apóstoles",
      "Aristóbulo del Valle",
      "Arroyo del Medio",
      "Azara",
      "Bernardo de Irigoyen",
      "Bonpland",
      "Caá Yarí",
      "Campo Grande",
      "Campo Ramón",
      "Campo Viera",
      "Candelaria",
      "Capioví",
      "Caraguatay",
      "Cerro Azul",
      "Cerro Corá",
      "Colonia Alberdi",
      "Colonia Aurora",
      "Colonia Delicia",
      "Colonia Polana",
      "Colonia Victoria",
      "Colonia Wanda",
      "Concepción de la Sierra",
      "Corpus",
      "Dos de Mayo",
      "El Alcázar",
      "El Soberbio",
      "Esperanza",
      "Fachinal",
      "Garuhapé",
      "Garupá",
      "General Alvear",
      "General Urquiza",
      "Gobernador López",
      "Gobernador Roca",
      "Gobernador Virasoro",
      "Guaraní",
      "Herradura",
      "Jardín América",
      "Leandro N. Alem",
      "Libertad",
      "Loreto",
      "Los Helechos",
      "Martín de Tours",
      "Mártires",
      "Mojón Grande",
      "Montecarlo",
      "Nueva Esperanza",
      "Oberá",
      "Olegario Víctor Andrade",
      "Panambí",
      "Posadas",
      "Profundidad",
      "Pueblo Liebig",
      "Puerto Esperanza",
      "Puerto Iguazú",
      "Puerto Leoni",
      "Puerto Libertad",
      "Puerto Piray",
      "Puerto Rico",
      "Ruiz de Montoya",
      "San Antonio",
      "San Ignacio",
      "San Javier",
      "San José",
      "San Martín",
      "San Pedro",
      "San Vicente",
      "Santa Ana",
      "Santa María",
      "Santiago de Liniers",
      "Santo Pipó",
      "Tres Capones",
      "Veinticinco de Mayo",
      "Wanda",
    ],
  },
  {
    name: "Tierra del Fuego",
    alias: ["Tierra del Fuego", "TFG"],
    municipalities: ["Río Grande", "Ushuaia", "Tolhuin"],
  },
  {
    name: "Entre Ríos",
    alias: ["Entre Ríos", "ER"],
    municipalities: [
      "Paraná",
      "Concepción del Uruguay",
      "Gualeguaychú",
      "Concordia",
      "Victoria",
      "Villaguay",
      "Gualeguay",
      "La Paz",
      "Colón",
      "Federación",
      "Tala",
      "Nogoyá",
      "San Salvador",
      "Diamante",
      "Uruguay",
      "Federal",
      "Villaguay",
      "San José de Feliciano",
      "Feliciano",
      "Victoria",
    ],
  },
  {
    name: "La Pampa",
    alias: ["La Pampa"],
    municipalities: [
      "Santa Rosa",
      "General Pico",
      "Toay",
      "General Acha",
      "Victorica",
      "Macachín",
      "Eduardo Castex",
      "Santa Isabel",
      "Lonquimay",
      "Realicó",
      "25 de Mayo",
      "Catriló",
      "Trenel",
      "Guatraché",
      "Jacinto Aráuz",
      "General San Martín",
      "Parera",
      "Alpachiri",
      "Bernasconi",
      "Colonia Santa María",
      "Mauricio Mayer",
      "Quehué",
      "Rancul",
      "La Adela",
      "Telén",
      "Winifreda",
      "Ingeniero Luiggi",
      "Puelén",
      "Gobernador Duval",
      "Loventuel",
      "Limay Mahuida",
      "Doblas",
      "Unanué",
      "Abramo",
      "General Manuel J. Campos",
      "Agustoni",
      "La Maruja",
      "La Reforma",
      "Ataliva Roca",
      "Metileo",
      "La Humada",
      "Algarrobo del Águila",
      "Miguel Riglos",
    ],
  },
  {
    name: "Córdoba",
    alias: ["Córdoba", "COR"],
    municipalities: [
      "Córdoba",
      "Villa Carlos Paz",
      "Río Cuarto",
      "Alta Gracia",
      "San Francisco",
      "Villa María",
      "Jesús María",
      "Río Tercero",
      "Cosquín",
      "La Calera",
      "Bell Ville",
      "Villa Allende",
      "Laboulaye",
      "Marcos Juárez",
      "Mina Clavero",
      "Arroyito",
      "Villa Dolores",
      "Deán Funes",
      "Villa del Rosario",
      "Río Segundo",
      "Villa Nueva",
      "Rio Ceballos",
      "Morteros",
      "San José de la Dormida",
      "Río Ceballos",
      "Villa Huidobro",
      "Río Primero",
      "Villa Giardino",
      "Capilla del Monte",
      "Bell Ville",
      "Villa Dolores",
      "San Francisco del Chañar",
      "Oliva",
      "La Falda",
      "San Marcos Sierras",
      "Colonia Caroya",
      "Villa General Belgrano",
      "Villa Carlos Paz",
      "Villa de María del Río Seco",
      "Alta Gracia",
      "La Puerta",
      "Villa de Soto",
      "Los Cóndores",
      "Pilar",
      "Valle Hermoso",
      "Villa María",
      "Río Tercero",
      "Villa Quillinzo",
      "Villa Yacanto",
      "Tanti",
      "Río Ceballos",
      "La Paz",
      "San Esteban",
      "Las Varillas",
      "La Playosa",
      "Río Tercero",
      "Santa María de Punilla",
      "Villa de Las Rosas",
      "San Basilio",
      "Río Cuarto",
      "Salsipuedes",
      "Colonia Tirolesa",
      "Etruria",
      "Morrison",
      "Marcos Juárez",
      "Serrezuela",
      "Laguna Larga",
      "Los Surgentes",
      "Villa de María",
      "La Cumbre",
      "Berrotarán",
      "General Deheza",
      "La Para",
      "La Carlota",
      "Monte Buey",
      "Corral de Bustos",
      "Cavanagh",
      "Villa del Rosario",
      "Obispo Trejo",
      "Villa Los Aromos",
      "Santa María de Punilla",
      "Villa Sarmiento",
      "Villa Tulumba",
      "Villa Fontana",
      "Río Tercero",
      "Calamuchita",
      "Villa Cura Brochero",
      "James Craik",
      "Achiras",
      "Alcira Gigena",
      "Tancacha",
      "Alto Alegre",
      "General Cabrera",
      "Córdoba",
      "Bell Ville",
      "Colonia Tirolesa",
      "San Francisco del Chañar",
      "Villa Huidobro",
      "Río Segundo",
      "Santa Rosa de Calamuchita",
      "Río Tercero",
      "General Deheza",
      "Villa María",
      "Cruz del Eje",
      "Oliva",
      "El Arañado",
      "Hernando",
      "Mina Clavero",
      "Luque",
      "La Calera",
      "Las Higueras",
      "Villa de Las Rosas",
      "Alcira Gigena",
      "Río Primero",
      "Colonia Caroya",
      "Monte Maíz",
      "Cruz del Eje",
      "Sinsacate",
      "Bell Ville",
      "Colonia Tirolesa",
      "La Playosa",
      "Río Tercero",
      "Pilar",
      "Villa de Soto",
      "San Esteban",
      "Malagueño",
      "La Paz",
      "Pampayasta",
      "Villa Los Aromos",
      "Los Zorros",
      "Cavanagh",
      "La Puerta",
      "La Falda",
      "Los Surgentes",
      "Arroyo Cabral",
      "Villa Allende",
      "San Marcos Sierras",
      "General Levalle",
      "La Playa",
      "San Basilio",
      "Alta Gracia",
      "Río Tercero",
      "Tancacha",
      "Córdoba",
      "Despeñaderos",
      "Calchín",
      "Villa Quillinzo",
      "Santa Rosa de Río Primero",
      "Río Ceballos",
      "Villa General Belgrano",
      "Etruria",
      "Capilla del Monte",
      "Villa María",
      "Morrison",
      "San Agustín",
      "Villa Rumipal",
      "Serrezuela",
      "El Rastreador",
      "Villa Cura Brochero",
      "La Cumbre",
      "Chazón",
      "Corral de Bustos",
      "San José de la Dormida",
      "Córdoba",
      "Villa de María del Río Seco",
      "Corralito",
      "San Francisco",
      "Río Ceballos",
      "Colonia Vicente Agüero",
      "Adelia María",
      "Morteros",
      "Córdoba",
      "Alta Gracia",
      "Mina Clavero",
      "La Calera",
      "Los Reartes",
      "San Agustín",
      "Río Cuarto",
      "La Granja",
      "Las Higueras",
      "Monte Buey",
      "Villa Cura Brochero",
      "Bell Ville",
      "Cruz Alta",
      "Despeñaderos",
      "San Marcos Sierras",
      "Río Tercero",
      "Pampayasta",
      "Santa María de Punilla",
      "Villa Los Aromos",
      "La Francia",
      "Los Zorros",
      "Oliva",
      "Unquillo",
      "San Marcos Sierras",
      "General Cabrera",
      "Malvinas Argentinas",
      "Corral de Bustos",
      "Villa Giardino",
      "Villa del Rosario",
      "Villa Quillinzo",
      "Morrison",
      "Río Primero",
      "Santa Rosa de Calamuchita",
      "Corralito",
      "El Rastreador",
      "La Playosa",
      "Mina Clavero",
      "Villa María",
      "Sinsacate",
      "San José de la Dormida",
      "Río Tercero",
      "Colonia Caroya",
      "La Calera",
      "Río Cuarto",
      "San Francisco",
      "Colonia Vicente Agüero",
      "Villa Carlos Paz",
      "Oliva",
      "Villa del Totoral",
      "Villa Huidobro",
      "San Esteban",
      "Villa Tulumba",
      "Villa Dolores",
      "Los Cóndores",
      "Etruria",
      "Berrotarán",
      "La Playa",
      "Los Reartes",
      "Villa Carlos Paz",
      "Villa Allende",
      "Villa de María del Río Seco",
      "Las Perdices",
      "Río Ceballos",
      "Villa Rumipal",
      "Villa Nueva",
      "Tancacha",
      "Villa de Las Rosas",
      "Despeñaderos",
      "Santa Rosa de Río Primero",
      "Marcos Juárez",
      "La Puerta",
      "Corral de Bustos",
      "Alta Gracia",
      "Colonia Tirolesa",
      "Villa de Soto",
      "Alta Gracia",
      "Monte Maíz",
      "Río Segundo",
      "Las Higueras",
      "Corralito",
      "Oliva",
      "Colonia Tirolesa",
      "Villa de María",
      "Córdoba",
      "Villa Quillinzo",
      "Colonia Vicente Agüero",
      "Bell Ville",
      "La Francia",
      "Los Zorros",
      "Villa Allende",
      "Santa María de Punilla",
      "Los Cóndores",
      "Morrison",
      "Malagueño",
      "Villa María",
      "Unquillo",
      "La Cumbre",
      "Colonia Caroya",
      "San Francisco del Chañar",
      "Las Perdices",
      "Villa de Soto",
      "Corral de Bustos",
      "San Marcos Sierras",
      "Villa Dolores",
      "San Marcos Sierras",
      "Villa General Belgrano",
      "Río Tercero",
      "Villa Yacanto",
      "Monte Buey",
      "Río Ceballos",
      "Corralito",
      "Río Cuarto",
      "Villa de María del Río Seco",
      "La Playosa",
      "Río Tercero",
      "Cruz del Eje",
      "Despeñaderos",
      "Alcira Gigena",
      "Marcos Juárez",
      "Río Tercero",
      "San Esteban",
      "Colonia Vicente Agüero",
      "Villa del Rosario",
      "Mina Clavero",
      "Villa Rumipal",
      "La Puerta",
      "La Falda",
      "Morteros",
      "Colonia Caroya",
      "San Marcos Sierras",
      "San José de la Dormida",
      "Córdoba",
      "Villa Los Aromos",
      "El Rastreador",
      "Villa Dolores",
      "Cruz del Eje",
      "Las Varillas",
      "Santa María de Calamuchita",
      "Villa María",
      "Río Tercero",
      "Río Ceballos",
      "Bell Ville",
      "Villa del Rosario",
      "Sinsacate",
      "Río Segundo",
      "Villa Carlos Paz",
      "Villa General Belgrano",
      "Unquillo",
      "Malagueño",
      "Villa del Totoral",
      "Villa Huidobro",
      "La Carlota",
      "Marcos Juárez",
      "Villa de Las Rosas",
      "Alta Gracia",
      "Río Primero",
      "Cosquín",
      "Río Ceballos",
      "La Falda",
      "General Deheza",
      "La Paz",
      "Villa de María",
      "Los Surgentes",
      "Alta Gracia",
      "Villa Los Aromos",
      "Colonia Vicente Agüero",
      "Colonia Tirolesa",
      "Santa Rosa de Calamuchita",
      "Corral de Bustos",
      "Villa María",
      "Tanti",
      "Villa de Soto",
      "Alcira Gigena",
      "Villa Nueva",
      "Villa Carlos Paz",
      "Villa Dolores",
      "Río Cuarto",
      "Salsipuedes",
      "Villa Giardino",
      "Villa Carlos Paz",
      "La Playa",
      "Bell Ville",
      "Villa Huidobro",
      "La Falda",
      "Villa Dolores",
      "Morteros",
      "Villa General Belgrano",
      "Santa Rosa de Río Primero",
      "Río Ceballos",
      "Marcos Juárez",
      "San Francisco",
      "Río Segundo",
      "La Puerta",
      "Villa María",
      "Colonia Caroya",
      "Corralito",
      "Las Varillas",
      "Cruz del Eje",
      "Monte Maíz",
      "Cosquín",
      "Malagueño",
      "Río Tercero",
      "Alta Gracia",
      "Tancacha",
      "Unquillo",
      "Río Cuarto",
      "Villa Carlos Paz",
      "Villa María",
      "Bell Ville",
      "Villa Huidobro",
      "Villa Dolores",
      "La Calera",
      "San Francisco",
      "Río Tercero",
      "Cosquín",
      "La Falda",
      "Villa Allende",
      "Laboulaye",
      "Marcos Juárez",
      "Mina Clavero",
      "Arroyito",
      "Villa Dolores",
      "Deán Funes",
      "Villa del Rosario",
      "Río Segundo",
      "Villa Nueva",
      "Rio Ceballos",
      "Morteros",
      "San José de la Dormida",
      "Río Ceballos",
      "Villa Huidobro",
      "Río Primero",
      "Villa Giardino",
      "Capilla del Monte",
      "Bell Ville",
      "Villa Dolores",
      "San Francisco del Chañar",
      "Oliva",
      "La Falda",
      "San Marcos Sierras",
      "Colonia Caroya",
      "Villa General Belgrano",
      "Villa Carlos Paz",
      "Villa de María del Río Seco",
      "Alta Gracia",
      "La Puerta",
      "Villa de Soto",
      "Los Cóndores",
      "Pilar",
      "Valle Hermoso",
      "Villa María",
      "Río Tercero",
      "Villa Quillinzo",
      "Villa Yacanto",
      "Tanti",
      "Río Ceballos",
      "La Paz",
      "San Esteban",
      "Las Varillas",
      "La Playosa",
      "Río Tercero",
      "Santa María de Punilla",
      "Villa de Las Rosas",
      "San Basilio",
      "Río Cuarto",
      "Salsipuedes",
      "Colonia Tirolesa",
      "Etruria",
      "Morrison",
      "Marcos Juárez",
      "Serrezuela",
      "Laguna Larga",
      "Los Surgentes",
      "Villa de María",
      "La Cumbre",
      "Berrotarán",
      "General Deheza",
      "La Para",
      "La Carlota",
      "Monte Buey",
      "Corral de Bustos",
      "Cavanagh",
      "Villa del Rosario",
      "Obispo Trejo",
      "Villa Los Aromos",
      "Santa María de Punilla",
      "Villa Sarmiento",
      "Villa Tulumba",
      "Villa Fontana",
      "Río Tercero",
      "Calamuchita",
      "Villa Cura Brochero",
      "James Craik",
      "Achiras",
      "Alcira Gigena",
      "Tancacha",
      "Alto Alegre",
      "General Cabrera",
      "Córdoba",
      "Bell Ville",
      "Colonia Tirolesa",
      "San Francisco del Chañar",
      "Villa Huidobro",
      "Río Segundo",
      "Santa Rosa de Calamuchita",
      "Río Tercero",
      "General Deheza",
      "Villa María",
      "Cruz del Eje",
      "Oliva",
      "El Arañado",
      "Hernando",
      "Mina Clavero",
      "Luque",
      "La Calera",
      "Las Higueras",
      "Villa de Las Rosas",
      "Alcira Gigena",
      "Río Primero",
      "Colonia Caroya",
      "Monte Maíz",
      "Cruz del Eje",
      "Sinsacate",
      "Bell Ville",
      "Colonia Tirolesa",
      "La Playosa",
      "Río Tercero",
      "Pilar",
      "Villa de Soto",
      "San Esteban",
      "Malagueño",
      "La Paz",
      "Pampayasta",
      "Villa Los Aromos",
      "Los Zorros",
      "Cavanagh",
      "La Puerta",
      "La Falda",
      "Los Surgentes",
      "Arroyo Cabral",
      "Villa Allende",
      "San Marcos Sierras",
      "General Levalle",
      "La Playa",
      "San Basilio",
      "Alta Gracia",
      "Río Tercero",
      "Tancacha",
      "Córdoba",
      "Despeñaderos",
      "Calchín",
      "Villa Quillinzo",
      "Santa Rosa de Río Primero",
      "Río Ceballos",
      "Villa General Belgrano",
      "Etruria",
      "Capilla del Monte",
      "Villa María",
      "Morrison",
      "San Agustín",
      "Villa Rumipal",
      "Serrezuela",
      "El Rastreador",
      "Villa Cura Brochero",
      "La Cumbre",
      "Chazón",
      "Corral de Bustos",
      "San José de la Dormida",
      "Córdoba",
      "Villa de María del Río Seco",
      "Corralito",
      "San Francisco",
      "Río Ceballos",
      "Colonia Vicente Agüero",
      "Adelia María",
      "Morteros",
      "Córdoba",
      "Alta Gracia",
      "Mina Clavero",
      "La Calera",
      "Los Reartes",
      "San Agustín",
      "Río Cuarto",
      "La Granja",
      "Las Higueras",
      "Monte Buey",
      "Villa Cura Brochero",
      "Bell Ville",
      "Cruz Alta",
      "Despeñaderos",
      "San Marcos Sierras",
      "Río Tercero",
      "Pampayasta",
      "Santa María de Punilla",
      "Villa Los Aromos",
      "La Francia",
      "Los Zorros",
      "Oliva",
      "Unquillo",
      "San Marcos Sierras",
      "General Cabrera",
      "Malvinas Argentinas",
      "Corral de Bustos",
      "Villa Giardino",
      "Villa del Rosario",
      "Villa Quillinzo",
      "Morrison",
      "Río Primero",
      "Santa Rosa de Calamuchita",
      "Corralito",
      "El Rastreador",
    ],
  },
  {
    name: "Santa Fe",
    alias: ["Santa Fe", "SF"],
    municipalities: [
      "Rosario",
      "Santa Fe",
      "Venado Tuerto",
      "Rosario del Tala",
      "Rafaela",
      "San Lorenzo",
      "Reconquista",
      "Casilda",
      "Santo Tomé",
      "Esperanza",
      "Villa Gobernador Gálvez",
      "San Justo",
      "Funes",
      "Villa Constitución",
      "Granadero Baigorria",
      "Firmat",
      "Arroyo Seco",
      "Cañada de Gómez",
      "Capitán Bermúdez",
      "Gálvez",
      "Roldán",
      "Villa Ocampo",
      "Pérez",
      "Vera",
      "San Jorge",
      "Totoras",
      "Sunchales",
      "Suardi",
      "Avellaneda",
      "Villa Cañas",
      "Rosario de Lerma",
      "Carcaraná",
      "Villa Eloísa",
      "Coronda",
      "Villaguay",
      "Villa Trinidad",
      "Las Rosas",
      "San Javier",
      "San Cristóbal",
      "San Guillermo",
    ],
  },
  {
    name: "Santa Cruz",
    alias: ["Santa Cruz", "SC"],
    municipalities: [
      "28 de Noviembre",
      "Caleta Olivia",
      "El Calafate",
      "El Chaltén",
      "Gobernador Gregores",
      "Hipólito Yrigoyen",
      "Jaramillo",
      "Koluel Kaike",
      "Las Heras",
      "Los Antiguos",
      "Perito Moreno",
      "Pico Truncado",
      "Puerto Deseado",
      "Puerto San Julián",
      "Puerto Santa Cruz",
      "Río Gallegos",
      "Río Turbio",
      "Tres Lagos",
      "Veintiocho de Noviembre",
    ],
  },
  {
    name: "Mendoza",
    alias: ["Mendoza", "MDZ"],
    municipalities: [
      "Ciudad de Mendoza",
      "Godoy Cruz",
      "Las Heras",
      "Guaymallén",
      "Maipú",
      "Luján de Cuyo",
      "San Rafael",
      "San Martín",
      "Junín",
      "Rivadavia",
      "Tunuyán",
      "San Carlos",
      "Malargüe",
      "La Paz",
      "General Alvear",
      "Lavalle",
      "Santa Rosa",
      "San Juan",
      "Capital",
      "La Unión",
    ],
  },
  {
    name: "Salta",
    alias: ["Salta", "SAL"],
    municipalities: [
      "Ciudad de Salta",
      "San Ramón de la Nueva Orán",
      "San Salvador de Jujuy",
      "Tartagal",
      "San Lorenzo",
      "Cafayate",
      "General Güemes",
      "Metán",
      "Rosario de Lerma",
      "Aguaray",
      "Embarcación",
      "Cerrillos",
      "Joquicingo",
      "La Caldera",
      "El Bordo",
      "San Antonio de los Cobres",
      "Chicoana",
      "Campo Quijano",
      "Santa Victoria Este",
      "Vaqueros",
      "La Merced",
      "Chilecito",
      "Cachi",
      "Rosario de la Frontera",
      "Coronel Moldes",
      "Cerrillos",
    ],
  },
  {
    name: "Tucumán",
    alias: ["Tucumán", "TUC"],
    municipalities: [
      "San Miguel de Tucumán",
      "Yerba Buena",
      "Concepción",
      "Agustín de la Vega",
      "Banda del Río Salí",
      "Famaillá",
      "Tafí Viejo",
      "Ranchillos",
      "Bella Vista",
      "Alderetes",
      "Simoca",
      "Lules",
      "Acheral",
      "Monteros",
      "Trancas",
      "Tafí del Valle",
      "Juan Bautista Alberdi",
      "Río Seco",
      "Leales",
      "Villa Quinteros",
      "Siete de Abril",
      "Alpachiri",
      "Burruyacú",
      "Los Sarmientos",
      "La Cocha",
      "El Cadillal",
      "Bajada del Tafí",
      "Delfín Gallo",
      "La Reducción",
    ],
  },
  {
    name: "Jujuy",
    alias: ["Jujuy", "JUJ"],
    municipalities: [
      "San Salvador de Jujuy",
      "San Pedro",
      "Palpalá",
      "San Antonio",
      "Libertador General San Martín",
      "Perico",
      "La Quiaca",
      "Tilcara",
      "Humahuaca",
      "El Carmen",
      "Abra Pampa",
      "San Pedro",
      "La Mendieta",
      "El Piquete",
      "Calilegua",
      "La Esperanza",
      "Fraile Pintado",
      "La Voluntad",
      "Purmamarca",
      "Rodeíto",
      "Vinalito",
      "Tres Cruces",
      "Caimancito",
      "El Aguilar",
      "Maimará",
      "Yala",
      "San Francisco",
      "Santa Catalina",
      "Barrios",
      "Puesto Viejo",
      "Tumbaya",
      "Valle Colorado",
      "Tilcara",
      "El Moreno",
      "La Intermedia",
      "Valle Grande",
    ],
  },
  {
    name: "Neuquén",
    alias: ["Neuquén", "NEU"],
    municipalities: [
      "Neuquén",
      "San Martín de los Andes",
      "Zapala",
      "Cutral Có",
      "Plottier",
      "Junín de los Andes",
      "Rincón de los Sauces",
      "Chos Malal",
      "Loncopué",
      "Aluminé",
      "Villa La Angostura",
      "Senillosa",
      "Centenario",
      "Villa El Chocón",
      "Añelo",
      "Las Lajas",
      "Picún Leufú",
      "Buta Ranquil",
      "El Huecú",
      "Piedra del Águila",
      "Las Coloradas",
      "Caviahue",
      "El Cholar",
      "Sauzal Bonito",
      "Andacollo",
      "Vista Alegre",
      "Huinganco",
      "Chorriaca",
      "Los Miches",
      "Las Ovejas",
    ],
  },
  {
    name: "Corrientes",
    alias: ["Corrientes", "COR"],
    municipalities: [
      "Corrientes",
      "Goya",
      "Mercedes",
      "Curuzú Cuatiá",
      "Esquina",
      "Bella Vista",
      "Paso de los Libres",
      "Monte Caseros",
      "Santo Tomé",
      "Ituzaingó",
      "Sauce",
      "Empedrado",
      "San Cosme",
      "San Luis del Palmar",
      "Mocoretá",
      "Santa Lucía",
      "Saladas",
      "Virasoro",
      "La Cruz",
      "Alvear",
      "Bella Vista",
      "Beron de Astrada",
      "San Roque",
      "Yapeyú",
      "Caá Catí",
      "San Miguel",
      "San Lorenzo",
      "Juan Pujol",
      "Lomas de Vallejos",
      "La Verde",
      "Pueblo Libertador",
      "Itá Ibaté",
      "Colonia Liebig",
      "Nuestra Señora del Rosario",
      "Ramada Paso",
      "Batea Mahuida",
      "Itatí",
    ],
  },
  {
    name: "Río Negro",
    alias: ["Río Negro", "RN"],
    municipalities: [
      "Viedma",
      "General Roca",
      "San Carlos de Bariloche",
      "Cipolletti",
      "Catriel",
      "Allen",
      "Villa Regina",
      "Cinco Saltos",
      "Carmen de Patagones",
      "El Bolsón",
      "Choele Choel",
      "Río Colorado",
      "Fernández Oro",
      "Villa Los Coihues",
      "Cervantes",
      "Ingeniero Jacobacci",
      "Chimpay",
      "Sierra Colorada",
      "Darwin",
      "El Caín",
      "El Cuy",
      "Los Menucos",
      "Mainqué",
      "Ñorquinco",
      "Paso Córdoba",
      "Paso de Agua",
      "Paso Flores",
      "Paso Limay",
      "Sierra Grande",
      "Pilcaniyeu",
      "San Antonio Oeste",
      "Valcheta",
      "Valle Azul",
      "Villa Llanquín",
      "Villa Mascardi",
    ],
  },
  {
    name: "San Juan",
    alias: ["San Juan", "SJ"],
    municipalities: [
      "25 de Mayo",
      "9 de Julio",
      "Albardón",
      "Angaco",
      "Calingasta",
      "Capital",
      "Caucete",
      "Chimbas",
      "Iglesia",
      "Jáchal",
      "Nueve de Julio",
      "Pocito",
      "Rawson",
      "Rivadavia",
      "San Juan",
      "San Martín",
      "Santa Lucía",
      "Sarmiento",
      "Ullum",
      "Valle Fértil",
      "Veinticinco de Mayo",
      "Zonda",
    ],
  },
  {
    name: "San Luis",
    alias: ["San Luis", "SL"],
    municipalities: [
      "San Luis",
      "Villa Mercedes",
      "San Francisco",
      "La Punta",
      "Villa de la Quebrada",
      "El Trapiche",
      "El Volcán",
      "Juana Koslay",
      "La Toma",
      "La Florida",
      "La Calera",
      "La Carolina",
      "Lafinur",
      "Lavaisse",
      "Balde",
      "Nogolí",
      "Quines",
      "Renca",
      "Saladillo",
      "San Martín",
      "San Pablo",
      "Santa Rosa del Conlara",
      "Talita",
      "Tilisarao",
      "Unión",
      "Villa de Praga",
      "Villa del Carmen",
      "Villa General Roca",
      "Villa Larca",
    ],
  },
  {
    name: "Formosa",
    alias: ["Formosa"],
    municipalities: [
      "Formosa",
      "Pirané",
      "Clorinda",
      "Las Lomitas",
      "Mayor Vicente Villafañe",
      "El Colorado",
      "Ibarreta",
      "San Martín 2",
      "Villa General Güemes",
      "Herradura",
      "San Hilario",
      "Subteniente Perín",
      "Riacho He-He",
      "Gran Guardia",
      "Misión Tacaaglé",
      "La Primavera",
      "Comandante Fontana",
      "Los Chiriguanos",
      "Laguna Blanca",
      "Fortín Sargento 1º Leyes",
      "Misión Laishí",
      "Laguna Yema",
      "Posta Cambio Zalazar",
      "General Lucio V. Mansilla",
      "Lote 8",
    ],
  },
  {
    name: "La Rioja",
    alias: ["La Rioja"],
    municipalities: [
      "La Rioja",
      "Chilecito",
      "Arauco",
      "Capital",
      "Chamical",
      "Famatina",
      "Coronel Felipe Varela",
      "General Ángel Vicente Peñaloza",
      "General Belgrano",
      "General Juan Facundo Quiroga",
      "General Lamadrid",
      "General Ocampo",
      "General San Martín",
      "Independencia",
      "Rosario Vera Peñaloza",
      "San Blas de los Sauces",
      "Sanagasta",
      "Vinchina",
      "Villa Castelli",
    ],
  },
  {
    name: "Santiago del Estero",
    alias: ["Santiago del Estero"],
    municipalities: [
      "Santiago del Estero",
      "La Banda",
      "Florencia",
      "Clodomira",
      "Añatuya",
      "Termas de Río Hondo",
      "Loreto",
      "Frías",
      "Santiago del Estero",
      "Monte Quemado",
      "Suncho Corral",
      "Selva",
      "Pinto",
      "Fernández",
      "Villa Atamisqui",
      "San Pedro",
      "Pozo Hondo",
      "Forres",
      "Icaño",
      "San Francisco del Rincón",
      "El Bobadal",
      "Lugones",
      "Nueva Esperanza",
      "Brea Pozo",
      "Árraga",
      "Villa Río Hondo",
      "Pampa de los Guanacos",
      "Salavina",
      "Tintina",
      "Estación Robles",
      "San José",
      "Garza",
      "El Zanjón",
      "Sachayoj",
      "La Cañada",
      "Los Pirpintos",
    ],
  },
];
